import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { ToastrService } from "ngx-toastr";
import { AuthenticationService } from "app/auth/service";
import { Router, ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SubStockTransferService } from 'app/_services/sub-stock-transfer.service';
import { StockTransferService } from 'app/_services/stock-transfer.service';

@Component({
  selector: 'app-sub-stock-transfer',
  templateUrl: './sub-stock-transfer.component.html',
  styleUrls: ['./sub-stock-transfer.component.scss']
})
export class SubStockTransferComponent implements OnInit {
  public stockList: any = [];
  public start: any = 0;
  public page: any = 0;

  public pageBasicText = 1;
  public collectionSize = 0;
  public lengths = 15;
  public loading = false;
  public progressMainTable: boolean = false;
  public isModelLoading: Boolean =true;
  public salesDetails:any;
  public transfer_user_name:any;

  public newFormAdd: FormGroup;
  public submitted = false;
  // public customerDropDown: any = [];
  public productDropDown: any = [];
  public currentUser: any;
  public tempUser: any;
  public user_id:any = 0;

  constructor(
    private _router: Router,
    private route: ActivatedRoute,
    private subStockTransferService: SubStockTransferService,
    private stockTransferService: StockTransferService,
    private _authenticationService: AuthenticationService,
    private _formBuilder: FormBuilder,
    private _toastrService: ToastrService,
    private http: HttpClient,
    private modalService: NgbModal,
  ) { }

  ngOnInit(): void {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.tempUser = JSON.parse(localStorage.getItem('tempUser'));
    if(this.currentUser.user.id != this.tempUser){
      this.user_id = this.tempUser;
    }else{
      this.user_id = this.currentUser.user.id;
    }

    this.getproductDropDown(this.user_id);
    this.stocksList(this.start, this.lengths,this.user_id);
  }

  getproductDropDown(user_id:any) {
    this.stockTransferService.getproductDropDown(user_id).subscribe(
      data => {
        if (data.status) {
          this.productDropDown = data.product;
        }
      });
  }
  stocksList(my_start: any, my_lengths: any,my_user_id:any) {
    const formdata = new FormData();
    formdata.append('start', my_start);
    formdata.append('length', my_lengths);
    formdata.append('user_id', my_user_id);

    this.subStockTransferService.getData(formdata).subscribe(
      (response) => {
        this.loading = false;
        this.progressMainTable = false;
        if (response.status) {
          this.stockList = response.data;
          this.collectionSize = response.recordsTotal;
        }
      },
      (err) => {
        if (err.error.error == "Unauthenticated.") {
          this._authenticationService.logout();
          this._router.navigate(["/login"]);
        }
      }
    );
  }
  reloadList(type: any = 0) {
    this.progressMainTable = true;
    this.stocksList(this.page, this.lengths,this.user_id);
  }

  loadPage(event: any) {
    this.progressMainTable = true;
    this.page = this.lengths * (event - 1);
    this.stocksList(this.page, this.lengths,this.user_id);
  }
  loadPageLenghs(event: any) {
    this.progressMainTable = true;
    this.lengths = event.target.value;
    this.stocksList(this.start, this.lengths,this.user_id);
  }

  details(stock_data: any,modalSM) {
    this.modalService.open(modalSM, {
      centered: true,
      size: 'xl'
    });
    this.transfer_user_name = stock_data.send_user_name;
    this.subStockTransferService.getmeta(stock_data.id).subscribe(
      data => {
          this.isModelLoading = false;
          if (data.status) {
              this.salesDetails = data.data;
          }else {
              this._toastrService.error(data.errorMessage, 'Error!');
          }
      },
      err => {
          this._toastrService.error(err.errorMessage, 'Opps!');
      }
    );
  }
}
