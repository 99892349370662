import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import Swal from "sweetalert2";
import { ToastrService } from "ngx-toastr";
import { AuthenticationService } from "app/auth/service";
import { Router, ActivatedRoute } from "@angular/router";
import { CoreSidebarService } from "@core/components/core-sidebar/core-sidebar.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { CashendService } from 'app/_services/cashend.service';
import { SalesReportService } from 'app/_services/sales-report.service';

@Component({
  selector: 'app-cashend',
  templateUrl: './cashend.component.html',
  styleUrls: ['./cashend.component.scss']
})
export class CashendComponent implements OnInit {

  public cashList: any = [];
  public cashadminList: any = [];
  public start: any = 0;
  public page: any = 0;

  public sidebarToggleRef = false;
  public contentHeader: object;
  public pageBasicText = 1;
  public collectionSize = 0;
  public lengths = 30;
  public loading = false;
  public progressMainTable: boolean = false;
  public newFormAdd: FormGroup;
  public submitted = false;
  public loadingFrm: boolean = false;
  public categoryId = 0;
  public error = "";
  public currentUser: any;
  public userDropDown: any = [];
  public tempUser: any;
  public user_id:any = 0;
  constructor(
    private _router: Router,
    private route: ActivatedRoute,
    private cashendService: CashendService,
    private salesReportService: SalesReportService,
    private _authenticationService: AuthenticationService,
    private _formBuilder: FormBuilder,
    private _toastrService: ToastrService,
    private http: HttpClient,
    private _coreSidebarService: CoreSidebarService
  ) { }

  ngOnInit(): void {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.tempUser = JSON.parse(localStorage.getItem('tempUser'));
    if(this.currentUser.user.id != this.tempUser){
      this.user_id = this.tempUser;
    }else{
      this.user_id = this.currentUser.user.id;
    }

    this.cashsList(1,this.user_id);

    this.getuserDropDown();
    this.newFormAdd = this._formBuilder.group({
      start_date: [''],
      end_date: [''],
    });
  }

  get f() {
    return this.newFormAdd.controls;
  }

  getuserDropDown() {
    this.salesReportService.getuserDropDown().subscribe(
      data => {
        if (data.status) {
          this.userDropDown = data.user;
        }
      });
  }
  
  cashsList(type: any = 1,my_user_id:any) {
    const formdata = new FormData();
    formdata.append('user_id', my_user_id);
    if (type != 1) {
      if (this.f.start_date.value !== null) {
        formdata.append('start_date', this.f.start_date.value);
      }
      if (this.f.end_date.value !== null) {
        formdata.append('end_date', this.f.end_date.value);
      }
    }
    this.cashendService.getData(formdata).subscribe(
      (response) => {
        this.loading = false;
        this.progressMainTable = false;
        if (response.status) {
          this.cashList = response.data;
          this.collectionSize = response.recordsTotal;
        }
      },
      (err) => {
        if (err.error.error == "Unauthenticated.") {
          this._authenticationService.logout();
          this._router.navigate(["/login"]);
        }
      }
    );
  }

  onSubmit() {
    this.submitted = true;
    this.loading = true;
    // this.toggleSidebar('new-sidebar');
    this.cashList = [];
    // stop here if form is invalid
    if (this.newFormAdd.invalid) {

    }
    else {
      const formdata = new FormData();
      formdata.append('user_id', this.user_id);
      if (this.f.start_date.value !== null) {
        formdata.append('start_date', this.f.start_date.value);
      }
      if (this.f.end_date.value !== null) {
        formdata.append('end_date', this.f.end_date.value);
      }

      this.cashendService.getData(formdata).subscribe(
        data => {
          this.loading = false;
          if (data.status) {
            this.cashList = data.data;
          }
        },
        err => {
          if (err.error.error == 'Unauthenticated.') {
            this._authenticationService.logout();
            this._router.navigate(['/login']);
          }
        }
      );
    }
  }
  toggleSidebar(name): void {
    this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
  }
  resetReport()
  {
    this.newFormAdd.reset();
    this.onSubmit();
  }
  getSum(index: any): number {
    let sum = 0;
    for (let i = 0; i < this.cashList.length; i++) {
      sum += parseFloat(this.cashList[i][index]);
    }
    return sum;
  }
  downloadExcelData() {
    const formdata = new FormData();
    formdata.append('ses_user_id', this.user_id);
    formdata.append('user_id', this.user_id);
    if (this.f.start_date.value !== null) {
      formdata.append('start_date', this.f.start_date.value);
    }
    if (this.f.end_date.value !== null) {
      formdata.append('end_date', this.f.end_date.value);
    }

    this.cashendService.fileDownload(formdata).subscribe(
      (data) => {
        window.location.href = data.url;
      },
      (error) => {
        this._toastrService.error('Something went wrong! Please Try Againg...', 'Opps!', { toastClass: 'toast ngx-toastr', closeButton: true });
      }
    );
  }
}
