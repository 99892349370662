<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <section id="home-page">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Order List</h4>
                        </div>
                        <form class="add-new modal-content pt-0" [formGroup]="newFormFilter"
                            (ngSubmit)="onSubmitFilter()">
                            <div class="modal-body flex-grow-1">
                                <div class="row">
                                    <div class="col-lg-3">
                                        <div class="form-group">
                                            <label>Select Order to<span class="text-danger">*</span></label>
                                            <ng-select formControlName="order_to" aria-describedby="register-order_to"
                                                [ngClass]="{ 'is-invalid': submitted && f.order_to.errors }">
                                                <ng-option disabled selected value="0">Select Company</ng-option>
                                                <ng-option *ngFor="let comp of vendorDropDown"
                                                    [value]="comp.id">{{comp.name}}</ng-option>
                                            </ng-select>
                                            <div *ngIf="submitted && f.order_to.errors" class="invalid-feedback">
                                                <div *ngIf="f.order_to.errors.required">Company is required</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3">
                                        <div class="form-group">
                                            <label>Select Consignee to<span class="text-danger">*</span></label>
                                            <ng-select formControlName="consignee_to" aria-describedby="register-consignee_to"
                                                [ngClass]="{ 'is-invalid': submitted && f.consignee_to.errors }">
                                                <ng-option disabled selected value="0">Select Company</ng-option>
                                                <ng-option *ngFor="let comp of vendorDropDown"
                                                    [value]="comp.id">{{comp.name}}</ng-option>
                                            </ng-select>
                                            <div *ngIf="submitted && f.consignee_to.errors" class="invalid-feedback">
                                                <div *ngIf="f.consignee_to.errors.required">Company is required</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3">
                                        <div class="form-group">
                                            <label class="form-label">From Date</label>
                                            <input type="date" formControlName="start_date" id="start_date"
                                                placeholder="dd-mm-yyyy" class="form-control">
                                        </div>
                                    </div>
                                    <div class="col-lg-3">
                                        <div class="form-group">
                                            <label class="form-label">To Date</label>
                                            <input type="date" formControlName="end_date" id="end_date"
                                                placeholder="dd-mm-yyyy" class="form-control">
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-3">
                                        <div class="form-group">
                                            <button type="submit" class="btn btn-primary mr-1 " rippleEffect
                                                [disabled]="loading "> <span *ngIf="loading "
                                                    class="spinner-border spinner-border-sm mr-1 "></span>Go</button>
                                            <button type="reset" class="btn btn-primary mr-1 " (click)="resetReport()"
                                                rippleEffect [disabled]="loading ">
                                                <span *ngIf="loading "
                                                    class="spinner-border spinner-border-sm mr-1 "></span>
                                                <i data-feather="refresh-cw"
                                                    class="d-sm-none d-inline-block mr-0 mr-sm-1"></i>
                                                <span class="d-none d-sm-inline-block">Reset</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div class="card-body p-0 pb-1">
                            <div class="row m-0">
                                <div class="col-md-6 col-6">
                                    <div class="d-flex justify-content-between align-items-center ml-1">
                                        <label class="d-flex align-items-center">Show
                                            <select class="form-control mx-25" [(ngModel)]="lengths"
                                                (change)="loadPageLenghs($event)">
                                                <option value="15">15</option>
                                                <option value="30">30</option>
                                                <option value="50">50</option>
                                                <option value="100">100</option>
                                            </select>
                                        </label>
                                    </div>
                                </div>
                                <div class="col-md-6 col-6 d-flex justify-content-start justify-content-md-end">
                                    <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pb-md-0 w-100">
                                        <button class="btn btn-primary ml-1" rippleEffect routerLink="/order/add"
                                            data-toggle="tooltip" data-popup="tooltip-custom" placement="top"
                                            container="body" ngbTooltip="Add New Order">
                                            <i data-feather="plus"
                                                class="d-sm-none d-inline-block mr-0 mr-sm-1"></i><span
                                                class="d-none d-sm-inline-block">Add New</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="col p-0 table-responsive">
                                <div class="overlay" *ngIf="progressMainTable">
                                    <div class="spinner-grow" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </div>
                                <table class="table table-striped table-hover valign-middle text-dark">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Order No</th>
                                            <th>Order to</th>
                                            <th>Consignee to</th>
                                            <th style="width: 110px;">Date</th>
                                            <th>Total</th>
                                            <th>Remark</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="orderList?.length != 0">
                                        <tr *ngFor="let ware of orderList; let ndx = index">
                                            <td>{{ndx+page+1}}</td>
                                            <td>{{ ware.order_no }}</td>
                                            <td>{{ ware.to_name }}</td>
                                            <td>{{ ware.consignee_name }}</td>
                                            <td>{{ ware.date }}</td>
                                            <td>{{ ware.final_total }}</td>
                                            <td>{{ ware.remark }}</td>
                                            <td>
                                                <button type="button" (click)="details(ware.id,modalSM)"
                                                    data-toggle="tooltip" data-popup="tooltip-custom" placement="top"
                                                    container="body" ngbTooltip="View"
                                                    class="btn btn-icon btn-sm btn-warning mr-1" rippleEffect>
                                                    <i data-feather="eye"></i>
                                                </button>
                                                <button type="button" rippleEffect
                                                    routerLink="/order/edit/{{ ware.id }}"
                                                    class="btn btn-icon btn-sm btn-info mr-1" data-toggle="tooltip"
                                                    data-popup="tooltip-custom" placement="top" container="body"
                                                    ngbTooltip="Edit">
                                                    <i data-feather="edit-2"></i>
                                                </button>
                                                <button type="button" rippleEffect
                                                    (click)="remove(ware.id)"
                                                    class="btn btn-icon btn-sm btn-danger mr-1" data-toggle="tooltip"
                                                    data-popup="tooltip-custom" placement="top" container="body"
                                                    ngbTooltip="Delete">
                                                    <i data-feather="trash"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="orderList?.length == 0">
                                        <tr *ngIf="!loading">
                                            <td colspan="9" class="no-data-available">No Data!</td>
                                        </tr>
                                        <tr>
                                            <td colspan="9" class="no-data-available">
                                                <div class="col" *ngIf="loading">
                                                    <div class="text-center">
                                                        <div class="spinner-border" role="status">
                                                            <span class="sr-only">Loading...</span>
                                                        </div>
                                                        <div>
                                                            Please Wait...
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <ngb-pagination class="d-flex justify-content-end mt-2 mr-2" *ngIf="collectionSize > 0"
                                    [collectionSize]="collectionSize" [pageSize]="lengths" [(page)]="pageBasicText"
                                    (pageChange)="loadPage($event)" aria-label="Custom pagination">
                                    <ng-template ngbPaginationPrevious><span [data-feather]="'chevron-left'"
                                            [class]="'font-weight-bolder'"></span>Prev
                                    </ng-template>
                                    <ng-template ngbPaginationNext>Next<span [data-feather]="'chevron-right'"
                                            [class]="'font-weight-bolder'"></span>
                                    </ng-template>
                                </ngb-pagination>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- Modal -->
        <ng-template #modalSM let-modal>
            <div class="overlay" *ngIf="isModelLoading">
                <div class="spinner-border text-primary" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
            <div class="modal-header">
                <h5 class="modal-title w-100" id="myModalLabel160">
                    <span class="float-left"> <span class="badge badge-glow badge-primary">View Details</span></span>
                </h5>
                <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body" tabindex="0" ngbAutofocus>
                <div class="row">
                    <div class="table-responsive m-t-40">
                        <table class="table table-hover">
                            <thead>
                                <tr>
                                    <th class="text-center">#</th>
                                    <th class="text-center">Product Name</th>
                                    <th class="text-center">Weight</th>
                                    <th class="text-center">Quantity</th>
                                    <th class="text-center">Price</th>
                                    <th class="text-center">Total</th>
                                    <th class="text-center">Technical Remark</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let ind of orderDetails; let i = index">
                                    <td class="text-center">{{ i + 1 }}</td>
                                    <td class="text-center">{{ ind.product_name}}</td>
                                    <td class="text-center">{{ ind.weight}}</td>
                                    <td class="text-center">{{ ind.price}}</td>
                                    <td class="text-center">{{ ind.qty}}</td>
                                    <td class="text-center">{{ ind.total}}</td>
                                    <td class="text-center">{{ ind.product_remark}}</td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="orderDetails?.length == 0">
                                <tr>
                                    <td colspan="4" class="no-data-available">No data!</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </ng-template>
        <!-- / Modal -->
    </div>
</div>

