import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class VendorService {

  constructor(private _http: HttpClient, private _toastrService: ToastrService) { }

  getData(start, length): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}get-vendor`, { start, length })
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  add(name: any, mobile: any, email: any, address: any, id: any): Observable<any> {
    if (id == 0) {
      return this._http
        .post(`${environment.apiUrl}save-vendor`, { name, mobile, email, address })
        .pipe(
          map(data => {
            return data;
          })
        );
    }
    else {
      return this._http
        .post(`${environment.apiUrl}update-vendor`, { name, mobile, email, address, id })
        .pipe(
          map(data => {
            return data;
          })
        );
    }
  }

  delete(id): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}delete-vendor`, { id })
      .pipe(
        map(data => {
          return data;
        })
      );
  }
  fileDownload(): Observable<any> {
    return this._http
      .get(`${environment.apiUrl}export-vendors`)
      .pipe(
        map(data => {
          return data;
        })
      );
  }
}
