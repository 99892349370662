import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthLoginV2Component } from 'app/main/pages/authentication/auth-login-v2/auth-login-v2.component';
import { AuthGuard } from './auth/helpers';
import { HomeComponent } from './main/sample/home.component';
import { Role } from './auth/models';
import { ProductComponent } from './component/product/product.component';
import { VendorComponent } from './component/vendor/vendor.component';
import { CustomerComponent } from './component/customer/customer.component';
import { PurchaseComponent } from './component/purchase/purchase.component';
import { PurchaseAddComponent } from './component/purchase-add/purchase-add.component';
import { StockComponent } from './component/stock/stock.component';
import { PurchasepaymentComponent } from './component/purchasepayment/purchasepayment.component';
import { UserComponent } from './component/user/user.component';
import { SalesComponent } from './component/sales/sales.component';
import { SalesAddComponent } from './component/sales-add/sales-add.component';
import { SalespaymentComponent } from './component/salespayment/salespayment.component';
import { StockreceiveComponent } from './component/stockreceive/stockreceive.component';
import { ExpenseComponent } from './component/expense/expense.component';
import { ExpensecategoryComponent } from './component/expensecategory/expensecategory.component';
import { ExpenseReportComponent } from './component/expense-report/expense-report.component';
import { OutstandingReportComponent } from './component/outstanding-report/outstanding-report.component';
import { PurchasePaymentReportComponent } from './component/purchase-payment-report/purchase-payment-report.component';
import { SalesReportComponent } from './component/sales-report/sales-report.component';
import { IntransitComponent } from './component/intransit/intransit.component';
import { CashendComponent } from './component/cashend/cashend.component';
import { CurrencyComponent } from './component/currency/currency.component';
import { ManageStockComponent } from './component/manage-stock/manage-stock.component';
import { AdminoutstandingComponent } from './component/adminoutstanding/adminoutstanding.component';
import { CashendadminComponent } from './component/cashendadmin/cashendadmin.component';
import { PurchaseReceiveComponent } from './component/purchase-receive/purchase-receive.component';
import { StockTransferComponent } from './component/stock-transfer/stock-transfer.component';
import { StockTransferAddComponent } from './component/stock-transfer-add/stock-transfer-add.component';
import { PaymentComponent } from './component/payment/payment.component';
import { ExpenseReportAdminComponent } from './component/expense-report-admin/expense-report-admin.component';
import { SubStockTransferComponent } from './component/sub-stock-transfer/sub-stock-transfer.component';
import { SubStockTransferAddComponent } from './component/sub-stock-transfer-add/sub-stock-transfer-add.component';
import { InportComponent } from './component/inport/inport.component';
import { SalesProductComponent } from './component/sales-product/sales-product.component';
import { OrderComponent } from './component/order/order.component';
import { OrderAddComponent } from './component/order-add/order-add.component';
import { CompanyComponent } from './component/company/company.component';

const routes: Routes = [
  {
    path: 'pages',
    loadChildren: () => import('./main/pages/pages.module').then(m => m.PagesModule)
  },
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: AuthLoginV2Component,
    data: { animation: 'auth' }
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth',role: [Role.Admin, Role.User] }
  },
  {
    path: 'product',
    component: ProductComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth',role: [Role.Admin] }
  },
  {
    path: 'vendor',
    component: VendorComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth',role: [Role.Admin] }
  },
  {
    path: 'customer',
    component: CustomerComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth',role: [Role.Admin] }
  },
  {
    path: 'purchase',
    component: PurchaseComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth',role: [Role.Admin] }
  },
  {
    path: 'inport',
    component: InportComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth',role: [Role.Admin] }
  },
  {
    path: 'purchasereceive',
    component: PurchaseReceiveComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth',role: [Role.Admin] }
  },
  {
    path: 'purchase/add',
    component: PurchaseAddComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth',role: [Role.Admin] }
  },
  {
    path: 'purchase/edit/:id',
    component: PurchaseAddComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth',role: [Role.Admin] }
  },
  {
    path: 'stock',
    component: StockComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth',role: [Role.Admin] }
  },
  {
    path: 'purchasepayment',
    component: PurchasepaymentComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth',role: [Role.Admin] }
  },
  {
    path: 'user',
    component: UserComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth',role: [Role.Admin] }
  },
  {
    path: 'sales',
    component: SalesComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth',role: [Role.Admin] }
  },
  {
    path: 'sales/add',
    component: SalesAddComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth',role: [Role.Admin] }
  },
  {
    path: 'sales/edit/:id',
    component: SalesAddComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth', role: [Role.Admin] }
  },
  {
    path: 'salespayment',
    component: SalespaymentComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth',role: [Role.Admin] }
  },
  {
    path: 'stockreceive',
    component: StockreceiveComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth',role: [Role.Admin] }
  },
  {
    path: 'expense',
    component: ExpenseComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth',role: [Role.Admin] }
  },
  {
    path: 'expensecategory',
    component: ExpensecategoryComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth',role: [Role.Admin] }
  },
  {
    path: 'expensereport',
    component: ExpenseReportComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth',role: [Role.Admin] }
  },
  {
    path: 'outstandingreport',
    component: OutstandingReportComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth',role: [Role.Admin] }
  },
  {
    path: 'purchasepaymentreport',
    component: PurchasePaymentReportComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth',role: [Role.Admin] }
  },
  {
    path: 'salesreport',
    component: SalesReportComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth',role: [Role.Admin] }
  },
  {
    path: 'intransit',
    component: IntransitComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth',role: [Role.Admin] }
  },
  {
    path: 'cashendreport',
    component: CashendComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth',role: [Role.Admin] }
  },
  {
    path: 'currency',
    component: CurrencyComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth',role: [Role.Admin] }
  },
  {
    path: 'managestock',
    component: ManageStockComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth',role: [Role.Admin] }
  },
  {
    path: 'adminoutstanding',
    component: AdminoutstandingComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth',role: [Role.Admin] }
  },
  {
    path: 'cashendadmin',
    component: CashendadminComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth',role: [Role.Admin] }
  },
  {
    path: 'stocktransfer',
    component: StockTransferComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth',role: [Role.Admin] }
  },
  {
    path: 'stocktransfer/add',
    component: StockTransferAddComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth',role: [Role.Admin] }
  },
  {
    path: 'payment',
    component: PaymentComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth',role: [Role.Admin] }
  },
  {
    path: 'expensereportadmin',
    component: ExpenseReportAdminComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth',role: [Role.Admin] }
  },
  {
    path: 'substocktransfer',
    component: SubStockTransferComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth',role: [Role.Admin] }
  },
  {
    path: 'substocktransfer/add',
    component: SubStockTransferAddComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth',role: [Role.Admin] }
  },
  {
    path: 'salesproduct',
    component: SalesProductComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth',role: [Role.Admin] }
  },
  {
    path: 'order',
    component: OrderComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth',role: [Role.Admin] }
  },
  {
    path: 'order/add',
    component: OrderAddComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth',role: [Role.Admin] }
  },
  {
    path: 'order/edit/:id',
    component: OrderAddComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth',role: [Role.Admin] }
  },
  {
    path: 'company',
    component: CompanyComponent,
    canActivate: [AuthGuard],
    data: { animation: 'auth',role: [Role.Admin] }
  },
  {
    path: '**',
    redirectTo: '/pages/miscellaneous/error' //Error 404 - Page not found
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: true,
    scrollPositionRestoration: 'enabled', // Add options right here
    relativeLinkResolution: 'legacy'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }