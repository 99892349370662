<!-- <div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <section id="home-page">
            <div class="col p-0">
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title">Vendor Report</h4>
                    </div>
                    <div class="card-body p-0 pb-1">
                        <div class="row m-0">
                            <div class="form-group col-lg-6 ">
                                <label class="form-label">Vendor Name<span class="text-danger">*</span></label>
                                <ng-autocomplete #ngAutoCompleteStatic [data]="allVendor" name="dealers" [initialValue]="" [minQueryLength]="3" placeholder="Search Vendor Min 3 Characters" [searchKeyword]="keyword" historyIdentifier="allVendor" [historyHeading]="historyHeading"
                                    [historyListMaxNumber]="3" notFoundText="Not found" (selected)='selectEvent($event)' (inputChanged)='onChangeSearch($event)' (inputFocused)='onFocused($event)' [itemTemplate]="itemTemplateStatic" [isLoading]="isLoading"
                                    [notFoundTemplate]="notFoundTemplate">
                                </ng-autocomplete>
                                <ng-template #itemTemplateStatic let-item>
                                    <a [innerHTML]="item.name" ></a>
                                </ng-template>
                                <ng-template #notFoundTemplate let-notFound>
                                    <div [innerHTML]="notFound"></div>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col p-0" *ngIf="vendorOpeningBalance && vendorOpeningBalance.balance_type">
                <div class="card">
                    <div class="card-header">
                        <h4 class="card-title">{{ vendorDetails.name }}</h4>
                    </div>
                    <div class="card-body p-0 pb-1">
                        <table class="table table-border table-striped">
                            <thead>
                                <tr>
                                    <th>Date &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</th>
                                    <th class="text-right">Credit &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</th>
                                    <th class="text-right">Debit &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</th>
                                    <th class="text-right">Closing Balance &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let outStanding of vendorOutStanding; let ndx = index">
                                    <td>{{ outStanding.payment_date }}</td>
                                    <td class="text-right" align="right" *ngIf="outStanding.payment_type == 'C'">{{ outStanding.amount | number:'1.2-2' }}</td>
                                    <td></td>
                                    <td class="text-right" align="right" *ngIf="outStanding.payment_type == 'D'">{{ outStanding.amount | number:'1.2-2' }}</td>
                                    <th class="text-right" align="right" *ngIf="outStanding.closing < 0"> {{(outStanding.closing | number:'1.2-2' + '').replace('-', '')}} DB </th>
                                    <th class="text-right" align="right" *ngIf="outStanding.closing >= 0">{{ outStanding.closing | number:'1.2-2' }} <span *ngIf="outStanding.closing > 0">CR</span> </th>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div> -->
<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <section id="home-page">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Payment List</h4>
                        </div>
                        <div class="card-body p-0 pb-1">
                            <div class="row m-0">
                                <div class="col-md-6 col-6">
                                    <div class="d-flex justify-content-between align-items-center ml-1">
                                        <label class="d-flex align-items-center">Show
                                        <select class="form-control mx-25" [(ngModel)]="lengths" (change)="loadPageLenghs($event)" >
                                            <option value="15">15</option>
                                            <option value="30">30</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                        </select>
                                      </label>
                                    </div>
                                </div>
                                <div class="col-md-6 col-6 d-flex justify-content-start justify-content-md-end">
                                    <button class="btn btn-info ml-1" rippleEffect (click)="downloadExcelData()">
                                        <i data-feather="plus"
                                            class="d-sm-none d-inline-block mr-0 mr-sm-1"></i><span
                                            class="d-none d-sm-inline-block">Download</span>
                                    </button>
                                </div>
                            </div>
                            <div class="col p-0 table-responsive">
                                <div class="overlay" *ngIf="progressMainTable">
                                    <div class="spinner-grow" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </div>
                                <table class="table table-striped table-hover valign-middle text-dark">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Name</th>
                                            <th>Local Currency</th>
                                            <th>Foreign Currency</th>
                                            <th>Remark</th>
                                        </tr>
                                    </thead>

                                    <tbody *ngIf="paymentList?.length != 0">
                                        <tr *ngFor="let ware of paymentList; let ndx = index">
                                            <td>{{ndx+page+1}}</td>
                                            <td>{{ ware.name }}</td>
                                            <td>{{ ware.local_currency | number:'1.2-2' }}</td>
                                            <td>{{ ware.foreign_currency | number:'1.2-2' }}</td>
                                            <td>{{ ware.remark }}</td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="paymentList?.length == 0">
                                        <tr *ngIf="!loading">
                                            <td colspan="3" class="no-data-available">No Data!</td>
                                        </tr>
                                        <tr>
                                            <td colspan="3" class="no-data-available">
                                                <div class="col" *ngIf="loading">
                                                    <div class="text-center">
                                                        <div class="spinner-border" role="status">
                                                            <span class="sr-only">Loading...</span>
                                                        </div>
                                                        <div>
                                                            Please Wait...
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <ngb-pagination class="d-flex justify-content-end mt-2 mr-2" *ngIf="collectionSize > 0" [collectionSize]="collectionSize" [pageSize]="lengths" [(page)]="pageBasicText" (pageChange)="loadPage($event)" aria-label="Custom pagination">
                                    <ng-template ngbPaginationPrevious><span [data-feather]="'chevron-left'" [class]="'font-weight-bolder'"></span>Prev
                                    </ng-template>
                                    <ng-template ngbPaginationNext>Next<span [data-feather]="'chevron-right'" [class]="'font-weight-bolder'"></span>
                                    </ng-template>
                                </ngb-pagination>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>