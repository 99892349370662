import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SalesService {

  constructor(private _http: HttpClient) { }

  getData(formdata: any): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}get-sales`, formdata)
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  getmeta(id: string): Observable<any> {
    return this._http.post(`${environment.apiUrl}get-metasales`, {id});
  }
  
  stockcheck(product_id: string,qty: string,user_id:string): Observable<any> {
    return this._http.post(`${environment.apiUrl}stock-check`, {product_id,qty,user_id});
  }

  add(user_id:any,invoice_no:any,customer_id:any,type_id:any,date:any,remark:any, id: any,final_total:any,salesProducts:any): Observable<any> {
    if (id == 0) {
      return this._http
        .post(`${environment.apiUrl}save-sales`, {user_id,invoice_no,customer_id,type_id,date,remark,final_total,salesProducts})
        .pipe(
          map(data => {
            return data;
          })
        );
    }
    else {
      return this._http
        .post(`${environment.apiUrl}update-sales`, {user_id,invoice_no,customer_id,type_id,date,remark,final_total,salesProducts, id })
        .pipe(
          map(data => {
            return data;
          })
        );
    }
  }

  edit(id: string): Observable<any> {
    return this._http.post(`${environment.apiUrl}edit-sales`, {id});
  }

  getInvoiceno(user_id): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}get-invoiceno`, {user_id })
      .pipe(
        map(data => {
          return data;
        })
      );
  }
  
  getproductDropDown(user_id): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}product-dropdown`, { user_id})
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  getcustomerDropDown(user_id): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}customer-dropdown`, { user_id})
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  delete(id: string,qty: string,user_id): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}delete-metasales`, { id,qty,user_id })
      .pipe(
        map(data => {
          return data;
        })
      );
  }
  fileDownload(formdata: any): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}export-sales`,formdata)
      .pipe(
        map(data => {
          return data;
        })
      );
  }
}
