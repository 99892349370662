import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import Swal from "sweetalert2";
import { ToastrService } from "ngx-toastr";
import { AuthenticationService } from "app/auth/service";
import { Router, ActivatedRoute } from "@angular/router";
import { CoreSidebarService } from "@core/components/core-sidebar/core-sidebar.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { IntransitService } from 'app/_services/intransit.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PurchaseService } from 'app/_services/purchase.service';

@Component({
  selector: 'app-intransit',
  templateUrl: './intransit.component.html',
  styleUrls: ['./intransit.component.scss']
})
export class IntransitComponent implements OnInit {
  public intransitList: any = [];
  public start: any = 0;
  public page: any = 0;

  public pageBasicText = 1;
  public collectionSize = 0;
  public lengths = 15;
  public loading = false;
  public progressMainTable: boolean = false;
  public isModelLoading: Boolean =true;
  public purchaseDetails:any;
  public intransitDetails:any;
  public newFormAdd: FormGroup;
  public submitted = false;
  public vendorDropDown: any = [];


  constructor(
    private _router: Router,
    private route: ActivatedRoute,
    private purchaseService: PurchaseService,
    private intransitService: IntransitService,
    private _authenticationService: AuthenticationService,
    private _formBuilder: FormBuilder,
    private _toastrService: ToastrService,
    private http: HttpClient,
    private modalService: NgbModal,
    private _coreSidebarService: CoreSidebarService
  ) { }

  getvendorDropDown() {
    this.purchaseService.getvendorDropDown().subscribe(
      data => {
        if (data.status) {
          this.vendorDropDown = data.vendor;
        }
      });
  }

  ngOnInit(): void {
    this.intransitsList(this.start, this.lengths);
    this.getvendorDropDown();

    this.newFormAdd = this._formBuilder.group({
      // start_date: [''],
      // end_date: [''],
      vendor_id: [null],
    });
  }

  get f() {
    return this.newFormAdd.controls;
  }

  onSubmit() {
    this.submitted = true;
    this.loading = true;
    // this.toggleSidebar('new-sidebar');
    this.intransitList = [];
    // stop here if form is invalid
    if (this.newFormAdd.invalid) {

    }
    else {
      this.page = 0;      
      this.pageBasicText = 1;
      
      const formdata = new FormData();
      formdata.append('start', "0");
      formdata.append('length', "15");
      
      // if (this.f.start_date.value !== null) {
      //   formdata.append('start_date', this.f.start_date.value);
      // }
      // if (this.f.end_date.value !== null) {
      //   formdata.append('end_date', this.f.end_date.value);
      // }
      if (this.f.vendor_id.value != "") {
        formdata.append('vendor_id', this.f.vendor_id.value);
      }
      this.intransitService.getData(formdata).subscribe(
        data => {
          this.loading = false;
          if (data.status) {
            this.intransitList = data.data;
            this.collectionSize = data.recordsTotal;
          }
        },
        err => {
          if (err.error.error == 'Unauthenticated.') {
            this._authenticationService.logout();
            this._router.navigate(['/login']);
          }
        }
      );
    }
  }
  
  toggleSidebar(name): void {
    this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
  }

  resetReport() {
    this.newFormAdd.reset();
    this.onSubmit();
  }

  intransitsList(my_start: any, my_lengths: any) {
    const formdata = new FormData();
    formdata.append('start', my_start);
    formdata.append('length', my_lengths);

    this.intransitService.getData(formdata).subscribe(
      (response) => {
        this.loading = false;
        this.progressMainTable = false;
        if (response.status) {
          this.intransitList = response.data;
          this.collectionSize = response.recordsTotal;
        }
      },
      (err) => {
        if (err.error.error == "Unauthenticated.") {
          this._authenticationService.logout();
          this._router.navigate(["/login"]);
        }
      }
    );
  }

  intransitsListNew(my_start: any, my_lengths: any, type: any = 0) {
    const formdata = new FormData();
    if (type != 1) {
      formdata.append('start', my_start);
    }
    else {
      formdata.append('start', '0');
      this.page = 0;
      this.pageBasicText = 1;
    }
    formdata.append('length', my_lengths);
    if (type != 1) {
      // if (this.f.start_date.value !== null) {
      //   formdata.append('start_date', this.f.start_date.value);
      // }
      // if (this.f.end_date.value !== null) {
      //   formdata.append('end_date', this.f.end_date.value);
      // }
      if (this.f.vendor_id.value != "") {
        formdata.append('vendor_id', this.f.vendor_id.value);
      }
    }

    this.intransitService.getData(formdata).subscribe(
      (response) => {
        this.loading = false;
        this.progressMainTable = false;
        if (response.status) {
          this.intransitList = response.data;
          this.collectionSize = response.recordsTotal;
        }
      },
      (err) => {
        if (err.error.error == "Unauthenticated.") {
          this._authenticationService.logout();
          this._router.navigate(["/login"]);
        }
      }
    );
  }

  reloadList(type: any = 0) {
    this.progressMainTable = true;
    this.intransitsListNew(this.page, this.lengths, type);
  }

  loadPage(event: any) {
    this.progressMainTable = true;
    this.page = this.lengths * (event - 1);
    this.intransitsListNew(this.page, this.lengths);
  }
  loadPageLenghs(event: any) {
    this.progressMainTable = true;
    this.lengths = event.target.value;
    this.intransitsListNew(this.start, this.lengths);
  }

  details(id: any,modalSM) {
    this.modalService.open(modalSM, {
      centered: true,
      size: 'xl'
    });
    this.intransitService.getmeta(id).subscribe(
      data => {
          this.isModelLoading = false;
          if (data.status) {
              this.intransitDetails = data.data;
          }else {
              this._toastrService.error(data.errorMessage, 'Error!');
          }
      },
      err => {
          this._toastrService.error(err.errorMessage, 'Opps!');
      }
    );
  }
}
