<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <section id="home-page">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title" *ngIf="isAddMode">Add Order</h4>
                            <h4 class="card-title" *ngIf="!isAddMode">Edit Order</h4>
                        </div>
                        <div class="card-body pb-1">
                            <form name="form" [formGroup]="newForm" autocomplete="off">
                                <div class="row">
                                    <div class="col-lg-3 pt-1">
                                        <div class="form-group">
                                            <label>Order to<span class="text-danger">*</span></label>
                                            <ng-select formControlName="order_to" aria-describedby="register-order_to"
                                                [ngClass]="{ 'is-invalid': submitted && f.order_to.errors }">
                                                <ng-option disabled selected value="0">Select Exporter</ng-option>
                                                <ng-option *ngFor="let ven of vendorDropDown"
                                                    [value]="ven.id">{{ven.name}}</ng-option>
                                            </ng-select>
                                            <div *ngIf="submitted && f.order_to.errors" class="invalid-feedback">
                                                <div *ngIf="f.order_to.errors.required">Exporter is required</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 pt-1">
                                        <div class="form-group">
                                            <label>Consignee to<span class="text-danger">*</span></label>
                                            <ng-select formControlName="consignee_to"
                                                aria-describedby="register-consignee_to"
                                                [ngClass]="{ 'is-invalid': submitted && f.consignee_to.errors }">
                                                <ng-option disabled selected value="0">Select Exporter</ng-option>
                                                <ng-option *ngFor="let ven of vendorDropDown"
                                                    [value]="ven.id">{{ven.name}}</ng-option>
                                            </ng-select>
                                            <div *ngIf="submitted && f.consignee_to.errors" class="invalid-feedback">
                                                <div *ngIf="f.consignee_to.errors.required">Exporter is required</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 pt-1">
                                        <div class="form-group">
                                            <label for="register-notify_party" class="form-label">Notify party</label>
                                            <input type="text" formControlName="notify_party" class="form-control"
                                                placeholder="Notify party" aria-describedby="register-notify_party"
                                                [ngClass]="{ 'is-invalid': submitted && f.notify_party.errors }" />
                                        </div>
                                    </div>
                                    <div class="col-lg-3 pt-1">
                                        <div class="form-group">
                                            <label for="register-order_no" class="form-label">Order No</label>
                                            <input type="text" formControlName="order_no" class="form-control" readonly
                                                placeholder="Order No" [value]="Orderno"
                                                aria-describedby="register-order_no"
                                                [ngClass]="{ 'is-invalid': submitted && f.order_no.errors }" />
                                        </div>
                                    </div>
                                    <div class="col-lg-4 pt-1">
                                        <div class="form-group">
                                            <label for="date" class="form-label">Date </label> <span class="required"
                                                aria-required="true"> * </span>
                                            <div class="input-group">
                                                <input class="form-control" placeholder="dd-mm-yyyy"
                                                    formControlName="date" id="date" [(ngModel)]="basicDPdata"
                                                    ngbDatepicker #basicDP="ngbDatepicker"
                                                    [ngClass]="{ 'is-invalid': submitted && f.date.errors }"
                                                    [maxDate]="{year: maxYear, month: maxMonth, day: maxDate}">
                                                <div class="input-group-append">
                                                    <button class="btn btn-outline-secondary feather icon-calendar"
                                                        (click)="basicDP.toggle()" type="button" rippleEffect></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-8 pt-1">
                                        <div class="form-group">
                                            <label for="register-remark" class="form-label">Remark</label>
                                            <input type="text" formControlName="remark" class="form-control"
                                                placeholder="Remark" aria-describedby="register-remark"
                                                [ngClass]="{ 'is-invalid': submitted && f.remark.errors }" />
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col col-sm-12 col-xs-12 col-lg-12 pt-1">
                                        <div class="form-group">
                                            <label for="register-note" class="form-label">Note</label>
                                            <textarea rows="3" formControlName="note" class="form-control" placeholder="Note" aria-describedby="register-note" [ngClass]="{ 'is-invalid': submitted && f.note.errors }"></textarea>
                                            <div *ngIf="submitted && f.note.errors" class="invalid-feedback">
                                                <div *ngIf="f.note.errors.required">Note is required</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <div class="row" *ngFor="let pue_product of orderProducts; let i = index;">
                                <div class="form-group col-lg-3">
                                    <label>Product</label>
                                    <ng-select name="product_id_{{pue_product.id}}" [(ngModel)]="pue_product.product_id"
                                        (ngModelChange)="onProductChange(pue_product, $event)">
                                        <ng-option disabled selected value="0">Select Product</ng-option>
                                        <ng-option *ngFor="let pro of productDropDown" [value]="pro.id"
                                            [attr.data-image]="pro.image">{{pro.product_name}}</ng-option>
                                    </ng-select>
                                </div>
                                <div class="form-group col-lg-2">
                                    <label>Weight<span class="text-danger">*</span></label>
                                    <input type="text" class="form-control" [(ngModel)]="pue_product.weight"
                                        name="weight_{{pue_product.id}}" (change)="addTotal($event)"
                                        placeholder="Weight" />
                                </div>
                                <div class="form-group col-lg-2">
                                    <label>Qty<span class="text-danger">*</span></label>
                                    <input type="text" class="form-control" [(ngModel)]="pue_product.qty"
                                        name="qty_{{pue_product.id}}" (change)="addTotal($event)" placeholder="Qty" />
                                </div>
                                <div class="form-group col-lg-2">
                                    <label>Price<span class="text-danger">*</span></label>
                                    <input type="text" class="form-control" [(ngModel)]="pue_product.price"
                                        name="price_{{pue_product.id}}" (change)="addTotal($event)"
                                        placeholder="Price" />
                                </div>
                                <div class="form-group col-lg-2">
                                    <label>Total<span class="text-danger">*</span></label>
                                    <input type="text" class="form-control" [(ngModel)]="pue_product.total"
                                        name="total_{{pue_product.id}}" placeholder="Total" />
                                </div>
                                <div class="form-group col-lg-3">
                                    <label>Technical Remark<span class="text-danger">*</span></label>
                                    <input type="text" class="form-control" [(ngModel)]="pue_product.product_remark"
                                        name="product_remark_{{pue_product.id}}" (change)="addTotal($event)"
                                        placeholder=" Technical Remark" />
                                </div>
                                <div class="form-group  col-lg-1">
                                    <br />
                                    <button type="button" class="btn btn-danger"
                                        (click)="remove(i,pue_product.ord_tra_id)">
                                        <i data-feather="trash"></i>
                                    </button>
                                </div>
                                <div class="form-group col-lg-2" *ngIf="pue_product.imageUrl">
                                    <img [src]="pue_product.imageUrl" alt="Product Image"
                                        style="max-width: 100px; max-height: 100px;">
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group offset-lg-9 col-lg-2">
                                    <input type="text" class="form-control" name="final_total" placeholder="Final Total"
                                        [(ngModel)]="final_total" />
                                </div>
                            </div>
                            <button type="button" class="btn btn-outline-success mb-2" (click)="addMore()">
                                <i data-feather="plus"></i> Add More
                            </button>
                            
                            <div class="row">
                                <div class="col col-sm-6 col-xs-6 col-lg-2 pt-1">
                                    <button [disabled]="loading" type="button" routerLink="/products"
                                        class="btn btn-secondary btn-block" rippleEffect>
                                        <span *ngIf="loading"
                                            class="spinner-border spinner-border-sm mr-1"></span>Cancel
                                    </button>
                                </div>
                                <div class="col col-sm-6 col-xs-6 offset-lg-8 col-lg-2 pt-1">
                                    <button [disabled]="loadingFrm" type="button" (click)="onSubmit()"
                                        class="btn btn-primary btn-block" *ngIf="isAddMode" rippleEffect>
                                        <span *ngIf="loadingFrm"
                                            class="spinner-border spinner-border-sm mr-1"></span>Save
                                    </button>
                                    <button [disabled]="loadingFrm" type="button" (click)="onSubmit()"
                                        class="btn btn-primary btn-block" *ngIf="!isAddMode" rippleEffect>
                                        <span *ngIf="loadingFrm"
                                            class="spinner-border spinner-border-sm mr-1"></span>Update
                                    </button>
                                </div>
                                <div class="clearfix"></div>
                                <div class="col col-sm-12 col-xs-12 col-lg-8">
                                    <ngb-alert [type]="'danger'" [dismissible]="false" *ngIf="error">
                                        <div class="alert-body">
                                            <p>{{ error }}</p>
                                        </div>
                                    </ngb-alert>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>