<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <section id="home-page">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Cashend Report</h4>
                        </div>
                        <form class="add-new modal-content pt-0" [formGroup]="newFormAdd" (ngSubmit)="onSubmit()">
                            <div class="modal-body flex-grow-1 ">
                                <div class="row">
                                    <div class="col-lg-4">
                                        <div class="form-group">
                                            <label class="form-label">From Date</label>
                                            <input type="date" formControlName="start_date" id="start_date"
                                                placeholder="dd-mm-yyyy" class="form-control">
                                        </div>
                                    </div>
                                    <div class="col-lg-4">

                                        <div class="form-group">
                                            <label class="form-label">To Date</label>
                                            <input type="date" formControlName="end_date" id="end_date"
                                                placeholder="dd-mm-yyyy" class="form-control">
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="form-group">
                                            <label>Select User<span class="text-danger">*</span></label>
                                            <ng-select formControlName="user_id" aria-describedby="register-user_id"
                                                [ngClass]="{ 'is-invalid': submitted && f.user_id.errors }">
                                                <ng-option disabled selected value="0">Select User</ng-option>
                                                <ng-option *ngFor="let expCat of userDropDown"
                                                    [value]="expCat.id">{{expCat.name}}</ng-option>
                                            </ng-select>
                                            <div *ngIf="submitted && f.user_id.errors" class="invalid-feedback">
                                                <div *ngIf="f.user_id.errors.required">Date is required</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-4">
                                        <div class="form-group">
                                            <button type="submit" class="btn btn-primary mr-1 " rippleEffect
                                                [disabled]="loading "> <span *ngIf="loading "
                                                    class="spinner-border spinner-border-sm mr-1 "></span>Go</button>
                                            <button type="reset" class="btn btn-primary mr-1 " (click)="resetReport()"
                                                rippleEffect [disabled]="loading ">
                                                <span *ngIf="loading "
                                                    class="spinner-border spinner-border-sm mr-1 "></span>
                                                <i data-feather="refresh-cw"
                                                    class="d-sm-none d-inline-block mr-0 mr-sm-1"></i>
                                                <span class="d-none d-sm-inline-block">Reset</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div class="card-body p-0 pb-1">
                            <div class="row m-0">
                                <div class="col-md-6 col-6">
                                    <!-- <div class="d-flex justify-content-between align-items-center ml-1">
                                        <label class="d-flex align-items-center">Show
                                        <select class="form-control mx-25" [(ngModel)]="lengths" (change)="loadPageLenghs($event)" >
                                            <option value="30">30</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                        </select>
                                      </label>
                                    </div> -->
                                </div>
                                <div class="col-md-6 col-6 d-flex justify-content-start justify-content-md-end">
                                    <div class="align-items-center justify-content-end pb-1 pb-md-0">
                                        <button class="btn btn-info ml-1" rippleEffect (click)="downloadExcelData()">
                                            <i data-feather="plus"
                                                class="d-sm-none d-inline-block mr-0 mr-sm-1"></i><span
                                                class="d-none d-sm-inline-block">Download</span>
                                        </button>
                                        <!-- <button class="btn btn-warning ml-1" rippleEffect
                                            (click)="toggleSidebar('new-sidebar')">
                                            <i data-feather="filter" class="d-sm-none d-inline-block mr-0 mr-sm-1"></i>
                                            <span class="d-none d-sm-inline-block">Filter</span>
                                        </button> -->
                                    </div>
                                </div>
                            </div>
                            <div class="col p-0 table-responsive">
                                <div class="overlay" *ngIf="progressMainTable">
                                    <div class="spinner-grow" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </div>
                                <table class="table table-striped table-hover valign-middle text-dark">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>User</th>
                                            <!-- <th>Credit</th> -->
                                            <!-- <th>debit</th> -->
                                            <th>balance</th>
                                            <th>Dollar</th>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="cashadminList?.length != 0">
                                        <tr *ngFor="let ware of cashadminList; let ndx = index">
                                            <td>{{ndx+page+1}}</td>
                                            <td>{{ ware.name }}</td>
                                            <!-- <td>{{ ware.payment_amount | number:'1.2-2' }}</td> -->
                                            <!-- <td>{{ ware.expense_amount | number:'1.2-2' }}</td> -->
                                            <td>{{ ware.balance | number:'1.2-2' }} <span
                                                    *ngIf="ware.balance > 0">CR</span> <span
                                                    *ngIf="ware.balance < 0">DB</span></td>
                                            <td>{{ ware.in_dollar | number:'1.2-2' }}</td>
                                        </tr>
                                        <tr class="total_bg">
                                            <th></th>
                                            <th>Total</th>
                                            <!-- <th>{{ getSum('payment_amount')| number:'1.2-2' }}</th> -->
                                            <!-- <th>{{ getSum('expense_amount')| number:'1.2-2' }}</th> -->
                                            <th>{{ getSum('balance')| number:'1.2-2' }}</th>
                                            <th>{{ getSum('in_dollar')| number:'1.2-2' }}</th>
                                        </tr>
                                    </tbody>

                                    <tbody *ngIf="cashadminList?.length == 0">
                                        <tr *ngIf="!loading">
                                            <td colspan="3" class="no-data-available">No Data!</td>
                                        </tr>
                                        <tr>
                                            <td colspan="3" class="no-data-available">
                                                <div class="col" *ngIf="loading">
                                                    <div class="text-center">
                                                        <div class="spinner-border" role="status">
                                                            <span class="sr-only">Loading...</span>
                                                        </div>
                                                        <div>
                                                            Please Wait...
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <ngb-pagination class="d-flex justify-content-end mt-2 mr-2" *ngIf="collectionSize > 0"
                                    [collectionSize]="collectionSize" [pageSize]="lengths" [(page)]="pageBasicText"
                                    (pageChange)="loadPage($event)" aria-label="Custom pagination">
                                    <ng-template ngbPaginationPrevious><span [data-feather]="'chevron-left'"
                                            [class]="'font-weight-bolder'"></span>Prev
                                    </ng-template>
                                    <ng-template ngbPaginationNext>Next<span [data-feather]="'chevron-right'"
                                            [class]="'font-weight-bolder'"></span>
                                    </ng-template>
                                </ngb-pagination>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>

<!-- New User Sidebar -->
<core-sidebar class="modal modal-slide-in sidebar-todo-modal fade" name="new-sidebar" overlayClass="modal-backdrop">
    <div class="slideout-content">
        <div class="modalsd modal-slide-in sdfade new-modal" id="modals-slide-in">
            <div class="modal-dialog">
                <form class="add-new modal-content pt-0" [formGroup]="newFormAdd" (ngSubmit)="onSubmit()">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                        (click)="toggleSidebar('new-sidebar')">
                        ×
                    </button>
                    <div class="modal-header mb-1">
                        <h5 class="modal-title" id="exampleModalLabel">Cashend Report Filter</h5>
                    </div>
                    <div class="modal-body flex-grow-1 ">
                        <div class="form-group">
                            <label class="form-label">From Date</label>
                            <input type="date" formControlName="start_date" id="start_date" placeholder="dd-mm-yyyy"
                                class="form-control">
                        </div>
                        <div class="form-group">
                            <label class="form-label">To Date</label>
                            <input type="date" formControlName="end_date" id="end_date" placeholder="dd-mm-yyyy"
                                class="form-control">
                        </div>
                        <div class="form-group">
                            <label>Select User<span class="text-danger">*</span></label>
                            <ng-select formControlName="user_id" aria-describedby="register-user_id"
                                [ngClass]="{ 'is-invalid': submitted && f.user_id.errors }">
                                <ng-option disabled selected value="0">Select User</ng-option>
                                <ng-option *ngFor="let expCat of userDropDown"
                                    [value]="expCat.id">{{expCat.name}}</ng-option>
                            </ng-select>
                            <div *ngIf="submitted && f.user_id.errors" class="invalid-feedback">
                                <div *ngIf="f.user_id.errors.required">Date is required</div>
                            </div>
                        </div>
                        <div class="form-group">
                            <button type="submit" class="btn btn-primary mr-1 " rippleEffect [disabled]="loading ">
                                <span *ngIf="loading " class="spinner-border spinner-border-sm mr-1 "></span>Go</button>
                            <button type="reset" class="btn btn-primary mr-1 " (click)="resetReport()" rippleEffect
                                [disabled]="loading ">
                                <span *ngIf="loading " class="spinner-border spinner-border-sm mr-1 "></span>
                                <i data-feather="refresh-cw" class="d-sm-none d-inline-block mr-0 mr-sm-1"></i>
                                <span class="d-none d-sm-inline-block">Reset</span>
                            </button>
                            <a class="btn btn-secondary" (click)="toggleSidebar('new-sidebar')" rippleEffect>
                                <i data-feather="x" class="d-sm-none d-inline-block mr-0 mr-sm-1"></i>
                                <span class="d-none d-sm-inline-block">Cancel</span>
                            </a>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</core-sidebar>
<!--/ New User Sidebar -->