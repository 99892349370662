import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class ExpensecategoryService {

  constructor(private _http: HttpClient, private _toastrService: ToastrService) { }

  getData(start, length,user_id): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}get-expensecategory`, { start, length,user_id })
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  add(category_name:any, id: any,user_id:any): Observable<any> {
    if (id == 0) {
      return this._http
        .post(`${environment.apiUrl}save-expensecategory`, { category_name,user_id})
        .pipe(
          map(data => {
            return data;
          })
        );
    }
    else {
      return this._http
        .post(`${environment.apiUrl}update-expensecategory`, { category_name,id })
        .pipe(
          map(data => {
            return data;
          })
        );
    }
  }

  delete(id): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}delete-expensecategory`, { id })
      .pipe(
        map(data => {
          return data;
        })
      );
  }
}
