import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { ToastrService } from 'ngx-toastr';
@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  constructor(private _http: HttpClient, private _toastrService: ToastrService) { }

  getData(formdata: any): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}get-payment`,formdata)
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  getuserDropDown(): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}user-dropdown-payment`, { })
      .pipe(
        map(data => {
          return data;
        })
      );
  }
  getvendorDropDown(): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}vendor-dropdown`, { })
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  add(user_id: any,vendor_id:any,date:any, local_currency: any, foreign_currency: any, remark: any, id: any): Observable<any> {
    if (id == 0) {
      return this._http
        .post(`${environment.apiUrl}save-payment`, { user_id,vendor_id, date,local_currency, foreign_currency, remark })
        .pipe(
          map(data => {
            return data;
          })
        );
    }
    else {
      return this._http
        .post(`${environment.apiUrl}update-payment`, { user_id,vendor_id,date, local_currency, foreign_currency, remark, id })
        .pipe(
          map(data => {
            return data;
          })
        );
    }
  }

  addfile(file:any): Observable<any> {
    // if (id == 0) {
      return this._http
        .post(`${environment.apiUrl}payment-file-upload`,file)
        .pipe(
          map(data => {
            return data;
          })
        );
    // }
  }
}
