<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <section id="home-page">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Sales Payment List</h4>
                        </div>
                        <form class="add-new modal-content pt-0" [formGroup]="newForm" (ngSubmit)="onSubmitFilter()">
                            <div class="modal-body flex-grow-1 ">
                                <div class="row">
                                    <div class="col-lg-4">
                                        <div class="form-group">
                                            <label>Select Customer<span class="text-danger">*</span></label>
                                            <ng-select formControlName="customer_id" aria-describedby="register-customer_id">
                                                <ng-option disabled selected value="0">Select Customer</ng-option>
                                                <ng-option *ngFor="let cust of customerDropDown"
                                                    [value]="cust.id">{{cust.name}}</ng-option>
                                            </ng-select>
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="form-group">
                                            <label class="form-label">From Date</label>
                                            <input type="date" formControlName="start_date" id="start_date"
                                                placeholder="dd-mm-yyyy" class="form-control">
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class="form-group">
                                            <label class="form-label">To Date</label>
                                            <input type="date" formControlName="end_date" id="end_date"
                                                placeholder="dd-mm-yyyy" class="form-control">
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-4">
                                        <div class="form-group">
                                            <button type="submit" class="btn btn-primary mr-1 " rippleEffect
                                                [disabled]="loading ">
                                                <span *ngIf="loading "
                                                    class="spinner-border spinner-border-sm mr-1 "></span>Go</button>
                                            <button type="reset" class="btn btn-primary mr-1 " (click)="resetReport()"
                                                rippleEffect [disabled]="loading ">
                                                <span *ngIf="loading "
                                                    class="spinner-border spinner-border-sm mr-1 "></span>
                                                <i data-feather="refresh-cw"
                                                    class="d-sm-none d-inline-block mr-0 mr-sm-1"></i>
                                                <span class="d-none d-sm-inline-block">Reset</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div class="card-body p-0 pb-1">
                            <div class="row m-0">
                                <div class="col-md-6 col-6">
                                    <div class="d-flex justify-content-between align-items-center ml-1">
                                        <label class="d-flex align-items-center">Show
                                            <select class="form-control mx-25" [(ngModel)]="lengths"
                                                (change)="loadPageLenghs($event)">
                                                <option value="15">15</option>
                                                <option value="30">30</option>
                                                <option value="50">50</option>
                                                <option value="100">100</option>
                                            </select>
                                        </label>
                                    </div>
                                </div>
                                <div class="col-md-6 col-6 d-flex justify-content-start justify-content-md-end">
                                    <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pb-md-0 w-100">
                                        <button class="btn btn-info ml-1" rippleEffect (click)="downloadExcelData()">
                                            <i data-feather="plus"
                                                class="d-sm-none d-inline-block mr-0 mr-sm-1"></i><span
                                                class="d-none d-sm-inline-block">Download</span>
                                        </button>
                                        <button class="btn btn-primary ml-1" rippleEffect
                                            (click)="toggleSidebar('new-sidebar')">
                                            <i data-feather="plus"
                                                class="d-sm-none d-inline-block mr-0 mr-sm-1"></i><span
                                                class="d-none d-sm-inline-block">Add New</span>
                                        </button>
                                        <!-- <button class="btn btn-warning ml-1" rippleEffect
                                            (click)="toggleSidebar('new-sidebar-filter')">
                                            <i data-feather="filter" class="d-sm-none d-inline-block mr-0 mr-sm-1"></i>
                                            <span class="d-none d-sm-inline-block">Filter</span>
                                        </button> -->
                                    </div>
                                </div>
                            </div>
                            <div class="col p-0 table-responsive">
                                <div class="overlay" *ngIf="progressMainTable">
                                    <div class="spinner-grow" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </div>
                                <table class="table table-striped table-hover valign-middle text-dark">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Customer Name</th>
                                            <th>Invoice No</th>
                                            <th>Amount</th>
                                            <th>Dollar Amount</th>
                                            <th style="width: 110px;">Date</th>
                                            <th>Remark</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>

                                    <tbody *ngIf="salpaymentList?.length != 0">
                                        <tr *ngFor="let ware of salpaymentList; let ndx = index">
                                            <td>{{ndx+page+1}}</td>
                                            <td>{{ ware.customer_name }}</td>
                                            <td>{{ ware.invoice_no }}</td>
                                            <td>{{ ware.amount | number:'1.2-2'}}</td>
                                            <td>{{ ware.in_dollar | number:'1.2-2'}}</td>
                                            <td>{{ ware.date }}</td>
                                            <td>{{ ware.remark }}</td>
                                            <td>
                                                <a (click)="edit(ndx)" class="btn btn-icon btn-sm btn-info mr-1 mb-1"
                                                    data-toggle="tooltip" data-popup="tooltip-custom" placement="top"
                                                    container="body" ngbTooltip="Edit">
                                                    <i data-feather="edit-2"></i>
                                                </a>
                                                <!-- <div ngbDropdown container="body">
                                                    <button type="button" class="btn btn-sm hide-arrow" ngbDropdownToggle data-toggle="dropdown">
                                                      <i data-feather="more-vertical"></i>
                                                    </button>
                                                    <div ngbDropdownMenu>
                                                        <a ngbDropdownItem href="javascript:void(0);" (click)="edit(ndx)"><i data-feather="edit" class="mr-50"></i><span>Edit</span></a>
                                                        <a ngbDropdownItem href="javascript:void(0);" (click)="delete(ware.id)"><i data-feather="trash" class="mr-50"></i><span>Delete</span></a>
                                                    </div>
                                                </div> -->
                                            </td>
                                        </tr>
                                        <tr class="total_bg" *ngFor="let ware of salpaymentListtotal;">
                                            <th></th>
                                            <th>Total</th>
                                            <th></th>
                                            <th>{{ ware.tot_total | number:'1.2-2' }}</th>
                                            <th>{{ ware.tot_dollar | number:'1.2-2' }}</th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="salpaymentList?.length == 0">
                                        <tr *ngIf="!loading">
                                            <td colspan="3" class="no-data-available">No Data!</td>
                                        </tr>
                                        <tr>
                                            <td colspan="3" class="no-data-available">
                                                <div class="col" *ngIf="loading">
                                                    <div class="text-center">
                                                        <div class="spinner-border" role="status">
                                                            <span class="sr-only">Loading...</span>
                                                        </div>
                                                        <div>
                                                            Please Wait...
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <ngb-pagination class="d-flex justify-content-end mt-2 mr-2" *ngIf="collectionSize > 0"
                                    [collectionSize]="collectionSize" [pageSize]="lengths" [(page)]="pageBasicText"
                                    (pageChange)="loadPage($event)" aria-label="Custom pagination">
                                    <ng-template ngbPaginationPrevious><span [data-feather]="'chevron-left'"
                                            [class]="'font-weight-bolder'"></span>Prev
                                    </ng-template>
                                    <ng-template ngbPaginationNext>Next<span [data-feather]="'chevron-right'"
                                            [class]="'font-weight-bolder'"></span>
                                    </ng-template>
                                </ngb-pagination>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>



<!-- New User Sidebar -->
<core-sidebar class="modal modal-slide-in sidebar-todo-modal fade" name="new-sidebar" overlayClass="modal-backdrop">
    <div class="slideout-content">
        <!-- Modal to add new starts-->
        <div class="modalsd modal-slide-in sdfade new-modal" id="modals-slide-in">
            <div class="modal-dialog">
                <form class="add-new modal-content pt-0" [formGroup]="newFormAdd" (ngSubmit)="onSubmit()">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                        (click)="toggleSidebar('new-sidebar')">
                        ×
                    </button>
                    <div class="modal-header mb-1">
                        <h5 class="modal-title" id="exampleModalLabel">Add Sales Payment</h5>
                    </div>
                    <div class="modal-body flex-grow-1 ">
                        <div class="row m-0">
                            <div class="col">
                                <div class="form-group">
                                    <label for="register-customer_id" class="form-label">Customer<span
                                            class="text-danger">*</span> </label>
                                    <ng-select formControlName="customer_id" placeholder="Select Customer"
                                        aria-describedby="register-customer_id"
                                        [ngClass]="{ 'is-invalid': submitted && f.customer_id.errors }">
                                        <ng-option disabled selected value="0">Select Customer</ng-option>
                                        <ng-option *ngFor="let ven of customerDropDown"
                                            [value]="ven.id">{{ven.name}}</ng-option>
                                    </ng-select>
                                    <div *ngIf="submitted && f.customer_id.errors" class="invalid-feedback">
                                        <div *ngIf="f.customer_id.errors.required">Customer is required</div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="register-amount" class="form-label">Amount<span
                                            class="text-danger">*</span> </label>
                                    <input type="text" formControlName="amount" class="form-control"
                                        placeholder="Enter Amount" aria-describedby="register-amount"
                                        [ngClass]="{ 'is-invalid': submitted && f.amount.errors }" />
                                    <div *ngIf="submitted && f.amount.errors" class="invalid-feedback">
                                        <div *ngIf="f.amount.errors.required">Amount is required</div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="register-date" class="form-label">Date<span class="text-danger">*</span>
                                    </label>
                                    <input type="date" formControlName="date" class="form-control"
                                        placeholder="Enter Date" aria-describedby="register-date"
                                        [ngClass]="{ 'is-invalid': submitted && f.date.errors }" />
                                    <div *ngIf="submitted && f.date.errors" class="invalid-feedback">
                                        <div *ngIf="f.date.errors.required">Date is required</div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="register-remark" class="form-label">Remark</label>
                                    <input type="text" formControlName="remark" class="form-control"
                                        placeholder="Enter Remark" aria-describedby="register-remark"
                                        [ngClass]="{ 'is-invalid': submitted && f.remark.errors }" />
                                </div>
                                <div class="form-group">
                                    <div class="col-lg-12 p-0">
                                        <ngb-alert [type]="'danger'" [dismissible]="false" *ngIf="error">
                                            <div class="alert-body">
                                                <p>{{ error }}</p>
                                            </div>
                                        </ngb-alert>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="col-lg-12 p-0">
                                        <button type="reset" class="btn btn-secondary"
                                            (click)="toggleSidebar('new-sidebar')" [disabled]="loadingFrm" rippleEffect>
                                            <span *ngIf="loadingFrm"
                                                class="spinner-border spinner-border-sm mr-1"></span>
                                            <i data-feather="x" class="d-sm-none d-inline-block mr-0 mr-sm-1"></i>
                                            <span class="d-none d-sm-inline-block">Cancel </span>
                                        </button>
                                        <button type="submit" class="btn btn-primary ml-1 " rippleEffect
                                            [disabled]="loadingFrm"> <span *ngIf="loadingFrm"
                                                class="spinner-border spinner-border-sm mr-1"></span>Submit</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <!-- Modal to add new Ends-->
    </div>
</core-sidebar>
<!--/ New User Sidebar -->

<!-- New User Sidebar -->
<!-- <core-sidebar class="modal modal-slide-in sidebar-todo-modal fade" name="new-sidebar-filter"
    overlayClass="modal-backdrop">
    <div class="slideout-content">
        <div class="modalsd modal-slide-in sdfade new-modal" id="modals-slide-in">
            <div class="modal-dialog">
                <form class="add-new modal-content pt-0" [formGroup]="newForm" (ngSubmit)="onSubmitFilter()">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                        (click)="toggleSidebar('new-sidebar')">
                        ×
                    </button>
                    <div class="modal-header mb-1">
                        <h5 class="modal-title" id="exampleModalLabel">Expense Report Filter</h5>
                    </div>
                    <div class="modal-body flex-grow-1 ">
                        <div class="form-group">
                            <label class="form-label">From Date</label>
                            <input type="date" formControlName="start_date" id="start_date" placeholder="dd-mm-yyyy"
                                class="form-control">
                        </div>
                        <div class="form-group">
                            <label class="form-label">To Date</label>
                            <input type="date" formControlName="end_date" id="end_date" placeholder="dd-mm-yyyy"
                                class="form-control">
                        </div>

                        <div class="form-group">
                            <button type="submit" class="btn btn-primary mr-1 " rippleEffect [disabled]="loading ">
                                <span *ngIf="loading " class="spinner-border spinner-border-sm mr-1 "></span>Go</button>
                            <button type="reset" class="btn btn-primary mr-1 " (click)="resetReport()" rippleEffect
                                [disabled]="loading ">
                                <span *ngIf="loading " class="spinner-border spinner-border-sm mr-1 "></span>
                                <i data-feather="refresh-cw" class="d-sm-none d-inline-block mr-0 mr-sm-1"></i>
                                <span class="d-none d-sm-inline-block">Reset</span>
                            </button>
                            <a class="btn btn-secondary" (click)="toggleSidebar('new-sidebar')" rippleEffect>
                                <i data-feather="x" class="d-sm-none d-inline-block mr-0 mr-sm-1"></i>
                                <span class="d-none d-sm-inline-block">Cancel</span>
                            </a>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</core-sidebar> -->
<!--/ New User Sidebar -->