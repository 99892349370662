import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import Swal from "sweetalert2";
import { ToastrService } from "ngx-toastr";
import { AuthenticationService } from "app/auth/service";
import { Router, ActivatedRoute } from "@angular/router";
import { CoreSidebarService } from "@core/components/core-sidebar/core-sidebar.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ProductService } from 'app/_services/product.service';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit {

  public productList: any = [];
  public start: any = 0;
  public page: any = 0;

  public sidebarToggleRef = false;
  public contentHeader: object;
  public pageBasicText = 1;
  public collectionSize = 0;
  public lengths = 30;
  public loading = false;
  public progressMainTable: boolean = false;
  public newFormAdd: FormGroup;
  public submitted = false;
  public isAddMode: boolean = true;
  public loadingFrm: boolean = false;
  public productId: any = 0;
  public error = "";
  public file: any = '';
  public file2: any = '';

  constructor(
    private _router: Router,
    private route: ActivatedRoute,
    private ProductService: ProductService,
    private _authenticationService: AuthenticationService,
    private _formBuilder: FormBuilder,
    private _toastrService: ToastrService,
    private http: HttpClient,
    private _coreSidebarService: CoreSidebarService
  ) { }

  ngOnInit(): void {
    this.productsList(this.start, this.lengths);
    this.newFormAdd = this._formBuilder.group({
      product_name: ["", Validators.required],
      unit: ["", Validators.required],
      file: [""],
      file2: [""]
    });
  }

  get f() {
    return this.newFormAdd.controls;
  }
  uploadFile(event: any, type: any) {
    if (type == 1) {
      this.file = event.target.files[0];
    }
  }
  uploadFile2(event: any, type: any) {
    if (type == 1) {
      this.file2 = event.target.files[0];
    }
  }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.newFormAdd.invalid) {
      this.newFormAdd.markAllAsTouched();
    } else {
      this.loadingFrm = true;
      this.error = "";
      // const { product_name,unit } = this.newFormAdd.controls;
      const formdata = new FormData();
      formdata.append('file', this.file);
      formdata.append('file2', this.file2);
      formdata.append('product_name', this.newFormAdd.get('product_name').value);
      formdata.append('unit', this.newFormAdd.get('unit').value);
      formdata.append('id', this.productId);

      // this.ProductService.add(product_name.value,unit.value,this.productId
      this.ProductService.add(formdata,this.productId
      ).subscribe(
        (data) => {
          this.loadingFrm = false;
          if (data.status) {
            this.productId = 0;
            this.submitted = false;
            this.newFormAdd.reset();
            this.toggleSidebar("new-sidebar");
            this._toastrService.success(data.message, "Success!", {
              toastClass: "toast ngx-toastr",
              closeButton: true,
            });
            this.reloadList();
          } else {
            this.error = data.message;
          }
        },
        (err) => {
          if (err.error.error == "Unauthenticated.") {
            this._authenticationService.logout();
            this._router.navigate(["/login"]);
          }
        }
      );
    }
  }

  toggleSidebar(name, type: any = 0): void {
    if (type == 0) {
      this.productId = 0;
    }
    this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
  }

  productsList(my_start: any, my_lengths: any) {
    this.ProductService.getData(my_start, my_lengths).subscribe(
      (response) => {
        this.loading = false;
        this.progressMainTable = false;
        if (response.status) {
          this.productList = response.data;
          this.collectionSize = response.recordsTotal;
        }
      },
      (err) => {
        if (err.error.error == "Unauthenticated.") {
          this._authenticationService.logout();
          this._router.navigate(["/login"]);
        }
      }
    );
  }

  reloadList() {
    this.progressMainTable = true;
    this.productsList(this.page, this.lengths);
  }

  loadPage(event: any) {
    this.progressMainTable = true;
    this.page = this.lengths * (event - 1);
    this.productsList(this.page, this.lengths);
  }
  loadPageLenghs(event: any) {
    this.progressMainTable = true;
    this.lengths = event.target.value;
    this.productsList(this.start, this.lengths);
  }

  edit(index: any) {
    this.productId = this.productList[index].id;
    this.f.product_name.setValue(this.productList[index].product_name);
    this.f.unit.setValue(this.productList[index].unit);

    this.toggleSidebar("new-sidebar", 1);
  }

  delete(id: any) {
    const me = this;
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#7367F0",
      cancelButtonColor: "#E42728",
      confirmButtonText: "Yes, delete it!",
      customClass: {
        confirmButton: "btn btn-primary",
        cancelButton: "btn btn-danger ml-1",
      },
    }).then(function (result) {
      if (result.value) {
        me.ProductService.delete(id).subscribe(
          (response) => {
            if (response.status) {
              me.progressMainTable = true;
              me.reloadList();
              Swal.fire({
                icon: "success",
                title: "Deleted!",
                text: "Product Deleted Successfully",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
            }
          },
          (err) => {
            if (err.error.error == "Unauthenticated.") {
              this._authenticationService.logout();
              this._router.navigate(["/login"]);
            }
          }
        );
      }
    });
  }

  downloadExcelData() {
    this.ProductService.fileDownload().subscribe(
      (data) => {
        window.location.href=data.url;
      },
      (error) => {
        this._toastrService.error('Something went wrong! Please Try Againg...', 'Opps!', { toastClass: 'toast ngx-toastr', closeButton: true });
      }
    );
  }
}
