<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <section id="home-page">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Sales Product Report</h4>
                        </div>
                        <form class="add-new modal-content pt-0" [formGroup]="newFormAdd" (ngSubmit)="onSubmit()">
                            <div class="modal-body flex-grow-1">
                                <div class="row">
                                    <div class="col-lg-6">
                                        <div class="form-group">
                                            <label>Select Product<span class="text-danger">*</span></label>
                                            <ng-select formControlName="product_id" aria-describedby="register-product_id"
                                                [ngClass]="{ 'is-invalid': submitted && f.product_id.errors }">
                                                <ng-option disabled selected value="0">Select Product</ng-option>
                                                <ng-option *ngFor="let pro of productDropDown"
                                                    [value]="pro.id">{{pro.product_name}}</ng-option>
                                            </ng-select>
                                            <div *ngIf="submitted && f.product_id.errors" class="invalid-feedback">
                                                <div *ngIf="f.product_id.errors.required">Date is required</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4" *ngIf="currentUser.user.id == 1">
                                        <div class="form-group">
                                            <label>Select User<span class="text-danger">*</span></label>
                                            <ng-select formControlName="user_id" aria-describedby="register-user_id"
                                                [ngClass]="{ 'is-invalid': submitted && f.user_id.errors }">
                                                <ng-option disabled selected value="0">Select User</ng-option>
                                                <ng-option *ngFor="let usr of userDropDown"
                                                    [value]="usr.id">{{usr.name}}</ng-option>
                                            </ng-select>
                                            <div *ngIf="submitted && f.user_id.errors" class="invalid-feedback">
                                                <div *ngIf="f.user_id.errors.required">Date is required</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3">
                                        <div class="form-group">
                                            <label class="form-label">From Date</label>
                                            <input type="date" formControlName="start_date" id="start_date"
                                                placeholder="dd-mm-yyyy" class="form-control">
                                        </div>
                                    </div>
                                    <div class="col-lg-3">
                                        <div class="form-group">
                                            <label class="form-label">To Date</label>
                                            <input type="date" formControlName="end_date" id="end_date"
                                                placeholder="dd-mm-yyyy" class="form-control">
                                        </div>
                                    </div>
                                    
                                </div>
                                <div class="row">
                                    <div class="col-lg-4">
                                        <div class="form-group">
                                            <button type="submit" class="btn btn-primary mr-1 " rippleEffect
                                                [disabled]="loading "> <span *ngIf="loading "
                                                    class="spinner-border spinner-border-sm mr-1 "></span>Go</button>
                                            <button type="reset" class="btn btn-primary mr-1 " (click)="resetReport()"
                                                rippleEffect [disabled]="loading ">
                                                <span *ngIf="loading "
                                                    class="spinner-border spinner-border-sm mr-1 "></span>
                                                <i data-feather="refresh-cw"
                                                    class="d-sm-none d-inline-block mr-0 mr-sm-1"></i>
                                                <span class="d-none d-sm-inline-block">Reset</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div class="card-body p-0 pb-1">
                            <div class="row m-0">
                                <div class="col-md-6 col-6">
                                    <div class="d-flex justify-content-between align-items-center ml-1">
                                        <label class="d-flex align-items-center">Show
                                            <select class="form-control mx-25" [(ngModel)]="lengths"
                                                (change)="loadPageLenghs($event)">
                                                <option value="15">15</option>
                                                <option value="30">30</option>
                                                <option value="50">50</option>
                                                <option value="100">100</option>
                                            </select>
                                        </label>
                                    </div>
                                </div>
                                <div class="col-md-6 col-6 d-flex justify-content-start justify-content-md-end">
                                    <div class="align-items-center justify-content-end pb-1 pb-md-0">
                                        <button class="btn btn-info ml-1" rippleEffect (click)="downloadExcelData()">
                                            <i data-feather="plus"
                                                class="d-sm-none d-inline-block mr-0 mr-sm-1"></i><span
                                                class="d-none d-sm-inline-block">Download</span>
                                        </button>
                                        <!-- <button class="btn btn-warning ml-1" rippleEffect
                                            (click)="toggleSidebar('new-sidebar')">
                                            <i data-feather="filter" class="d-sm-none d-inline-block mr-0 mr-sm-1"></i>
                                            <span class="d-none d-sm-inline-block">Filter</span>
                                        </button> -->
                                    </div>
                                </div>
                            </div>
                            <div class="col p-0 table-responsive">
                                <div class="overlay" *ngIf="progressMainTable">
                                    <div class="spinner-grow" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </div>
                                <table class="table table-striped table-hover valign-middle text-dark">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>User Name</th>
                                            <th>Customer Name</th>
                                            <th>Date</th>
                                            <th>Type</th>
                                            <th>Qty</th>
                                            <th>Price</th>
                                            <th>Total</th>
                                            <th>In Dollar</th>
                                        </tr>
                                    </thead>

                                    <tbody *ngIf="salesDataList?.length != 0">
                                        <tr *ngFor="let expense of salesDataList; let ndx = index">
                                            <td>{{ndx+page+1}}</td>
                                            <td>{{ expense.user_name }}</td>
                                            <td>{{ expense.customer_name }}</td>
                                            <td>{{ expense.date }}</td>
                                            <td>{{ expense.type }}</td>
                                            <td>{{ expense.qty}}</td>
                                            <td>{{ expense.price | number:'1.2-2'}}</td>
                                            <td>{{ expense.total }}</td>
                                            <td>{{ expense.in_dollar | number:'1.2-2'}}</td>
                                        </tr>
                                        <tr class="total_bg" *ngFor="let tot of salestotal;">
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th>Total</th>
                                            <th>{{ tot.tot_qty | number:'1.2-2' }}</th>
                                            <th>{{ tot.tot_price | number:'1.2-2' }}</th>
                                            <th>{{ tot.tot_total | number:'1.2-2' }}</th>
                                            <th>{{ tot.tot_dollar | number:'1.2-2' }}</th>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="salesDataList?.length == 0">
                                        <tr *ngIf="!loading">
                                            <td colspan="9" class="no-data-available">No Data!</td>
                                        </tr>
                                        <tr>
                                            <td colspan="9" class="no-data-available">
                                                <div class="col" *ngIf="loading">
                                                    <div class="text-center">
                                                        <div class="spinner-border" role="status">
                                                            <span class="sr-only">Loading...</span>
                                                        </div>
                                                        <div>
                                                            Please Wait...
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                                <ngb-pagination class="d-flex justify-content-end mt-2 mr-2" *ngIf="collectionSize > 0"
                                    [collectionSize]="collectionSize" [pageSize]="lengths" [(page)]="pageBasicText"
                                    (pageChange)="loadPage($event)" aria-label="Custom pagination">
                                    <ng-template ngbPaginationPrevious><span [data-feather]="'chevron-left'"
                                            [class]="'font-weight-bolder'"></span>Prev
                                    </ng-template>
                                    <ng-template ngbPaginationNext>Next<span [data-feather]="'chevron-right'"
                                            [class]="'font-weight-bolder'"></span>
                                    </ng-template>
                                </ngb-pagination>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>