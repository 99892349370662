<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <section id="home-page">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Manage Stock List</h4>
                        </div>
                        <div class="card-body p-0 pb-1">
                            <div class="row m-0">
                                <div class="col-md-6 col-6">
                                    <div class="d-flex justify-content-between align-items-center ml-1">
                                        <label class="d-flex align-items-center">Show
                                        <select class="form-control mx-25" [(ngModel)]="lengths" (change)="loadPageLenghs($event)" >
                                            <option value="30">30</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                        </select>
                                      </label>
                                    </div>
                                </div>
                                <div class="col-md-6 col-6 d-flex justify-content-start justify-content-md-end">
                                    <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pb-md-0 w-100">
                                        <button class="btn btn-primary ml-1" rippleEffect (click)="toggleSidebar('new-sidebar')">
                                        <i data-feather="plus" class="d-sm-none d-inline-block mr-0 mr-sm-1"></i
                                        ><span class="d-none d-sm-inline-block">Add New</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="col p-0 table-responsive">
                                <div class="overlay" *ngIf="progressMainTable">
                                    <div class="spinner-grow" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </div>
                                <table class="table table-striped table-hover valign-middle text-dark">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>User</th>
                                            <th>Product</th>
                                            <th>Qty</th>
                                            <th>Remark</th>
                                        </tr>
                                    </thead>

                                    <tbody *ngIf="stockList?.length != 0">
                                        <tr *ngFor="let ware of stockList; let ndx = index">
                                            <td>{{ndx+page+1}}</td>
                                            <td>{{ ware.name }}</td>
                                            <td>{{ ware.product_name }}</td>
                                            <td>{{ ware.qty }}</td>
                                            <td>{{ ware.remark }}</td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="stockList?.length == 0">
                                        <tr *ngIf="!loading">
                                            <td colspan="3" class="no-data-available">No Data!</td>
                                        </tr>
                                        <tr>
                                            <td colspan="3" class="no-data-available">
                                                <div class="col" *ngIf="loading">
                                                    <div class="text-center">
                                                        <div class="spinner-border" role="status">
                                                            <span class="sr-only">Loading...</span>
                                                        </div>
                                                        <div>
                                                            Please Wait...
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <ngb-pagination class="d-flex justify-content-end mt-2 mr-2" *ngIf="collectionSize > 0" [collectionSize]="collectionSize" [pageSize]="lengths" [(page)]="pageBasicText" (pageChange)="loadPage($event)" aria-label="Custom pagination">
                                    <ng-template ngbPaginationPrevious><span [data-feather]="'chevron-left'" [class]="'font-weight-bolder'"></span>Prev
                                    </ng-template>
                                    <ng-template ngbPaginationNext>Next<span [data-feather]="'chevron-right'" [class]="'font-weight-bolder'"></span>
                                    </ng-template>
                                </ngb-pagination>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>

<!-- New User Sidebar -->
<core-sidebar class="modal modal-slide-in sidebar-todo-modal fade" name="new-sidebar" overlayClass="modal-backdrop">
    <div class="slideout-content">
        <!-- Modal to add new starts-->
        <div class="modalsd modal-slide-in sdfade new-modal" id="modals-slide-in">
            <div class="modal-dialog">
                <form class="add-new modal-content pt-0" [formGroup]="newFormAdd" (ngSubmit)="onSubmit()">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="toggleSidebar('new-sidebar')">
                      ×
                    </button>
                    <div class="modal-header mb-1">
                        <h5 class="modal-title" id="exampleModalLabel">Manage Stock</h5>
                    </div>
                    <div class="modal-body flex-grow-1 ">
                        <div class="row m-0">
                            <div class="col">
                                <div class="form-group">
                                    <label>Select User<span class="text-danger">*</span></label>
                                    <ng-select formControlName="user_id" aria-describedby="register-user_id" [ngClass]="{ 'is-invalid': submitted && f.user_id.errors }">
                                        <ng-option disabled selected value="0">Select User</ng-option>
                                        <ng-option *ngFor="let expCat of userDropDown" [value]="expCat.id">{{expCat.name}}</ng-option>
                                    </ng-select>
                                    <div *ngIf="submitted && f.user_id.errors" class="invalid-feedback">
                                        <div *ngIf="f.user_id.errors.required">User is required</div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label>Select Product<span class="text-danger">*</span></label>
                                    <ng-select formControlName="product_id" aria-describedby="register-product_id" [ngClass]="{ 'is-invalid': submitted && f.product_id.errors }">
                                        <ng-option disabled selected value="0">Select Product</ng-option>
                                        <ng-option *ngFor="let expCat of productDropDown" [value]="expCat.id">{{expCat.product_name}}</ng-option>
                                    </ng-select>
                                    <div *ngIf="submitted && f.product_id.errors" class="invalid-feedback">
                                        <div *ngIf="f.product_id.errors.required">Product is required</div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label>Select Type<span class="text-danger">*</span></label>
                                    <ng-select formControlName="type" placeholder="Select Type" aria-describedby="register-type" [ngClass]="{ 'is-invalid': submitted && f.type.errors }">
                                        <ng-option disabled selected value="0">Select Type</ng-option>
                                        <ng-option [value]="1">Plus</ng-option>
                                        <ng-option [value]="2">Minus</ng-option>
                                    </ng-select>
                                    <div *ngIf="submitted && f.type.errors" class="invalid-feedback">
                                        <div *ngIf="f.type.errors.required">Type is required</div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="register-qty" class="form-label">Qty<span class="text-danger">*</span> </label>
                                    <input type="text" formControlName="qty" class="form-control" placeholder="Enter Qty" aria-describedby="register-qty" [ngClass]="{ 'is-invalid': submitted && f.qty.errors }" />
                                    <div *ngIf="submitted && f.qty.errors" class="invalid-feedback">
                                        <div *ngIf="f.qty.errors.required">Qty is required</div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="register-remark" class="form-label">Remark<span class="text-danger"></span> </label>
                                    <input type="text" formControlName="remark" class="form-control" placeholder="Enter Remark" aria-describedby="register-remark" [ngClass]="{ 'is-invalid': submitted && f.remark.errors }" />
                                    <div *ngIf="submitted && f.remark.errors" class="invalid-feedback">
                                        <div *ngIf="f.remark.errors.required">Remark is required</div>
                                    </div>
                                </div>
                                
                                <div class="form-group">
                                    <div class="col-lg-12 p-0">
                                        <ngb-alert [type]="'danger'" [dismissible]="false" *ngIf="error">
                                            <div class="alert-body">
                                                <p>{{ error }}</p>
                                            </div>
                                        </ngb-alert>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="col-lg-12 p-0">
                                        <button type="reset" class="btn btn-secondary" (click)="toggleSidebar('new-sidebar')" [disabled]="loadingFrm" rippleEffect>
                                            <span *ngIf="loadingFrm" class="spinner-border spinner-border-sm mr-1"></span>
                                            <i data-feather="x" class="d-sm-none d-inline-block mr-0 mr-sm-1"></i>
                                            <span class="d-none d-sm-inline-block">Cancel </span>                                    
                                        </button>
                                        <button type="submit" class="btn btn-primary ml-1 " rippleEffect [disabled]="loadingFrm"> <span *ngIf="loadingFrm" class="spinner-border spinner-border-sm mr-1"></span>Submit</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <!-- Modal to add new Ends-->
    </div>
</core-sidebar>
<!--/ New User Sidebar -->