import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class ProductService {

  constructor(private _http: HttpClient, private _toastrService: ToastrService) { }

  getData(start, length): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}get-product`, { start, length })
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  // add(product_name:any,unit:any, id: any): Observable<any> {
  add(formdata: any, id: any): Observable<any> {
    if (id === 0) {
      return this._http
        // .post(`${environment.apiUrl}save-product`, { product_name, unit})
        .post(`${environment.apiUrl}save-product`,formdata)
        .pipe(
          map(data => {
            return data;
          })
        );
    }
    else {
      return this._http
        // .post(`${environment.apiUrl}update-product`, { product_name,unit,id })
        .post(`${environment.apiUrl}update-product`, formdata)
        .pipe(
          map(data => {
            return data;
          })
        );
    }
  }

  delete(id): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}delete-product`, { id })
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  fileDownload(): Observable<any> {
    return this._http
      .get(`${environment.apiUrl}export-product`)
      .pipe(
        map(data => {
          return data;
        })
      );

  }
}
