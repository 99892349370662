import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PurchaseService {

  constructor(private _http: HttpClient) { }
  geteta(): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}get-eta`,{})
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  getData(formdata: any): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}get-purchase`,formdata)
      .pipe(
        map(data => {
          return data;
        })
      );
  }
  getDataReceive(formdata: any): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}receive-purchase`,formdata)
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  edit(id: string): Observable<any> {
    return this._http.post(`${environment.apiUrl}edit-purchase`, {id});
  }

  add(user_id:any,vendor_id:any,date:any,vehicle_details:any,remark:any,consignee_name:any,eta:any, id: any,final_total:any,purchaseProducts:any): Observable<any> {
    if (id == 0) {
      return this._http
        .post(`${environment.apiUrl}save-purchase`, { user_id, vendor_id,date,vehicle_details,remark,consignee_name,eta,final_total,purchaseProducts})
        .pipe(
          map(data => {
            return data;
          })
        );
    }
    else {
      return this._http
        .post(`${environment.apiUrl}update-purchase`, { user_id,vendor_id, date,vehicle_details,remark,consignee_name,eta,final_total,purchaseProducts, id })
        .pipe(
          map(data => {
            return data;
          })
        );
    }
  }

  getmeta(id: string): Observable<any> {
    return this._http.post(`${environment.apiUrl}get-metapurchase`, {id});
  }

  getuserDropDown(): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}user-dropdown`, { })
      .pipe(
        map(data => {
          return data;
        })
      );
  }
  getproductDropDown(): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}product-dropdown`, { })
      .pipe(
        map(data => {
          return data;
        })
      );
  }
  getvendorDropDown(): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}vendor-dropdown`, { })
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  orderchangeStatus(id: string, status: string): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}change-status`, { status,id })
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  addfile(file:any): Observable<any> {
    // if (id == 0) {
      return this._http
        .post(`${environment.apiUrl}file-upload`,file)
        .pipe(
          map(data => {
            return data;
          })
        );
    // }
  }
  getallgraph(formdata: any): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}all-branch-graph`,formdata)
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  getsenegalgraph(formdata: any): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}get-senegal-graph`,formdata)
      .pipe(
        map(data => {
          return data;
        })
      );
  }
  getmaligraph(formdata: any): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}get-mali-graph`,formdata)
      .pipe(
        map(data => {
          return data;
        })
      );
  }
  getconakrygraph(formdata: any): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}get-conakry-graph`,formdata)
      .pipe(
        map(data => {
          return data;
        })
      );
  }
  getgambiagraph(formdata: any): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}get-gambia-graph`,formdata)
      .pipe(
        map(data => {
          return data;
        })
      );
  }
  getlometogograph(formdata: any): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}get-lometogo-graph`,formdata)
      .pipe(
        map(data => {
          return data;
        })
      );
  }
  getburkinagraph(formdata: any): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}get-burkina-graph`,formdata)
      .pipe(
        map(data => {
          return data;
        })
      );
  }
}
