import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class CashendService {

  constructor(private _http: HttpClient, private _toastrService: ToastrService) { }
  getData(formdata: any): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}cashend-report`,formdata)
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  fileDownload(formdata: any): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}export-cashend-report`,formdata)
      .pipe(
        map(data => {
          return data;
        })
      );
  }
}
