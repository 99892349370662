import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import Swal from "sweetalert2";
import { ToastrService } from "ngx-toastr";
import { AuthenticationService } from "app/auth/service";
import { Router, ActivatedRoute } from "@angular/router";
import { CoreSidebarService } from "@core/components/core-sidebar/core-sidebar.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AdminoutstandingService } from 'app/_services/adminoutstanding.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-adminoutstanding',
  templateUrl: './adminoutstanding.component.html',
  styleUrls: ['./adminoutstanding.component.scss']
})
export class AdminoutstandingComponent implements OnInit {

  public outstandingList: any = [];
  public cashadminList: any = [];
  public start: any = 0;
  public page: any = 0;

  public sidebarToggleRef = false;
  public contentHeader: object;
  public pageBasicText = 1;
  public collectionSize = 0;
  public lengths = 30;
  public loading = false;
  public progressMainTable: boolean = false;
  public newFormAdd: FormGroup;
  public submitted = false;
  public loadingFrm: boolean = false;
  public categoryId = 0;
  public error = "";
  public currentUser: any;
  public isModelLoading: Boolean =true;
  public outstandingDetails:any;
  public excel_user_id:any = 0;

  constructor(
    private _router: Router,
    private route: ActivatedRoute,
    private adminoutstandingService: AdminoutstandingService,
    private _authenticationService: AuthenticationService,
    private _formBuilder: FormBuilder,
    private _toastrService: ToastrService,
    private http: HttpClient,
    private modalService: NgbModal,
    private _coreSidebarService: CoreSidebarService
  ) { }

  ngOnInit(): void {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.cashsList(1);
    this.newFormAdd = this._formBuilder.group({
      start_date: [''],
      end_date: [''],
      user_id: [null],
    });
  }

  get f() {
    return this.newFormAdd.controls;
  }
  cashsList(type: any = 1) {
    const formdata = new FormData();
    if (type != 1) {
      if (this.f.start_date.value !== null) {
        formdata.append('start_date', this.f.start_date.value);
      }
      if (this.f.end_date.value !== null) {
        formdata.append('end_date', this.f.end_date.value);
      }

      if (this.f.user_id.value != "") {
        formdata.append('user_id', this.f.user_id.value);
      }
    }
    this.adminoutstandingService.getData(formdata).subscribe(
      (response) => {
        this.loading = false;
        this.progressMainTable = false;
        if (response.status) {
          this.outstandingList = response.data;
          this.collectionSize = response.recordsTotal;
        }
      },
      (err) => {
        if (err.error.error == "Unauthenticated.") {
          this._authenticationService.logout();
          this._router.navigate(["/login"]);
        }
      }
    );
  }

  onSubmit() {
    this.submitted = true;
    this.loading = true;
    // this.toggleSidebar('new-sidebar');
    this.outstandingList = [];
    // stop here if form is invalid
    if (this.newFormAdd.invalid) {

    }
    else {
      const formdata = new FormData();
      if (this.f.start_date.value !== null) {
        formdata.append('start_date', this.f.start_date.value);
      }
      if (this.f.end_date.value !== null) {
        formdata.append('end_date', this.f.end_date.value);
      }
      if (this.f.user_id.value != "") {
        formdata.append('user_id', this.f.user_id.value);
      }

      this.adminoutstandingService.getData(formdata).subscribe(
        data => {
          this.loading = false;
          if (data.status) {
            this.outstandingList = data.data;
            this.collectionSize = data.recordsTotal;
          }
        },
        err => {
          if (err.error.error == 'Unauthenticated.') {
            this._authenticationService.logout();
            this._router.navigate(['/login']);
          }
        }
      );
    }
  }

  resetReport()
  {
    this.newFormAdd.reset();
    this.onSubmit();
  }

  downloadExcelData() {
    const formdata = new FormData();
    if (this.f.start_date.value !== null) {
      formdata.append('start_date', this.f.start_date.value);
    }
    if (this.f.end_date.value !== null) {
      formdata.append('end_date', this.f.end_date.value);
    }
    if (this.f.user_id.value !== null) {
      formdata.append('user_id', this.f.user_id.value);
    }

    this.adminoutstandingService.fileDownload(formdata).subscribe(
      (data) => {
        window.location.href = data.url;
      },
      (error) => {
        this._toastrService.error('Something went wrong! Please Try Againg...', 'Opps!', { toastClass: 'toast ngx-toastr', closeButton: true });
      }
    );
  }
  downloadExcelDataCustomer() {
    
    const formdata = new FormData();
    if (this.f.start_date.value !== null) {
      formdata.append('start_date', this.f.start_date.value);
    }
    if (this.f.end_date.value !== null) {
      formdata.append('end_date', this.f.end_date.value);
    }
    formdata.append('user_id', this.excel_user_id);


    this.adminoutstandingService.fileDownloadCustomer(formdata).subscribe(
      (data) => {
        window.location.href = data.url;
      },
      (error) => {
        this._toastrService.error('Something went wrong! Please Try Againg...', 'Opps!', { toastClass: 'toast ngx-toastr', closeButton: true });
      }
    );
  }

  // reloadList() {
  //   this.progressMainTable = true;
  //   this.cashsList(this.page, this.lengths);
  // }

  // loadPage(event: any) {
  //   this.progressMainTable = true;
  //   this.page = this.lengths * (event - 1);
  //   this.cashsList(this.page, this.lengths);
  // }
  // loadPageLenghs(event: any) {
  //   this.progressMainTable = true;
  //   this.lengths = event.target.value;
  //   this.cashsList(this.start, this.lengths);
  // }

  details(user_id: any,modalSM) {
    this.modalService.open(modalSM, {
      centered: true,
      size: 'xl'
    });
    this.excel_user_id = user_id;
    const formdata = new FormData();
    if (this.f.start_date.value !== null) {
      formdata.append('start_date', this.f.start_date.value);
    }
    if (this.f.end_date.value !== null) {
      formdata.append('end_date', this.f.end_date.value);
    }
    formdata.append('user_id', user_id);
    

    this.adminoutstandingService.getmeta(formdata).subscribe(
      data => {
          this.isModelLoading = false;
          if (data.status) {
              this.outstandingDetails = data.data;
          }else {
              this._toastrService.error(data.errorMessage, 'Error!');
          }
      },
      err => {
          this._toastrService.error(err.errorMessage, 'Opps!');
      }
    );
  }
}
