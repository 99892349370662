import { Component, OnInit, ViewChild } from '@angular/core'
import { Router } from '@angular/router';
import { PurchaseService } from 'app/_services/purchase.service';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { AuthenticationService } from 'app/auth/service';
import { ToastrService } from 'ngx-toastr';
import Swal from "sweetalert2";
import { ChartComponent } from "ng-apexcharts";

import {
  ApexNonAxisChartSeries,
  ApexResponsive,
  ApexChart
} from "ng-apexcharts";
export type ChartOptions = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  responsive: ApexResponsive[];
  labels: any;
  colors: any[];
};

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  public etaList: any = [];
  @ViewChild("chart") chart: ChartComponent;
  public allchartOptions: Partial<ChartOptions>;
  public senegalchartOptions: Partial<ChartOptions>;
  public malichartOptions: Partial<ChartOptions>;
  public conakrychartOptions: Partial<ChartOptions>;
  public gambiachartOptions: Partial<ChartOptions>;
  public lometogochartOptions: Partial<ChartOptions>;
  public burkinachartOptions: Partial<ChartOptions>;
  public currentUser: any;
  public tempUser: any;
  public user_id: any = 0;
  public newFormAdd: FormGroup;

  constructor(private purchaseService: PurchaseService,
    private _router: Router,
    private _toastrService: ToastrService,
    private _formBuilder: FormBuilder,
    private _authenticationService: AuthenticationService,
  ) { }

  public contentHeader: object

  // Lifecycle Hooks
  // -----------------------------------------------------------------------------------------------------

  /**
   * On init
   */
  ngOnInit() {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.tempUser = JSON.parse(localStorage.getItem('tempUser'));
    if (this.currentUser.user.id != this.tempUser) {
      this.user_id = this.tempUser;
    } else {
      this.user_id = this.currentUser.user.id;
    }
    this.newFormAdd = this._formBuilder.group({
      start_date: [''],
      end_date: [''],
    });

    this.dashboardchart();

    this.contentHeader = {
      headerTitle: 'Home',
      actionButton: true,
      breadcrumb: {
        type: '',
        links: [
          {
            name: 'Home',
            isLink: true,
            link: '/'
          },
          {
            name: 'Sample',
            isLink: false
          }
        ]
      }
    }

    this.purchaseService.geteta().subscribe(
      (response) => {
        if (response.status) {
          this.etaList = response.data;
          Swal.fire({
            icon: "success",
            background: 'green',
            html: '<span style="color:#fff">' + response.msg + '<span>',
            customClass: {
              confirmButton: "btn btn-success",
            },
          });
        }
      },
      (err) => {
        if (err.error.error == "Unauthenticated.") {
          this._authenticationService.logout();
          this._router.navigate(["/login"]);
        }
      }
    );
  }
  get f() {
    return this.newFormAdd.controls;
  }
  onSubmit() {
    const formdata = new FormData();
    if (this.f.start_date.value !== null) {
      formdata.append('start_date', this.f.start_date.value);
    }
    if (this.f.end_date.value !== null) {
      formdata.append('end_date', this.f.end_date.value);
    }
    this.purchaseService.getallgraph(formdata).subscribe(
      (response) => {
        if (response.status) {
          this.allchartOptions = {
            series: [response.data.expenseTotal, response.data.creditPayment, response.data.debitPayment, response.data.sales],
            chart: {
              width: 580,
              type: "pie",
            },
            colors: ['#ff0000', '#449D44', '#F0AD4E', '#337AB7'],
            labels: ["Total Expense - <b>$ " + response.data.expenseTotal, "</b>Cash Sales - <b>$ " + response.data.creditPayment, "</b>Credit Sales - <b>$ " + response.data.debitPayment, "</b>Total Sales - <b>$ " + response.data.sales + '</b>'],
            // labels: response.data,
            responsive: [
              {
                breakpoint: 480,
                options: {
                  chart: {
                    width: 200
                  },
                  legend: {
                    position: "bottom"
                  }
                }
              }
            ],
          };
        }
      },
      (err) => {
        if (err.error.error == "Unauthenticated.") {
          this._authenticationService.logout();
          this._router.navigate(["/login"]);
        }
      }
    );
  }
  onSubmitSenegal(){
    const formdata = new FormData();
    if (this.f.start_date.value !== null) {
      formdata.append('start_date', this.f.start_date.value);
    }
    if (this.f.end_date.value !== null) {
      formdata.append('end_date', this.f.end_date.value);
    }
    this.purchaseService.getsenegalgraph(formdata).subscribe(
      (response) => {
        if (response.status) {
          this.senegalchartOptions = {
            series: [response.data.expenseTotal, response.data.creditPayment, response.data.debitPayment, response.data.sales],
            chart: {
              width: 380,
              type: "pie"
            },
            colors: ['#ff0000', '#449D44', '#F0AD4E', '#337AB7'],
            labels: ["Total Expense - <b>$ " + response.data.expenseTotal, "</b>Cash Sales - <b>$ " + response.data.creditPayment, "</b>Credit Sales - <b>$ " + response.data.debitPayment, "</b>Total Sales - <b>$ " + response.data.sales + '</b>'],
            // labels: response.data,
            responsive: [
              {
                breakpoint: 480,
                options: {
                  chart: {
                    width: 200
                  },
                  legend: {
                    position: "bottom"
                  }
                }
              }
            ]
          };
        }
      },
      (err) => {
        if (err.error.error == "Unauthenticated.") {
          this._authenticationService.logout();
          this._router.navigate(["/login"]);
        }
      }
    );
  }
  onSubmitMali(){
    const formdata = new FormData();
    if (this.f.start_date.value !== null) {
      formdata.append('start_date', this.f.start_date.value);
    }
    if (this.f.end_date.value !== null) {
      formdata.append('end_date', this.f.end_date.value);
    }
    this.purchaseService.getmaligraph(formdata).subscribe(
      (response) => {
        if (response.status) {
          this.malichartOptions = {
            series: [response.data.expenseTotal, response.data.creditPayment, response.data.debitPayment, response.data.sales],
            chart: {
              width: 380,
              type: "pie"
            },
            colors: ['#ff0000', '#449D44', '#F0AD4E', '#337AB7'],
            labels: ["Total Expense - <b>$ " + response.data.expenseTotal, "</b>Cash Sales - <b>$ " + response.data.creditPayment, "</b>Credit Sales - <b>$ " + response.data.debitPayment, "</b>Total Sales - <b>$ " + response.data.sales + '</b>'],
            // labels: response.data,
            responsive: [
              {
                breakpoint: 480,
                options: {
                  chart: {
                    width: 200
                  },
                  legend: {
                    position: "bottom"
                  }
                }
              }
            ]
          };
        }
      },
      (err) => {
        if (err.error.error == "Unauthenticated.") {
          this._authenticationService.logout();
          this._router.navigate(["/login"]);
        }
      }
    );
  }
  onSubmitConakry(){
    const formdata = new FormData();
    if (this.f.start_date.value !== null) {
      formdata.append('start_date', this.f.start_date.value);
    }
    if (this.f.end_date.value !== null) {
      formdata.append('end_date', this.f.end_date.value);
    }
    this.purchaseService.getconakrygraph(formdata).subscribe(
      (response) => {
        if (response.status) {
          this.conakrychartOptions = {
            series: [response.data.expenseTotal, response.data.creditPayment, response.data.debitPayment, response.data.sales],
            chart: {
              width: 380,
              type: "pie"
            },
            colors: ['#ff0000', '#449D44', '#F0AD4E', '#337AB7'],
            labels: ["Total Expense - <b>$ " + response.data.expenseTotal, "</b>Cash Sales - <b>$ " + response.data.creditPayment, "</b>Credit Sales - <b>$ " + response.data.debitPayment, "</b>Total Sales - <b>$ " + response.data.sales + '</b>'],
            // labels: response.data,
            responsive: [
              {
                breakpoint: 480,
                options: {
                  chart: {
                    width: 200
                  },
                  legend: {
                    position: "bottom"
                  }
                }
              }
            ]
          };
        }
      },
      (err) => {
        if (err.error.error == "Unauthenticated.") {
          this._authenticationService.logout();
          this._router.navigate(["/login"]);
        }
      }
    );
  }
  onSubmitGambia(){
    const formdata = new FormData();
    if (this.f.start_date.value !== null) {
      formdata.append('start_date', this.f.start_date.value);
    }
    if (this.f.end_date.value !== null) {
      formdata.append('end_date', this.f.end_date.value);
    }
    this.purchaseService.getgambiagraph(formdata).subscribe(
      (response) => {
        if (response.status) {
          this.gambiachartOptions = {
            series: [response.data.expenseTotal, response.data.creditPayment, response.data.debitPayment, response.data.sales],
            chart: {
              width: 380,
              type: "pie"
            },
            colors: ['#ff0000', '#449D44', '#F0AD4E', '#337AB7'],
            labels: ["Total Expense - <b>$ " + response.data.expenseTotal, "</b>Cash Sales - <b>$ " + response.data.creditPayment, "</b>Credit Sales - <b>$ " + response.data.debitPayment, "</b>Total Sales - <b>$ " + response.data.sales + '</b>'],
            // labels: response.data,
            responsive: [
              {
                breakpoint: 480,
                options: {
                  chart: {
                    width: 200
                  },
                  legend: {
                    position: "bottom"
                  }
                }
              }
            ]
          };
        }
      },
      (err) => {
        if (err.error.error == "Unauthenticated.") {
          this._authenticationService.logout();
          this._router.navigate(["/login"]);
        }
      }
    );
  }
  onSubmitLome(){
    const formdata = new FormData();
    if (this.f.start_date.value !== null) {
      formdata.append('start_date', this.f.start_date.value);
    }
    if (this.f.end_date.value !== null) {
      formdata.append('end_date', this.f.end_date.value);
    }
    this.purchaseService.getlometogograph(formdata).subscribe(
      (response) => {
        if (response.status) {
          this.lometogochartOptions = {
            series: [response.data.expenseTotal, response.data.creditPayment, response.data.debitPayment, response.data.sales],
            chart: {
              width: 380,
              type: "pie"
            },
            colors: ['#ff0000', '#449D44', '#F0AD4E', '#337AB7'],
            labels: ["Total Expense - <b>$ " + response.data.expenseTotal, "</b>Cash Sales - <b>$ " + response.data.creditPayment, "</b>Credit Sales - <b>$ " + response.data.debitPayment, "</b>Total Sales - <b>$ " + response.data.sales + '</b>'],
            // labels: response.data,
            responsive: [
              {
                breakpoint: 480,
                options: {
                  chart: {
                    width: 200
                  },
                  legend: {
                    position: "bottom"
                  }
                }
              }
            ]
          };
        }
      },
      (err) => {
        if (err.error.error == "Unauthenticated.") {
          this._authenticationService.logout();
          this._router.navigate(["/login"]);
        }
      }
    );
  }
  onSubmitBurkina(){
    const formdata = new FormData();
    if (this.f.start_date.value !== null) {
      formdata.append('start_date', this.f.start_date.value);
    }
    if (this.f.end_date.value !== null) {
      formdata.append('end_date', this.f.end_date.value);
    }
    this.purchaseService.getburkinagraph(formdata).subscribe(
      (response) => {
        if (response.status) {
          this.burkinachartOptions = {
            series: [response.data.expenseTotal, response.data.creditPayment, response.data.debitPayment, response.data.sales],
            chart: {
              width: 380,
              type: "pie"
            },
            colors: ['#ff0000', '#449D44', '#F0AD4E', '#337AB7'],
            labels: ["Total Expense - <b>$ " + response.data.expenseTotal, "</b>Cash Sales - <b>$ " + response.data.creditPayment, "</b>Credit Sales - <b>$ " + response.data.debitPayment, "</b>Total Sales - <b>$ " + response.data.sales + '</b>'],
            // labels: response.data,
            responsive: [
              {
                breakpoint: 480,
                options: {
                  chart: {
                    width: 200
                  },
                  legend: {
                    position: "bottom"
                  }
                }
              }
            ]
          };
        }
      },
      (err) => {
        if (err.error.error == "Unauthenticated.") {
          this._authenticationService.logout();
          this._router.navigate(["/login"]);
        }
      }
    );
  }
  dashboardchart() {
    const formdata = new FormData();

    this.purchaseService.getallgraph(formdata).subscribe(
      (response) => {
        if (response.status) {
          this.allchartOptions = {
            series: [response.data.expenseTotal, response.data.creditPayment, response.data.debitPayment, response.data.sales],
            chart: {
              width: 580,
              type: "pie",
            },
            colors: ['#ff0000', '#449D44', '#F0AD4E', '#337AB7'],
            labels: ["Total Expense - <b>$ " + response.data.expenseTotal, "</b>Cash Sales - <b>$ " + response.data.creditPayment, "</b>Credit Sales - <b>$ " + response.data.debitPayment, "</b>Total Sales - <b>$ " + response.data.sales + '</b>'],
            // labels: response.data,
            responsive: [
              {
                breakpoint: 480,
                options: {
                  chart: {
                    width: 200
                  },
                  legend: {
                    position: "bottom"
                  }
                }
              }
            ],
          };
        }
      },
      (err) => {
        if (err.error.error == "Unauthenticated.") {
          this._authenticationService.logout();
          this._router.navigate(["/login"]);
        }
      }
    );
    this.purchaseService.getsenegalgraph(formdata).subscribe(
      (response) => {
        if (response.status) {
          this.senegalchartOptions = {
            series: [response.data.expenseTotal, response.data.creditPayment, response.data.debitPayment, response.data.sales],
            chart: {
              width: 380,
              type: "pie"
            },
            colors: ['#ff0000', '#449D44', '#F0AD4E', '#337AB7'],
            labels: ["Total Expense - <b>$ " + response.data.expenseTotal, "</b>Cash Sales - <b>$ " + response.data.creditPayment, "</b>Credit Sales - <b>$ " + response.data.debitPayment, "</b>Total Sales - <b>$ " + response.data.sales + '</b>'],
            // labels: response.data,
            responsive: [
              {
                breakpoint: 480,
                options: {
                  chart: {
                    width: 200
                  },
                  legend: {
                    position: "bottom"
                  }
                }
              }
            ]
          };
        }
      },
      (err) => {
        if (err.error.error == "Unauthenticated.") {
          this._authenticationService.logout();
          this._router.navigate(["/login"]);
        }
      }
    );
    this.purchaseService.getmaligraph(formdata).subscribe(
      (response) => {
        if (response.status) {
          this.malichartOptions = {
            series: [response.data.expenseTotal, response.data.creditPayment, response.data.debitPayment, response.data.sales],
            chart: {
              width: 380,
              type: "pie"
            },
            colors: ['#ff0000', '#449D44', '#F0AD4E', '#337AB7'],
            labels: ["Total Expense - <b>$ " + response.data.expenseTotal, "</b>Cash Sales - <b>$ " + response.data.creditPayment, "</b>Credit Sales - <b>$ " + response.data.debitPayment, "</b>Total Sales - <b>$ " + response.data.sales + '</b>'],
            // labels: response.data,
            responsive: [
              {
                breakpoint: 480,
                options: {
                  chart: {
                    width: 200
                  },
                  legend: {
                    position: "bottom"
                  }
                }
              }
            ]
          };
        }
      },
      (err) => {
        if (err.error.error == "Unauthenticated.") {
          this._authenticationService.logout();
          this._router.navigate(["/login"]);
        }
      }
    );
    this.purchaseService.getconakrygraph(formdata).subscribe(
      (response) => {
        if (response.status) {
          this.conakrychartOptions = {
            series: [response.data.expenseTotal, response.data.creditPayment, response.data.debitPayment, response.data.sales],
            chart: {
              width: 380,
              type: "pie"
            },
            colors: ['#ff0000', '#449D44', '#F0AD4E', '#337AB7'],
            labels: ["Total Expense - <b>$ " + response.data.expenseTotal, "</b>Cash Sales - <b>$ " + response.data.creditPayment, "</b>Credit Sales - <b>$ " + response.data.debitPayment, "</b>Total Sales - <b>$ " + response.data.sales + '</b>'],
            // labels: response.data,
            responsive: [
              {
                breakpoint: 480,
                options: {
                  chart: {
                    width: 200
                  },
                  legend: {
                    position: "bottom"
                  }
                }
              }
            ]
          };
        }
      },
      (err) => {
        if (err.error.error == "Unauthenticated.") {
          this._authenticationService.logout();
          this._router.navigate(["/login"]);
        }
      }
    );
    this.purchaseService.getgambiagraph(formdata).subscribe(
      (response) => {
        if (response.status) {
          this.gambiachartOptions = {
            series: [response.data.expenseTotal, response.data.creditPayment, response.data.debitPayment, response.data.sales],
            chart: {
              width: 380,
              type: "pie"
            },
            colors: ['#ff0000', '#449D44', '#F0AD4E', '#337AB7'],
            labels: ["Total Expense - <b>$ " + response.data.expenseTotal, "</b>Cash Sales - <b>$ " + response.data.creditPayment, "</b>Credit Sales - <b>$ " + response.data.debitPayment, "</b>Total Sales - <b>$ " + response.data.sales + '</b>'],
            // labels: response.data,
            responsive: [
              {
                breakpoint: 480,
                options: {
                  chart: {
                    width: 200
                  },
                  legend: {
                    position: "bottom"
                  }
                }
              }
            ]
          };
        }
      },
      (err) => {
        if (err.error.error == "Unauthenticated.") {
          this._authenticationService.logout();
          this._router.navigate(["/login"]);
        }
      }
    );
    this.purchaseService.getlometogograph(formdata).subscribe(
      (response) => {
        if (response.status) {
          this.lometogochartOptions = {
            series: [response.data.expenseTotal, response.data.creditPayment, response.data.debitPayment, response.data.sales],
            chart: {
              width: 380,
              type: "pie"
            },
            colors: ['#ff0000', '#449D44', '#F0AD4E', '#337AB7'],
            labels: ["Total Expense - <b>$ " + response.data.expenseTotal, "</b>Cash Sales - <b>$ " + response.data.creditPayment, "</b>Credit Sales - <b>$ " + response.data.debitPayment, "</b>Total Sales - <b>$ " + response.data.sales + '</b>'],
            // labels: response.data,
            responsive: [
              {
                breakpoint: 480,
                options: {
                  chart: {
                    width: 200
                  },
                  legend: {
                    position: "bottom"
                  }
                }
              }
            ]
          };
        }
      },
      (err) => {
        if (err.error.error == "Unauthenticated.") {
          this._authenticationService.logout();
          this._router.navigate(["/login"]);
        }
      }
    );
    this.purchaseService.getburkinagraph(formdata).subscribe(
      (response) => {
        if (response.status) {
          this.burkinachartOptions = {
            series: [response.data.expenseTotal, response.data.creditPayment, response.data.debitPayment, response.data.sales],
            chart: {
              width: 380,
              type: "pie"
            },
            colors: ['#ff0000', '#449D44', '#F0AD4E', '#337AB7'],
            labels: ["Total Expense - <b>$ " + response.data.expenseTotal, "</b>Cash Sales - <b>$ " + response.data.creditPayment, "</b>Credit Sales - <b>$ " + response.data.debitPayment, "</b>Total Sales - <b>$ " + response.data.sales + '</b>'],
            // labels: response.data,
            responsive: [
              {
                breakpoint: 480,
                options: {
                  chart: {
                    width: 200
                  },
                  legend: {
                    position: "bottom"
                  }
                }
              }
            ]
          };
        }
      },
      (err) => {
        if (err.error.error == "Unauthenticated.") {
          this._authenticationService.logout();
          this._router.navigate(["/login"]);
        }
      }
    );
  }
}
