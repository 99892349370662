import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OrderService {

  constructor(private _http: HttpClient) { }

  getData(formdata: any): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}get-order`,formdata)
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  getvendorDropDown(): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}vendor-dropdown`, { })
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  delete(id: string): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}delete-order`, { id })
      .pipe(
        map(data => {
          return data;
        })
      );
  }
  getmeta(id: string): Observable<any> {
    return this._http.post(`${environment.apiUrl}get-metaorder`, {id});
  }
}
