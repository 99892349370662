import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import Swal from "sweetalert2";
import { ToastrService } from "ngx-toastr";
import { AuthenticationService } from "app/auth/service";
import { Router, ActivatedRoute } from "@angular/router";
import { CoreSidebarService } from "@core/components/core-sidebar/core-sidebar.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { CompanyService } from 'app/_services/company.service';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.scss']
})
export class CompanyComponent implements OnInit {
  public companyList: any = [];
  public start: any = 0;
  public page: any = 0;

  public sidebarToggleRef = false;
  public contentHeader: object;
  public pageBasicText = 1;
  public collectionSize = 0;
  public lengths = 30;
  public loading = false;
  public progressMainTable: boolean = false;
  public newFormAdd: FormGroup;
  public submitted = false;
  public isAddMode: boolean = true;
  public loadingFrm: boolean = false;
  public companyId = 0;
  public error = "";

  constructor(
    private _router: Router,
    private route: ActivatedRoute,
    private companyService: CompanyService,
    private _authenticationService: AuthenticationService,
    private _formBuilder: FormBuilder,
    private _toastrService: ToastrService,
    private http: HttpClient,
    private _coreSidebarService: CoreSidebarService
  ) { }

  ngOnInit(): void {
    this.companysList(this.start, this.lengths);
    this.newFormAdd = this._formBuilder.group({
      company_name: ["", Validators.required]
    });
  }
  get f() {
    return this.newFormAdd.controls;
  }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.newFormAdd.invalid) {
      this.newFormAdd.markAllAsTouched();
    } else {
      this.loadingFrm = true;
      this.error = "";
      const { company_name } = this.newFormAdd.controls;
      
      this.companyService.add(
        company_name.value,
        this.companyId
      ).subscribe(
        (data) => {
          this.loadingFrm = false;
          if (data.status) {
            this.companyId = 0;
            this.submitted = false;
            this.newFormAdd.reset();
            this.toggleSidebar("new-sidebar");
            this._toastrService.success(data.message, "Success!", {
              toastClass: "toast ngx-toastr",
              closeButton: true,
            });
            this.reloadList();
          } else {
            this.error = data.message;
          }
        },
        (err) => {
          if (err.error.error == "Unauthenticated.") {
            this._authenticationService.logout();
            this._router.navigate(["/login"]);
          }
        }
      );
    }
  }
  toggleSidebar(name, type: any = 0): void {
    if (type == 0) {
      this.companyId = 0;
    }
    this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
  }

  companysList(my_start: any, my_lengths: any) {
    this.companyService.getData(my_start, my_lengths).subscribe(
      (response) => {
        this.loading = false;
        this.progressMainTable = false;
        if (response.status) {
          this.companyList = response.data;
          this.collectionSize = response.recordsTotal;
        }
      },
      (err) => {
        if (err.error.error == "Unauthenticated.") {
          this._authenticationService.logout();
          this._router.navigate(["/login"]);
        }
      }
    );
  }

  reloadList() {
    this.progressMainTable = true;
    this.companysList(this.page, this.lengths);
  }

  loadPage(event: any) {
    this.progressMainTable = true;
    this.page = this.lengths * (event - 1);
    this.companysList(this.page, this.lengths);
  }
  loadPageLenghs(event: any) {
    this.progressMainTable = true;
    this.lengths = event.target.value;
    this.companysList(this.start, this.lengths);
  }

  edit(index: any) {
    this.companyId = this.companyList[index].id;
    this.f.company_name.setValue(this.companyList[index].company_name);

    this.toggleSidebar("new-sidebar", 1);
  }

  delete(id: any) {
    const me = this;
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#7367F0",
      cancelButtonColor: "#E42728",
      confirmButtonText: "Yes, delete it!",
      customClass: {
        confirmButton: "btn btn-primary",
        cancelButton: "btn btn-danger ml-1",
      },
    }).then(function (result) {
      if (result.value) {
        me.companyService.delete(id).subscribe(
          (response) => {
            if (response.status) {
              me.progressMainTable = true;
              me.reloadList();
              Swal.fire({
                icon: "success",
                title: "Deleted!",
                text: "Company Deleted Successfully",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
            }
          },
          (err) => {
            if (err.error.error == "Unauthenticated.") {
              this._authenticationService.logout();
              this._router.navigate(["/login"]);
            }
          }
        );
      }
    });
  }
}
