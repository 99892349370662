<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <section id="home-page">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Exporter List</h4>
                        </div>
                        <div class="card-body p-0 pb-1">
                            <div class="row m-0">
                                <div class="col-md-6 col-6">
                                    <div class="d-flex justify-content-between align-items-center ml-1">
                                        <label class="d-flex align-items-center">Show
                                        <select class="form-control mx-25" [(ngModel)]="lengths" (change)="loadPageLenghs($event)" >
                                            <option value="30">30</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                        </select>
                                      </label>
                                    </div>
                                </div>
                                <div class="col-md-6 col-6 d-flex justify-content-start justify-content-md-end">
                                    <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pb-md-0 w-100">
                                        <button class="btn btn-info ml-1" rippleEffect
                                            (click)="downloadExcelData()">
                                            <i data-feather="plus"
                                                class="d-sm-none d-inline-block mr-0 mr-sm-1"></i><span
                                                class="d-none d-sm-inline-block">Download</span>
                                        </button>
                                        <button class="btn btn-primary ml-1" rippleEffect (click)="toggleSidebar('new-sidebar')">
                                        <i data-feather="plus" class="d-sm-none d-inline-block mr-0 mr-sm-1"></i
                                        ><span class="d-none d-sm-inline-block">Add New</span>
                                        </button>
                                        
                                    </div>
                                </div>
                            </div>
                            <div class="col p-0 table-responsive">
                                <div class="overlay" *ngIf="progressMainTable">
                                    <div class="spinner-grow" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </div>
                                <table class="table table-striped table-hover valign-middle text-dark">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Name</th>
                                            <th>Mobile</th>
                                            <th>Email</th>
                                            <th>Address</th>
                                            <th>Action</th>
                                            <th>Purchase Payment View</th>
                                        </tr>
                                    </thead>

                                    <tbody *ngIf="vendorList?.length != 0">
                                        <tr *ngFor="let ware of vendorList; let ndx = index">
                                            <td>{{ndx+page+1}}</td>
                                            <td>{{ ware.name }}</td>
                                            <td>{{ ware.mobile }}</td>
                                            <td>{{ ware.email }}</td>
                                            <td>{{ ware.address }}</td>
                                            <td>
                                                <div ngbDropdown container="body">
                                                    <button type="button" class="btn btn-sm hide-arrow" ngbDropdownToggle data-toggle="dropdown">
                                                      <i data-feather="more-vertical"></i>
                                                    </button>
                                                    <div ngbDropdownMenu>
                                                        <a ngbDropdownItem href="javascript:void(0);" (click)="edit(ndx)"><i data-feather="edit" class="mr-50"></i><span>Edit</span></a>
                                                        <a ngbDropdownItem href="javascript:void(0);" (click)="delete(ware.id)"><i data-feather="trash" class="mr-50"></i><span>Delete</span></a>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <button type="button" (click)="details(ware.id,modalSM)" data-toggle="tooltip" data-popup="tooltip-custom" placement="top" container="body" ngbTooltip="View" class="btn btn-icon btn-sm btn-warning mr-1" rippleEffect>
                                                    <i data-feather="eye"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="vendorList?.length == 0">
                                        <tr *ngIf="!loading">
                                            <td colspan="3" class="no-data-available">No Data!</td>
                                        </tr>
                                        <tr>
                                            <td colspan="3" class="no-data-available">
                                                <div class="col" *ngIf="loading">
                                                    <div class="text-center">
                                                        <div class="spinner-border" role="status">
                                                            <span class="sr-only">Loading...</span>
                                                        </div>
                                                        <div>
                                                            Please Wait...
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <ngb-pagination class="d-flex justify-content-end mt-2 mr-2" *ngIf="collectionSize > 0" [collectionSize]="collectionSize" [pageSize]="lengths" [(page)]="pageBasicText" (pageChange)="loadPage($event)" aria-label="Custom pagination">
                                    <ng-template ngbPaginationPrevious><span [data-feather]="'chevron-left'" [class]="'font-weight-bolder'"></span>Prev
                                    </ng-template>
                                    <ng-template ngbPaginationNext>Next<span [data-feather]="'chevron-right'" [class]="'font-weight-bolder'"></span>
                                    </ng-template>
                                </ngb-pagination>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- Modal -->
        <ng-template #modalSM let-modal>
            <div class="overlay" *ngIf="isModelLoading">
                <div class="spinner-border text-primary" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
            <div class="modal-header">
                <h5 class="modal-title w-100" id="myModalLabel160">
                    <span class="float-left"> <span class="badge badge-glow badge-primary">Outstanding Details</span></span>
                </h5>
                <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body" tabindex="0" ngbAutofocus>
                <div class="row">
                    <div class="table-responsive m-t-40">
                        <table class="table table-hover">
                            <thead>
                                <tr>
                                    <th>Date &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</th>
                                    <!-- <th>Refrence &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</th> -->
                                    <!-- <th>Account Name &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</th> -->
                                    <th class="text-right">Credit &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</th>
                                    <th class="text-right">Debit &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</th>
                                    <th class="text-right">Closing Balance &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let outStanding of vendorOutStanding; let ndx = index">
                                    <td>{{ outStanding.payment_date }}</td>
                                    <!-- <td>{{ outStanding.refrence }}</td> -->
                                    <!-- <td>{{ outStanding.account_name }}</td> -->
                                    <td class="text-right" align="right" *ngIf="outStanding.payment_type == 'C'">{{ outStanding.amount | number:'1.2-2' }}</td>
                                    <td></td>
                                    <td class="text-right" align="right" *ngIf="outStanding.payment_type == 'D'">{{ outStanding.amount | number:'1.2-2' }}</td>
                                    <th class="text-right" align="right" *ngIf="outStanding.closing < 0"> {{(outStanding.closing | number:'1.2-2' + '').replace('-', '')}} DB </th>
                                    <th class="text-right" align="right" *ngIf="outStanding.closing >= 0">{{ outStanding.closing | number:'1.2-2' }} <span *ngIf="outStanding.closing > 0">CR</span> </th>
                                </tr>
                            </tbody>
                            <tbody *ngIf="salesDetails?.length == 0">
                                <tr>
                                    <td colspan="4" class="no-data-available">No data!</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </ng-template>
        <!-- / Modal -->
    </div>
</div>



<!-- New User Sidebar -->
<core-sidebar class="modal modal-slide-in sidebar-todo-modal fade" name="new-sidebar" overlayClass="modal-backdrop">
    <div class="slideout-content">
        <!-- Modal to add new starts-->
        <div class="modalsd modal-slide-in sdfade new-modal" id="modals-slide-in">
            <div class="modal-dialog">
                <form class="add-new modal-content pt-0" [formGroup]="newFormAdd" (ngSubmit)="onSubmit()">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="toggleSidebar('new-sidebar')">
                      ×
                    </button>
                    <div class="modal-header mb-1">
                        <h5 class="modal-title" id="exampleModalLabel">Add Exporter</h5>
                    </div>
                    <div class="modal-body flex-grow-1 ">
                        <div class="row m-0">
                            <div class="col">
                                <div class="form-group">
                                    <label for="register-name" class="form-label">Name<span class="text-danger">*</span> </label>
                                    <input type="text" formControlName="name" class="form-control" placeholder="Enter Name" aria-describedby="register-name" [ngClass]="{ 'is-invalid': submitted && f.name.errors }" />
                                    <div *ngIf="submitted && f.name.errors" class="invalid-feedback">
                                        <div *ngIf="f.name.errors.required">Name is required</div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="register-mobile" class="form-label">Mobile<span class="text-danger">*</span> </label>
                                    <input type="text" formControlName="mobile" class="form-control" placeholder="Enter Mobile" aria-describedby="register-mobile" [ngClass]="{ 'is-invalid': submitted && f.mobile.errors }" />
                                    <div *ngIf="submitted && f.mobile.errors" class="invalid-feedback">
                                        <div *ngIf="f.mobile.errors.required">Mobile is required</div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="register-email" class="form-label">Email<span class="text-danger"></span> </label>
                                    <input type="text" formControlName="email" class="form-control" placeholder="Enter Email" aria-describedby="register-email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" />
                                    <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                        <div *ngIf="f.email.errors.required">Email is required</div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="register-address" class="form-label">Address<span class="text-danger"></span> </label>
                                    <input type="text" formControlName="address" class="form-control" placeholder="Enter Address" aria-describedby="register-address" [ngClass]="{ 'is-invalid': submitted && f.address.errors }" />
                                    <div *ngIf="submitted && f.address.errors" class="invalid-feedback">
                                        <div *ngIf="f.address.errors.required">Address is required</div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="col-lg-12 p-0">
                                        <ngb-alert [type]="'danger'" [dismissible]="false" *ngIf="error">
                                            <div class="alert-body">
                                                <p>{{ error }}</p>
                                            </div>
                                        </ngb-alert>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="col-lg-12 p-0">
                                        <button type="reset" class="btn btn-secondary" (click)="toggleSidebar('new-sidebar')" [disabled]="loadingFrm" rippleEffect>
                                            <span *ngIf="loadingFrm" class="spinner-border spinner-border-sm mr-1"></span>
                                            <i data-feather="x" class="d-sm-none d-inline-block mr-0 mr-sm-1"></i>
                                            <span class="d-none d-sm-inline-block">Cancel </span>                                    
                                        </button>
                                        <button type="submit" class="btn btn-primary ml-1 " rippleEffect [disabled]="loadingFrm"> <span *ngIf="loadingFrm" class="spinner-border spinner-border-sm mr-1"></span>Submit</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <!-- Modal to add new Ends-->
    </div>
</core-sidebar>
<!--/ New User Sidebar -->