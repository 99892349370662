import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import Swal from "sweetalert2";
import { ToastrService } from "ngx-toastr";
import { AuthenticationService } from "app/auth/service";
import { Router, ActivatedRoute } from "@angular/router";
import { CoreSidebarService } from "@core/components/core-sidebar/core-sidebar.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ManageStockService } from 'app/_services/manage-stock.service';
import { PurchaseService } from 'app/_services/purchase.service';

@Component({
  selector: 'app-manage-stock',
  templateUrl: './manage-stock.component.html',
  styleUrls: ['./manage-stock.component.scss']
})
export class ManageStockComponent implements OnInit {

  public stockList: any = [];
  public start: any = 0;
  public page: any = 0;

  public sidebarToggleRef = false;
  public contentHeader: object;
  public pageBasicText = 1;
  public collectionSize = 0;
  public lengths = 30;
  public loading = false;
  public progressMainTable: boolean = false;
  public newFormAdd: FormGroup;
  public submitted = false;
  public isAddMode: boolean = true;
  public loadingFrm: boolean = false;
  public stockId = 0;
  public error = "";

  public userDropDown: any = [];
  public productDropDown: any = [];

  constructor(
    private _router: Router,
    private route: ActivatedRoute,
    private manageStockService: ManageStockService,
    private PurchaseService: PurchaseService,
    private _authenticationService: AuthenticationService,
    private _formBuilder: FormBuilder,
    private _toastrService: ToastrService,
    private http: HttpClient,
    private _coreSidebarService: CoreSidebarService
  ) { }

  ngOnInit(): void {
    this.getuserDropDown();
    this.getproductDropDown();

    this.stocksList(this.start, this.lengths);
    this.newFormAdd = this._formBuilder.group({
      user_id: ["", Validators.required],
      product_id: ["", Validators.required],
      type: ["", Validators.required],
      qty: ["", Validators.required],
      remark: [""]
    });
  }

  get f() {
    return this.newFormAdd.controls;
  }

  getproductDropDown() {
    this.PurchaseService.getproductDropDown().subscribe(
      data => {
        if (data.status) {
          this.productDropDown = data.product;
        }
      });
  }

  getuserDropDown() {
    this.PurchaseService.getuserDropDown().subscribe(
      data => {
        if (data.status) {
          this.userDropDown = data.user;
        }
      });
  }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.newFormAdd.invalid) {
      this.newFormAdd.markAllAsTouched();
    } else {
      this.loadingFrm = true;
      this.error = "";
      const { user_id,product_id,type,qty,remark } = this.newFormAdd.controls;
      this.manageStockService.add(user_id.value,product_id.value,type.value,qty.value,remark.value,this.stockId
      ).subscribe(
        (data) => {
          this.loadingFrm = false;
          if (data.status) {
            this.stockId = 0;
            this.submitted = false;
            this.newFormAdd.reset();
            this.toggleSidebar("new-sidebar");
            this._toastrService.success(data.message, "Success!", {
              toastClass: "toast ngx-toastr",
              closeButton: true,
            });
            this.reloadList();
          } else {
            this.error = data.message;
          }
        },
        (err) => {
          if (err.error.error == "Unauthenticated.") {
            this._authenticationService.logout();
            this._router.navigate(["/login"]);
          }
        }
      );
    }
  }

  toggleSidebar(name, type: any = 0): void {
    if (type == 0) {
      this.stockId = 0;
    }
    this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
  }

  stocksList(my_start: any, my_lengths: any) {
    this.manageStockService.getData(my_start, my_lengths).subscribe(
      (response) => {
        this.loading = false;
        this.progressMainTable = false;
        if (response.status) {
          this.stockList = response.data;
          this.collectionSize = response.recordsTotal;
        }
      },
      (err) => {
        if (err.error.error == "Unauthenticated.") {
          this._authenticationService.logout();
          this._router.navigate(["/login"]);
        }
      }
    );
  }

  reloadList() {
    this.progressMainTable = true;
    this.stocksList(this.page, this.lengths);
  }

  loadPage(event: any) {
    this.progressMainTable = true;
    this.page = this.lengths * (event - 1);
    this.stocksList(this.page, this.lengths);
  }
  loadPageLenghs(event: any) {
    this.progressMainTable = true;
    this.lengths = event.target.value;
    this.stocksList(this.start, this.lengths);
  }
}
