import { Component, Injectable, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { ToastrService } from "ngx-toastr";
import { AuthenticationService } from "app/auth/service";
import { Router, ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbDateAdapter, NgbDateParserFormatter, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import { OrderAddService } from 'app/_services/order-add.service';
import { OrderService } from 'app/_services/order.service';
import { PurchaseService } from 'app/_services/purchase.service';
import Swal from "sweetalert2";
import { log } from 'console';

@Injectable()
 export class CustomDateParserFormatter extends NgbDateParserFormatter {
   readonly DELIMITER = '-';
 
   parse(value: string): NgbDateStruct | null {
     if (value) {
       const date = value.split(this.DELIMITER);
       return {
         day: parseInt(date[0], 10),
         month: parseInt(date[1], 10),
         year: parseInt(date[2], 10),
       };
     }
     return null;
   }
 
   format(date: NgbDateStruct | null): string {
     return date ? date.day + this.DELIMITER + date.month + this.DELIMITER + date.year : '';
   }
}

@Component({
  selector: 'app-order-add',
  templateUrl: './order-add.component.html',
  styleUrls: ['./order-add.component.scss'],
  providers: [
		{ provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
	],
})
export class OrderAddComponent implements OnInit {
  public newForm: FormGroup;
  public submitted = false;
  public isAddMode: boolean = true;
  public loadingFrm: boolean = false;
  public orderId = 0;
  public final_total: any = 0.00;
  public error = "";
  public Orderno: any = [];
  
  public vendorDropDown: any = [];
  public productDropDown: any = [];
  public FormSubmit: Boolean = false;
  public addFormSubmit: Boolean = false;
  public loading = false;

  public today = new Date();
  public getDate = String(this.today. getDate()). padStart(2, '0');
  public getMonth = String(this.today. getMonth() + 1). padStart(2, '0'); //January is 0!
  public getYear = this.today. getFullYear();

  public maxDate = Number(this.getDate);
  public maxMonth = Number(this.getMonth);
  public maxYear = Number(this.getYear);
  public basicDPdata: NgbDateStruct = {day: this.maxDate, month: this.maxMonth, year: this.maxYear};

  constructor(
    private orderAddService: OrderAddService,
    private orderService: OrderService,
    private PurchaseService: PurchaseService,
    private _router: Router,
    private route: ActivatedRoute,
    private _authenticationService: AuthenticationService,
    private _formBuilder: FormBuilder,
    private _toastrService: ToastrService,
    private http: HttpClient,
  ) { }

  public orderProducts: any[] = [{
    id: 1,
    product_id: '0',
    price: '0',
    qty: '0',
    total: '0',
    weight: '0',
    product_remark: '',
    imageUrl: '',
  }];

  ngOnInit(): void {
    this.getvendorDropDown();
    this.getproductDropDown();
    this.getOrderno();

    this.newForm = this._formBuilder.group({
      order_to: ["", Validators.required],
      consignee_to: ["", Validators.required],
      date: ["", Validators.required],
      notify_party: [""],
      remark: [""],
      order_no: [""],
      note: [""],
    });

    this.orderId = this.route.snapshot.params['id'];
    this.isAddMode = !this.orderId;
    if (!this.isAddMode) {
      this.loading = true;
      this.edit(this.orderId);
    }else {
      this.orderId = 0;
    }
  
  }
  get f() {
    return this.newForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.newForm.invalid) {
      this.newForm.markAllAsTouched();
    } else {
      this.loadingFrm = true;
      this.error = "";
      let date = this.newForm.controls.date.value['year']+'-'+ this.newForm.controls.date.value['month']+'-'+this.newForm.controls.date.value['day'];
      const { order_to,consignee_to,notify_party,remark,order_no,note } = this.newForm.controls;      
      this.orderAddService.add(order_to.value,consignee_to.value,notify_party.value,date,note.value,remark.value,this.Orderno,this.orderId,this.final_total,this.orderProducts
      ).subscribe(
        (data) => {
          this.loadingFrm = false;
          if (data.status) {
            this.orderId = 0;
            this.submitted = false;
            this.newForm.reset();
            this._toastrService.success(data.message, "Success!", {
              toastClass: "toast ngx-toastr",
              closeButton: true,
            });
            this._router.navigate(['/order']);
          } else {
            this.error = data.message;
          }
        },
        (err) => {
          if (err.error.error == "Unauthenticated.") {
            this._authenticationService.logout();
            this._router.navigate(["/login"]);
          }
        }
      );
    }
  }

  getvendorDropDown() {
    this.orderService.getvendorDropDown().subscribe(
      data => {
        if (data.status) {
          this.vendorDropDown = data.vendor;
        }
      });
  }

  getproductDropDown() {
    this.PurchaseService.getproductDropDown().subscribe(
      data => {
        if (data.status) {
          this.productDropDown = data.product;
        }
      });
  }
  edit(id: any) {
    this.loadingFrm = true;
    this.orderAddService.edit(id).subscribe(
      data => {
        if (data.status) {
          this.loadingFrm = false;
          this.loading = false;
          const [year, month, day] = data.order.date.split('-');
          const obj = { year: parseInt(year), month: parseInt(month), day: 
            parseInt(day.split(' ')[0].trim()) };
            
          this.newForm.setValue({
            date:  {day: parseInt(day), month: parseInt(month), year: parseInt(year)},
            remark: data.order.remark,
            order_to: data.order.order_to,
            consignee_to: data.order.consignee_to,
            notify_party: data.order.notify_party,
            order_no: data.order.order_no,
            note: data.order.note,
          });
          this.final_total = data.order.final_total;

          this.orderId = data.order.id,
          this.orderProducts = data.order_details;
        } else {
          this._toastrService.error(data.errorMessage, 'Error!');
        }
      },
      err => {
        this._toastrService.error(err.errorMessage, 'Opps!');
      }
    );
  }

  addMore() {
    this.orderProducts.push({
      id: this.orderProducts.length + 1,
      product_id: '0',
      price: '0',
      qty: '0',
      total: '0',
      weight: '0',
      product_remark: '',
      imageUrl: '',
    });
  }
  addTotal(event:any){
    let temp_total = 0;
    for (let i = 0; i < this.orderProducts.length; i++) {
      this.orderProducts[i].total = (this.orderProducts[i].price * this.orderProducts[i].qty).toFixed(2);
      temp_total += parseFloat(this.orderProducts[i].total);
    }
    this.final_total = temp_total.toFixed(2);
  }
  remove(i: number, ord_tra_id: any = 0) {
    const me = this;
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to delete this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#7367F0",
      cancelButtonColor: "#E42728",
      confirmButtonText: "Yes, delete it!",
      customClass: {
        confirmButton: "btn btn-primary",
        cancelButton: "btn btn-danger ml-1",
      },
    }).then(function (result) {
      if (result.value) {
        me.orderAddService.delete(ord_tra_id).subscribe(
          (response) => {
            if (response.status) {
              Swal.fire({
                icon: "success",
                title: "Deleted!",
                text: "Record Deleted Successfully",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
              // remove code
              me.orderProducts.splice(i, 1);
              let temp_total = 0;
              for (let i = 0; i < me.orderProducts.length; i++) {
                me.orderProducts[i].total = (me.orderProducts[i].price * me.orderProducts[i].qty).toFixed(2);
                temp_total += parseFloat(me.orderProducts[i].total);
              }
              me.final_total = temp_total.toFixed(2);
              // remove code
            }
          },
          (err) => {
            if (err.error.error == "Unauthenticated.") {
              this._authenticationService.logout();
              this._router.navigate(["/login"]);
            }
          }
        );
      }
    });
    
  }
// change code
  onProductChange(pueProduct: any, selectedProductId: any): void {
    const selectedProduct = this.productDropDown.find(pro => pro.id === selectedProductId);
    const selectedImage = selectedProduct ? selectedProduct.image : null;
    pueProduct.imageUrl = selectedImage;
  }

  getOrderno() {
    this.orderAddService.getOrderno().subscribe(
      data => {
        if (data.status) {
          this.Orderno = data.order_no;
        }
      });
  }
}
