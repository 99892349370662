import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { ToastrService } from "ngx-toastr";
import { AuthenticationService } from "app/auth/service";
import { Router, ActivatedRoute } from "@angular/router";
import { CoreSidebarService } from "@core/components/core-sidebar/core-sidebar.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ExpenseService } from 'app/_services/expense.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-expense-report',
  templateUrl: './expense-report.component.html',
  styleUrls: ['./expense-report.component.scss']
})
export class ExpenseReportComponent implements OnInit {
  public expenseDataList: any = [];
  public start: any = 0;
  public page: any = 0;

  public sidebarToggleRef = false;
  public contentHeader: object;
  public pageBasicText = 1;
  public collectionSize = 0;
  public total_amount = 0;
  public lengths = 15;
  public loading = false;
  public progressMainTable: boolean = false;
  public newFormAdd: FormGroup;
  public submitted = false;
  public isAddMode: boolean = true;
  public loadingFrm: boolean = false;
  public categoryId = 0;
  public error = "";
  public categoryDropDown: any = [];
  public userDropDown: any = [];
  public currentUser: any;
  public isModelLoading: Boolean = true;
  public expenseDetails: any;
  public user_name: any;
  public tempUser: any;
  public meta_start_date: any;
  public meta_end_date: any;
  public user_id:any = 0;
  constructor(
    private _router: Router,
    private route: ActivatedRoute,
    private expenseService: ExpenseService,
    private _authenticationService: AuthenticationService,
    private _formBuilder: FormBuilder,
    private _toastrService: ToastrService,
    private http: HttpClient,
    private modalService: NgbModal,
    private _coreSidebarService: CoreSidebarService
  ) { }

  ngOnInit(): void {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.tempUser = JSON.parse(localStorage.getItem('tempUser'));
    
    if(this.currentUser.user.id != this.tempUser){
      this.user_id = this.tempUser;
    }else{
      this.user_id = this.currentUser.user.id;
    }
    // this.loading = true;
    this.expenseReportList(this.start, this.lengths,this.user_id);
    this.getcategoryDropDown(this.user_id);
    this.getuserDropDown();
    this.newFormAdd = this._formBuilder.group({
      start_date: [''],
      end_date: [''],
      expense_category_id: [null],
      user_id: [null],
    });
  }

  get f() {
    return this.newFormAdd.controls;
  }

  expenseReportList(my_start: any, my_lengths: any,my_user_id:any) {
    const formdata = new FormData();
    formdata.append('start', my_start);
    formdata.append('length', my_lengths);
    formdata.append('ses_user_id', my_user_id);
    
    this.expenseService.getAllData(formdata).subscribe(
      data => {
        this.loading = false;
        this.progressMainTable = false;
        if (data.status) {
          this.expenseDataList = data.data;
          this.total_amount = data.total_amount;
          this.collectionSize = data.recordsTotal;
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      }
    );
  }

  expenseReportListNew(my_start: any, my_lengths: any, type: any = 0) {
    const formdata = new FormData();
    if (type != 1) {
      formdata.append('start', my_start);
    }
    else {
      formdata.append('start', '0');
      this.page = 0;
      this.pageBasicText = 1;
    }
    formdata.append('length', my_lengths);
    formdata.append('ses_user_id', this.user_id);
    if (type != 1) {
      if (this.f.start_date.value !== null) {
        formdata.append('start_date', this.f.start_date.value);
      }
      if (this.f.end_date.value !== null) {
        formdata.append('end_date', this.f.end_date.value);
      }
      if (this.f.expense_category_id.value != "") {
        formdata.append('expense_category_id', this.f.expense_category_id.value);
      }
      if (this.f.user_id.value != "") {
        formdata.append('user_id', this.f.user_id.value);
      }
    }
    this.expenseService.getAllData(formdata).subscribe(
      data => {
        this.loading = false;
        this.progressMainTable = false;
        if (data.status) {
          this.expenseDataList = data.data;
          this.total_amount = data.total_amount;
          this.collectionSize = data.recordsTotal;
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      }
    );
  }

  reloadList(type: any = 0) {
    this.progressMainTable = true;
    this.expenseReportListNew(this.page, this.lengths, type);
  }

  loadPage(event: any) {
    this.progressMainTable = true;
    this.page = this.lengths * (event - 1);
    this.expenseReportListNew(this.page, this.lengths);
  }
  loadPageLenghs(event: any) {
    this.progressMainTable = true;
    this.lengths = event.target.value;
    this.expenseReportListNew(this.start, this.lengths);
  }

  getcategoryDropDown(my_user_id:any) {
    this.expenseService.getcategoryDropDown(my_user_id).subscribe(
      data => {
        if (data.status) {
          this.categoryDropDown = data.category;
        }
      });
  }
  getuserDropDown() {
    this.expenseService.getuserDropDown().subscribe(
      data => {
        if (data.status) {
          this.userDropDown = data.user;
        }
      });
  }

  onSubmit() {
    this.submitted = true;
    this.loading = true;
    // this.toggleSidebar('new-sidebar');
    this.expenseDataList = [];
    // stop here if form is invalid
    if (this.newFormAdd.invalid) {

    }
    else {
      this.page = 0;      
      this.pageBasicText = 1;
      
      const formdata = new FormData();
      formdata.append('start', "0");
      formdata.append('length', "15");
      formdata.append('ses_user_id', this.user_id);
      
      if (this.f.start_date.value !== null) {
        formdata.append('start_date', this.f.start_date.value);
      }
      if (this.f.end_date.value !== null) {
        formdata.append('end_date', this.f.end_date.value);
      }
      if (this.f.expense_category_id.value != "") {
        formdata.append('expense_category_id', this.f.expense_category_id.value);
      }
      if (this.f.user_id.value != "") {
        formdata.append('user_id', this.f.user_id.value);
      }

      this.expenseService.getAllData(formdata).subscribe(
        data => {
          this.loading = false;
          if (data.status) {
            this.expenseDataList = data.data;
            this.total_amount = data.total_amount;
            this.collectionSize = data.recordsTotal;
          }
        },
        err => {
          if (err.error.error == 'Unauthenticated.') {
            this._authenticationService.logout();
            this._router.navigate(['/login']);
          }
        }
      );
    }
  }

  toggleSidebar(name): void {
    this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
  }

  getSum(index: any): number {
    let sum = 0;
    for (let i = 0; i < this.expenseDataList.length; i++) {
      sum += parseFloat(this.expenseDataList[i][index]);
    }
    return sum;
  }

  resetReport() {
    this.newFormAdd.reset();
    this.onSubmit();
  }

  details(expense_data: any, modalSM) {
    this.modalService.open(modalSM, {
      centered: true,
      size: 'xl'
    });

    if (this.f.start_date.value !== null) {
      this.meta_start_date = this.f.start_date.value;
    }
    if (this.f.end_date.value !== null) {
      this.meta_end_date = this.f.end_date.value;
    }

    this.user_name = expense_data.username;
    this.expenseService.getmeta(expense_data.expense_category_id,this.user_id,this.meta_start_date,this.meta_end_date).subscribe(
      data => {
        this.isModelLoading = false;
        if (data.status) {
          this.expenseDetails = data.data;
        } else {
          this._toastrService.error(data.errorMessage, 'Error!');
        }
      },
      err => {
        this._toastrService.error(err.errorMessage, 'Opps!');
      }
    );
  }

  downloadExcelData() {
    const formdata = new FormData();
    formdata.append('ses_user_id', this.user_id);

    if (this.f.start_date.value !== null) {
      formdata.append('start_date', this.f.start_date.value);
    }
    if (this.f.end_date.value !== null) {
      formdata.append('end_date', this.f.end_date.value);
    }
    if (this.f.expense_category_id.value != "") {
      formdata.append('expense_category_id', this.f.expense_category_id.value);
    }
    if (this.f.user_id.value != "") {
      formdata.append('user_id', this.f.user_id.value);
    }

    this.expenseService.fileDownload(formdata).subscribe(
      (data) => {
        window.location.href = data.url;
      },
      (error) => {
        this._toastrService.error('Something went wrong! Please Try Againg...', 'Opps!', { toastClass: 'toast ngx-toastr', closeButton: true });
      }
    );
  }
}