<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <section id="home-page">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Stock Receive List</h4>
                        </div>
                        <form class="add-new modal-content pt-0" [formGroup]="newFormFilter" (ngSubmit)="onSubmit1()">
                            <div class="modal-body flex-grow-1 ">
                                <div class="row">
                                    <div class="col-lg-3">
                                        <div class="form-group">
                                            <label>Select Exporter<span class="text-danger">*</span></label>
                                            <ng-select formControlName="vendor_id" aria-describedby="register-vendor_id"
                                                [ngClass]="{ 'is-invalid': submitted && fr.vendor_id.errors }">
                                                <ng-option disabled selected value="0">Select Exporter</ng-option>
                                                <ng-option *ngFor="let expCat of vendorDropDown"
                                                    [value]="expCat.id">{{expCat.name}}</ng-option>
                                            </ng-select>
                                            <div *ngIf="submitted && fr.vendor_id.errors" class="invalid-feedback">
                                                <div *ngIf="fr.vendor_id.errors.required">Exporter is required</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3">
                                        <div class="form-group">
                                            <label class="form-label">From Date</label>
                                            <input type="date" formControlName="start_date" id="start_date"
                                                placeholder="dd-mm-yyyy" class="form-control">
                                        </div>
                                    </div>
                                    <div class="col-lg-3">
                                        <div class="form-group">
                                            <label class="form-label">To Date</label>
                                            <input type="date" formControlName="end_date" id="end_date"
                                                placeholder="dd-mm-yyyy" class="form-control">
                                        </div>
                                    </div>
                                    <div class="col-lg-3">
                                        <div class="form-group">
                                            <label class="form-label">Container Details</label>
                                            <input type="text" formControlName="vehicle_details" id="vehicle_details"
                                                placeholder="Container Details" class="form-control">
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-3">
                                        <div class="form-group">
                                            <button type="submit" class="btn btn-primary mr-1 " rippleEffect
                                                [disabled]="loading "> <span *ngIf="loading "
                                                    class="spinner-border spinner-border-sm mr-1 "></span>Go</button>
                                            <button type="reset" class="btn btn-primary mr-1 " (click)="resetReport()"
                                                rippleEffect [disabled]="loading ">
                                                <span *ngIf="loading "
                                                    class="spinner-border spinner-border-sm mr-1 "></span>
                                                <i data-feather="refresh-cw"
                                                    class="d-sm-none d-inline-block mr-0 mr-sm-1"></i>
                                                <span class="d-none d-sm-inline-block">Reset</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div class="card-body p-0 pb-1">
                            <div class="row m-0">
                                <div class="col-md-6 col-6">
                                    <div class="d-flex justify-content-between align-items-center ml-1">
                                        <label class="d-flex align-items-center">Show
                                            <select class="form-control mx-25" [(ngModel)]="lengths"
                                                (change)="loadPageLenghs($event)">
                                                <option value="15">15</option>
                                                <option value="30">30</option>
                                                <option value="50">50</option>
                                                <option value="100">100</option>
                                            </select>
                                        </label>
                                    </div>
                                </div>
                                <div class="col-md-6 col-6 d-flex justify-content-start justify-content-md-end">
                                    <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pb-md-0 w-100">
                                        <!-- <button class="btn btn-primary ml-1" rippleEffect routerLink="/purchase/add" data-toggle="tooltip" data-popup="tooltip-custom" placement="top" container="body" ngbTooltip="Add New Purchase">
                                        <i data-feather="plus" class="d-sm-none d-inline-block mr-0 mr-sm-1"></i
                                        ><span class="d-none d-sm-inline-block">Add New</span>
                                        </button> -->
                                        <!-- <button class="btn btn-warning ml-1" rippleEffect
                                            (click)="toggleSidebar('new-sidebar1')">
                                            <i data-feather="filter" class="d-sm-none d-inline-block mr-0 mr-sm-1"></i>
                                            <span class="d-none d-sm-inline-block">Filter</span>
                                        </button> -->
                                    </div>
                                </div>
                            </div>
                            <div class="col p-0 table-responsive">
                                <div class="overlay" *ngIf="progressMainTable">
                                    <div class="spinner-grow" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </div>
                                <table class="table table-striped table-hover valign-middle text-dark">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Exporter Name</th>
                                            <!-- <th>User Name</th> -->
                                            <th style="width: 110px;">Date</th>
                                            <!-- <th>Total</th> -->
                                            <th style="width: 146px;">Vehicle Details</th>
                                            <th>Remark</th>
                                            <th>Status</th>
                                            <th style="width: 130px;">Port Date</th>
                                            <th>View</th>
                                            <th>Bill Of Loading</th>
                                            <th>Telex Copy</th>
                                            <th>Other Doc.</th>
                                            <!-- <th>Action</th> -->
                                        </tr>
                                    </thead>

                                    <tbody *ngIf="stockList?.length != 0">
                                        <tr *ngFor="let ware of stockList; let ndx = index">
                                            <td>{{ndx+page+1}}</td>
                                            <td>{{ ware.vendor_name }}</td>
                                            <!-- <td>{{ ware.user_name }}</td> -->
                                            <td>{{ ware.date }}</td>
                                            <!-- <td>{{ ware.final_total }}</td> -->
                                            <td>{{ ware.vehicle_details }}</td>
                                            <td>{{ ware.remark }}</td>
                                            <td>
                                                <div ngbDropdown *ngIf="ware.status == 1">
                                                    <button ngbDropdownToggle class="btn btn-gradient-warning btn-sm"
                                                        type="button" id="dropdownMenuButton101">
                                                        In Transit
                                                    </button>
                                                    <div ngbDropdownMenu aria-labelledby="dropdownMenuButton101">
                                                        <a ngbDropdownItem (click)="orderchangeStatus(ware.id,3,'new-sidebar-date')">In Port</a>
                                                    </div>
                                                </div>
                                                <div ngbDropdown *ngIf="ware.status == 2">
                                                    <span class="badge badge-pill badge-light-primary mr-1"
                                                        *ngIf="ware.status == 2">Received</span>
                                                </div>
                                                <div ngbDropdown *ngIf="ware.status == 3">
                                                    <button ngbDropdownToggle class="btn btn-gradient-warning btn-sm"
                                                        type="button" id="dropdownMenuButton101">
                                                        In Port
                                                    </button>
                                                    <div ngbDropdownMenu aria-labelledby="dropdownMenuButton101">
                                                        <a ngbDropdownItem (click)="orderchangeStatus(ware.id,2,'new-sidebar-date')">Received</a>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>{{ ware.port_date }}
                                                <div *ngIf="ware.port_date != ''">
                                                    <a (click)="edit(ndx)" class="btn btn-icon btn-sm btn-info mr-1 mb-1" data-toggle="tooltip" data-popup="tooltip-custom" placement="top" container="body" ngbTooltip="Edit">
                                                        <i data-feather="edit-2"></i>
                                                    </a>
                                                </div>
                                            </td>
                                            <td>
                                                <button type="button" (click)="details(ware,modalSM)"
                                                    data-toggle="tooltip" data-popup="tooltip-custom" placement="top"
                                                    container="body" ngbTooltip="View"
                                                    class="btn btn-icon btn-sm btn-warning mr-1" rippleEffect>
                                                    <i data-feather="eye"></i>
                                                </button>
                                            </td>
                                            <td>
                                                <button class="btn btn-primary ml-1" rippleEffect
                                                    (click)="toggleSidebar('new-sidebar',ware.id,1)">
                                                    <i data-feather="plus"
                                                        class="d-sm-none d-inline-block mr-0 mr-sm-1"></i><span
                                                        class="d-none d-sm-inline-block">Add</span>
                                                </button>
                                                <a *ngIf="ware.bill_path != '' " [href]="ware.bill_path" target="_blank"
                                                    rel="noopener noreferrer"><button
                                                        class="btn btn-warning ml-1">view</button></a>
                                            </td>
                                            <td>
                                                <button class="btn btn-primary ml-1" rippleEffect
                                                    (click)="toggleSidebar('new-sidebar',ware.id,2)">
                                                    <i data-feather="plus"
                                                        class="d-sm-none d-inline-block mr-0 mr-sm-1"></i><span
                                                        class="d-none d-sm-inline-block">Add</span>
                                                </button>
                                                <a *ngIf="ware.telex_path != '' " [href]="ware.telex_path"
                                                    target="_blank" rel="noopener noreferrer"><button
                                                        class="btn btn-warning ml-1">view</button></a>
                                            </td>
                                            <td>
                                                <button class="btn btn-primary ml-1" rippleEffect
                                                    (click)="toggleSidebar('new-sidebar',ware.id,3)">
                                                    <i data-feather="plus"
                                                        class="d-sm-none d-inline-block mr-0 mr-sm-1"></i><span
                                                        class="d-none d-sm-inline-block">Add</span>
                                                </button>
                                                <a *ngIf="ware.other_path != '' " [href]="ware.other_path"
                                                    target="_blank" rel="noopener noreferrer"><button
                                                        class="btn btn-warning ml-1">view</button></a>
                                            </td>
                                            <!-- <td>
                                                <div ngbDropdown container="body">
                                                    <button type="button" class="btn btn-sm hide-arrow" ngbDropdownToggle data-toggle="dropdown">
                                                      <i data-feather="more-vertical"></i>
                                                    </button>
                                                    <div ngbDropdownMenu>
                                                        <a ngbDropdownItem href="javascript:void(0);" (click)="edit(ndx)"><i data-feather="edit" class="mr-50"></i><span>Edit</span></a>
                                                        <a ngbDropdownItem href="javascript:void(0);" (click)="delete(ware.id)"><i data-feather="trash" class="mr-50"></i><span>Delete</span></a>
                                                    </div>
                                                </div>
                                            </td> -->
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="stockList?.length == 0">
                                        <tr *ngIf="!loading">
                                            <td colspan="3" class="no-data-available">No Data!</td>
                                        </tr>
                                        <tr>
                                            <td colspan="3" class="no-data-available">
                                                <div class="col" *ngIf="loading">
                                                    <div class="text-center">
                                                        <div class="spinner-border" role="status">
                                                            <span class="sr-only">Loading...</span>
                                                        </div>
                                                        <div>
                                                            Please Wait...
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <ngb-pagination class="d-flex justify-content-end mt-2 mr-2" *ngIf="collectionSize > 0"
                                    [collectionSize]="collectionSize" [pageSize]="lengths" [(page)]="pageBasicText"
                                    (pageChange)="loadPage($event)" aria-label="Custom pagination">
                                    <ng-template ngbPaginationPrevious><span [data-feather]="'chevron-left'"
                                            [class]="'font-weight-bolder'"></span>Prev
                                    </ng-template>
                                    <ng-template ngbPaginationNext>Next<span [data-feather]="'chevron-right'"
                                            [class]="'font-weight-bolder'"></span>
                                    </ng-template>
                                </ngb-pagination>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- Modal -->
        <ng-template #modalSM let-modal>
            <div class="overlay" *ngIf="isModelLoading">
                <div class="spinner-border text-primary" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
            <div class="modal-header">
                <h5 class="modal-title w-100" id="myModalLabel160">
                    <span class="float-left"> <span class="badge badge-glow badge-primary">View Details -
                            <b>{{exp_name}}</b></span></span>
                </h5>
                <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body" tabindex="0" ngbAutofocus>
                <div class="row">
                    <div class="table-responsive m-t-40">
                        <table class="table table-hover">
                            <thead>
                                <tr>
                                    <th class="text-center">#</th>
                                    <th class="text-center">Product Name</th>
                                    <!-- <th class="text-center">Price</th> -->
                                    <th class="text-center">Quantity</th>
                                    <!-- <th class="text-center">Total</th> -->
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let ind of purchaseDetails; let i = index">
                                    <td class="text-center">{{ i + 1 }}</td>
                                    <td class="text-center">{{ ind.product_name}}</td>
                                    <td class="text-center">{{ ind.qty}}</td>
                                    <!-- <td class="text-center">{{ ind.price}}</td> -->
                                    <!-- <td class="text-center">{{ ind.total}}</td> -->
                                </tr>
                            </tbody>
                            <tbody *ngIf="purchaseDetails?.length == 0">
                                <tr>
                                    <td colspan="4" class="no-data-available">No data!</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </ng-template>
        <!-- / Modal -->
        
    </div>
</div>

<!-- New User Sidebar -->
<core-sidebar class="modal modal-slide-in sidebar-todo-modal fade" name="new-sidebar" overlayClass="modal-backdrop">
    <div class="slideout-content">
        <!-- Modal to add new starts-->
        <div class="modalsd modal-slide-in sdfade new-modal" id="modals-slide-in">
            <div class="modal-dialog">
                <form class="add-new modal-content pt-0" [formGroup]="newFormAdd" (ngSubmit)="onSubmit()">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                        (click)="toggleSidebar('new-sidebar')">
                        ×
                    </button>
                    <div class="modal-header mb-1">
                        <h5 class="modal-title" id="exampleModalLabel">Add File</h5>
                    </div>
                    <div class="modal-body flex-grow-1 ">
                        <div class="row m-0">
                            <div class="col">
                                <div class="form-group">
                                    <label for="register-file" class="form-label">File<span class="text-danger">*</span>
                                    </label>
                                    <input type="file" formControlName="file" class="form-control"
                                        placeholder="Select File" (change)="uploadFile($event,1)"
                                        aria-describedby="register-file"
                                        [ngClass]="{ 'is-invalid': submitted && f.file.errors }" />
                                    <div *ngIf="submitted && f.file.errors" class="invalid-feedback">
                                        <div *ngIf="f.file.errors.required">File is required</div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="col-lg-12 p-0">
                                        <ngb-alert [type]="'danger'" [dismissible]="false" *ngIf="error">
                                            <div class="alert-body">
                                                <p>{{ error }}</p>
                                            </div>
                                        </ngb-alert>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="col-lg-12 p-0">
                                        <button type="reset" class="btn btn-secondary"
                                            (click)="toggleSidebar('new-sidebar')" [disabled]="loadingFrm" rippleEffect>
                                            <span *ngIf="loadingFrm"
                                                class="spinner-border spinner-border-sm mr-1"></span>
                                            <i data-feather="x" class="d-sm-none d-inline-block mr-0 mr-sm-1"></i>
                                            <span class="d-none d-sm-inline-block">Cancel </span>
                                        </button>
                                        <button type="submit" class="btn btn-primary ml-1 " rippleEffect
                                            [disabled]="loadingFrm"> <span *ngIf="loadingFrm"
                                                class="spinner-border spinner-border-sm mr-1"></span>Submit</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <!-- Modal to add new Ends-->
    </div>
</core-sidebar>
<!--/ New User Sidebar -->

<!-- New Date Sidebar -->
<core-sidebar class="modal modal-slide-in sidebar-todo-modal fade" name="new-sidebar-date" overlayClass="modal-backdrop">
    <div class="slideout-content">
        <!-- Modal to add new starts-->
        <div class="modalsd modal-slide-in sdfade new-modal" id="modals-slide-in">
            <div class="modal-dialog">
                <form class="add-new modal-content pt-0" [formGroup]="newFormDate" (ngSubmit)="onSubmit2()">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                        (click)="toggleSidebar('new-sidebar-date')">
                        ×
                    </button>
                    <div class="modal-header mb-1">
                        <h5 class="modal-title" id="exampleModalLabel">Add Date</h5>
                    </div>
                    <div class="modal-body flex-grow-1 ">
                        <div class="row m-0">
                            <div class="col">
                                <div class="form-group">
                                    <label for="port-date" class="form-label">Port Date<span class="text-danger">*</span>
                                    </label>
                                    <input type="date" formControlName="port_date" class="form-control"
                                        placeholder="dd-mm-yyyy"
                                        aria-describedby="port-date"
                                        [ngClass]="{ 'is-invalid': submitted && fd.port_date.errors }" />
                                    <div *ngIf="submitted && fd.port_date.errors" class="invalid-feedback">
                                        <div *ngIf="fd.port_date.errors.required">Port Date is required</div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="col-lg-12 p-0">
                                        <ngb-alert [type]="'danger'" [dismissible]="false" *ngIf="error">
                                            <div class="alert-body">
                                                <p>{{ error }}</p>
                                            </div>
                                        </ngb-alert>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="col-lg-12 p-0">
                                        <button type="reset" class="btn btn-secondary"
                                            (click)="toggleSidebar('new-sidebar-date')" [disabled]="loadingFrm" rippleEffect>
                                            <span *ngIf="loadingFrm"
                                                class="spinner-border spinner-border-sm mr-1"></span>
                                            <i data-feather="x" class="d-sm-none d-inline-block mr-0 mr-sm-1"></i>
                                            <span class="d-none d-sm-inline-block">Cancel </span>
                                        </button>
                                        <button type="submit" class="btn btn-primary ml-1 " rippleEffect
                                            [disabled]="loadingFrm"> <span *ngIf="loadingFrm"
                                                class="spinner-border spinner-border-sm mr-1"></span>Submit</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <!-- Modal to add new Ends-->
    </div>
</core-sidebar>
<!--/ New Date Sidebar -->

<!-- New User Sidebar -->
<!-- <core-sidebar class="modal modal-slide-in sidebar-todo-modal fade" name="new-sidebar1" overlayClass="modal-backdrop">
    <div class="slideout-content">
        <div class="modalsd modal-slide-in sdfade new-modal" id="modals-slide-in">
            <div class="modal-dialog">
                <form class="add-new modal-content pt-0" [formGroup]="newFormFilter" (ngSubmit)="onSubmit1()">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                        (click)="toggleSidebar('new-sidebar')">
                        ×
                    </button>
                    <div class="modal-header mb-1">
                        <h5 class="modal-title" id="exampleModalLabel">Intransit Filter</h5>
                    </div>
                    <div class="modal-body flex-grow-1 ">
                        <div class="form-group">
                            <label>Select Exporter<span class="text-danger">*</span></label>
                            <ng-select formControlName="vendor_id" aria-describedby="register-vendor_id"
                                [ngClass]="{ 'is-invalid': submitted && fr.vendor_id.errors }">
                                <ng-option disabled selected value="0">Select Exporter</ng-option>
                                <ng-option *ngFor="let expCat of vendorDropDown"
                                    [value]="expCat.id">{{expCat.name}}</ng-option>
                            </ng-select>
                            <div *ngIf="submitted && fr.vendor_id.errors" class="invalid-feedback">
                                <div *ngIf="fr.vendor_id.errors.required">Exporter is required</div>
                            </div>
                        </div>
                        <div class="form-group">
                            <label class="form-label">From Date</label>
                            <input type="date" formControlName="start_date" id="start_date" placeholder="dd-mm-yyyy"
                                class="form-control">
                        </div>
                        <div class="form-group">
                            <label class="form-label">To Date</label>
                            <input type="date" formControlName="end_date" id="end_date" placeholder="dd-mm-yyyy"
                                class="form-control">
                        </div>
                        <div class="form-group">
                            <label class="form-label">Container Details</label>
                            <input type="text" formControlName="vehicle_details" id="vehicle_details"
                                placeholder="Container Details" class="form-control">
                        </div>
                        <div class="form-group">
                            <button type="submit" class="btn btn-primary mr-1 " rippleEffect [disabled]="loading ">
                                <span *ngIf="loading " class="spinner-border spinner-border-sm mr-1 "></span>Go</button>
                            <button type="reset" class="btn btn-primary mr-1 " (click)="resetReport()" rippleEffect
                                [disabled]="loading ">
                                <span *ngIf="loading " class="spinner-border spinner-border-sm mr-1 "></span>
                                <i data-feather="refresh-cw" class="d-sm-none d-inline-block mr-0 mr-sm-1"></i>
                                <span class="d-none d-sm-inline-block">Reset</span>
                            </button>
                            <a class="btn btn-secondary" (click)="toggleSidebar('new-sidebar')" rippleEffect>
                                <i data-feather="x" class="d-sm-none d-inline-block mr-0 mr-sm-1"></i>
                                <span class="d-none d-sm-inline-block">Cancel</span>
                            </a>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</core-sidebar> -->
<!--/ New User Sidebar -->