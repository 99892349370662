import { Component, OnInit, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { ToastrService } from "ngx-toastr";
import { AuthenticationService } from "app/auth/service";
import { Router, ActivatedRoute } from "@angular/router";
import { CoreSidebarService } from "@core/components/core-sidebar/core-sidebar.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbDateAdapter, NgbDateParserFormatter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';
import { StockTransferService } from 'app/_services/stock-transfer.service';
import { log } from 'console';
import { SubStockTransferService } from 'app/_services/sub-stock-transfer.service';

@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {
  readonly DELIMITER = '-';

  parse(value: string): NgbDateStruct | null {
    if (value) {
      const date = value.split(this.DELIMITER);
      return {
        day: parseInt(date[0], 10),
        month: parseInt(date[1], 10),
        year: parseInt(date[2], 10),
      };
    }
    return null;
  }

  format(date: NgbDateStruct | null): string {
    return date ? date.day + this.DELIMITER + date.month + this.DELIMITER + date.year : '';
  }
}

@Component({
  selector: 'app-sub-stock-transfer-add',
  templateUrl: './sub-stock-transfer-add.component.html',
  styleUrls: ['./sub-stock-transfer-add.component.scss'],
  providers: [
    { provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
  ],
})
export class SubStockTransferAddComponent implements OnInit {
  public newForm: FormGroup;
  public submitted = false;
  public loadingFrm: boolean = false;
  public isSubmitting: boolean = true;
  public stockId: any = 0;
  public final_total: any = 0.00;
  public error = "";
  public usertDropDown: any = [];
  public userreceiveDropDown: any = [];
  public productDropDown: any = [];
  public FormSubmit: Boolean = false;
  public loading = false;

  public today = new Date();
  public getDate = String(this.today.getDate()).padStart(2, '0');
  public getMonth = String(this.today.getMonth() + 1).padStart(2, '0'); //January is 0!
  public getYear = this.today.getFullYear();

  public maxDate = Number(this.getDate);
  public maxMonth = Number(this.getMonth);
  public maxYear = Number(this.getYear);
  public basicDPdata: NgbDateStruct = { day: this.maxDate, month: this.maxMonth, year: this.maxYear };
  public currentUser: any;
  public tempUser: any;
  public user_id: any = 0;

  constructor(
    private _router: Router,
    private route: ActivatedRoute,
    private stockTransferService: StockTransferService,
    private subStockTransferService: SubStockTransferService,
    private _authenticationService: AuthenticationService,
    private _formBuilder: FormBuilder,
    private http: HttpClient,
    private _toastrService: ToastrService,
  ) { }

  public stockTransfer: any[] = [{
    id: 1,
    product_id: '0',
    qty: '0',
    total: '0',
  }];

  ngOnInit(): void {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.tempUser = JSON.parse(localStorage.getItem('tempUser'));
    if (this.currentUser.user.id != this.tempUser) {
      this.user_id = this.tempUser;
    } else {
      this.user_id = this.currentUser.user.id;
    }
    // this.getproductDropDown(this.user_id);
    this.getuserDropDown(this.user_id);
    this.getuserrecDropDown();

    this.newForm = this._formBuilder.group({
      send_user_id: ["", Validators.required],
      receive_user_id: ["", Validators.required],
      date: ["", Validators.required],
      remark: [""]
    });
  }

  get f() {
    return this.newForm.controls;
  }

  getuserDropDown(user_id: any) {
    this.stockTransferService.getuserDropDown(user_id).subscribe(
      data => {
        if (data.status) {
          this.usertDropDown = data.user;
        }
      });
  }
  getuserrecDropDown() {
    this.stockTransferService.getuserreceiveDropDown().subscribe(
      data => {
        if (data.status) {
          this.userreceiveDropDown = data.user;
        }
      });
  }
  getproductDropDown(user_id: any) {
    this.stockTransferService.getproductDropDown(user_id).subscribe(
      data => {
        if (data.status) {
          this.productDropDown = data.product;
        }
      });
  }

  addProduct(event: any) {
    const trns_user = this.newForm.value.send_user_id;
    this.getproductDropDown(trns_user);
  }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.newForm.invalid) {
      this.newForm.markAllAsTouched();
    } else {
      this.loadingFrm = true;
      this.isSubmitting = true;
      this.error = "";
      let date = this.newForm.controls.date.value['year'] + '-' + this.newForm.controls.date.value['month'] + '-' + this.newForm.controls.date.value['day'];
      const { send_user_id, receive_user_id, remark } = this.newForm.controls;

      this.subStockTransferService.add(this.user_id, send_user_id.value, receive_user_id.value, date, remark.value, this.stockId, this.final_total, this.stockTransfer
      ).subscribe(
        (data) => {
          this.loadingFrm = false;
          if (data.status) {
            this.stockId = 0;
            this.submitted = false;
            this.newForm.reset();
            this._toastrService.success(data.message, "Success!", {
              toastClass: "toast ngx-toastr",
              closeButton: true,
            });
            this._router.navigate(['/substocktransfer']);
          } else {
            this.error = data.message;
          }
        },
        (err) => {
          if (err.error.error == "Unauthenticated.") {
            this._authenticationService.logout();
            this._router.navigate(["/login"]);
          }
        }
      );
    }
  }

  addMore() {
    this.stockTransfer.push({
      id: this.stockTransfer.length + 1,
      product_id: '0',
      qty: '0',
      total: '0',
    });
  }

  remove(i: number) {
    const me = this;
    // remove code
    me.stockTransfer.splice(i, 1);
    let temp_total = 0;
    for (let i = 0; i < me.stockTransfer.length; i++) {
      me.stockTransfer[i].total = me.stockTransfer[i].qty;
      temp_total += parseFloat(me.stockTransfer[i].total);
    }
    me.final_total = temp_total.toFixed(2);
    // remove code
  }

  addTotal(event: any) {
    const trns_user = this.newForm.value.send_user_id;

    let temp_total = 0;
    for (let i = 0; i < this.stockTransfer.length; i++) {
      this.stockTransferService.stockcheck(this.stockTransfer[i].product_id, this.stockTransfer[i].qty, trns_user).subscribe(
        (response) => {
          this.loading = false;
          if (response.status) {
            this.stockTransfer[i].total = this.stockTransfer[i].qty;
            temp_total += parseFloat(this.stockTransfer[i].total);
            this.final_total = temp_total.toFixed(2);
            this.isSubmitting = false;
          } else {
            this._toastrService.error(response.message, "Error!", {
              toastClass: "toast ngx-toastr",
              closeButton: true,
            });
            this.isSubmitting = true;
          }
        },
      );
    }
  }
}
