import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import Swal from "sweetalert2";
import { ToastrService } from "ngx-toastr";
import { AuthenticationService } from "app/auth/service";
import { Router, ActivatedRoute } from "@angular/router";
import { CoreSidebarService } from "@core/components/core-sidebar/core-sidebar.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PaymentService } from 'app/_services/payment.service';
@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {
  public paymentList: any = [];
  public paymentListtotal: any = [];
  public start: any = 0;
  public page: any = 0;

  public sidebarToggleRef = false;
  public contentHeader: object;
  public pageBasicText = 1;
  public collectionSize = 0;
  public lengths = 15;
  public loading = false;
  public progressMainTable: boolean = false;
  public newFormAdd: FormGroup;
  public newFormfilter: FormGroup;
  public newForm: FormGroup;
  public submitted = false;
  public isAddMode: boolean = true;
  public loadingFrm: boolean = false;
  public paymentId: any = 0;
  public error = "";
  public file: any = '';
  public loadingReport: boolean = false;
  public userDropDown: any = [];
  public vendorDropDown: any = [];

  constructor(
    private _router: Router,
    private route: ActivatedRoute,
    private paymentService: PaymentService,
    private _authenticationService: AuthenticationService,
    private _formBuilder: FormBuilder,
    private _toastrService: ToastrService,
    private http: HttpClient,
    private modalService: NgbModal,
    private _coreSidebarService: CoreSidebarService
  ) { }

  ngOnInit(): void {
    this.loading = true;

    this.paymentsList(this.start, this.lengths);
    this.getuserDropDown();
    this.getvendorDropDown();

    this.newFormAdd = this._formBuilder.group({
      user_id: ["", Validators.required],
      vendor_id: [""],
      local_currency: ["", Validators.required],
      date: ["", Validators.required],
      foreign_currency: [""],
      remark: [""],
    });
    
    this.newFormfilter = this._formBuilder.group({
      user_id: [null],
      vendor_id: [null],
      start_date: [''],
      end_date: ['']
    });

    this.newForm = this._formBuilder.group({
      file: ["", Validators.required]
    });
  }
  get f() {
    return this.newFormAdd.controls;
  }
  get fl() {
    return this.newFormfilter.controls;
  }
  get fr() {
    return this.newForm.controls;
  }
  getuserDropDown() {
    this.paymentService.getuserDropDown().subscribe(
      data => {
        if (data.status) {
          this.userDropDown = data.user;
        }
      });
  }
  getvendorDropDown() {
    this.paymentService.getvendorDropDown().subscribe(
      data => {
        if (data.status) {
          this.vendorDropDown = data.vendor;
        }
      });
  }
  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.newFormAdd.invalid) {
      this.newFormAdd.markAllAsTouched();
    } else {
      this.loadingFrm = true;
      this.error = "";
      const { user_id,vendor_id,date,local_currency,foreign_currency,remark } = this.newFormAdd.controls;
      
      this.paymentService.add(user_id.value,vendor_id.value,date.value,local_currency.value,foreign_currency.value,remark.value,this.paymentId
      ).subscribe(
        (data) => {
          this.loadingFrm = false;
          if (data.status) {
            this.paymentId = 0;
            this.submitted = false;
            this.newFormAdd.reset();
            this.toggleSidebar("new-sidebar");
            this._toastrService.success(data.message, "Success!", {
              toastClass: "toast ngx-toastr",
              closeButton: true,
            });
            this.reloadList();
          } else {
            this.error = data.message;
          }
        },
        (err) => {
          if (err.error.error == "Unauthenticated.") {
            this._authenticationService.logout();
            this._router.navigate(["/login"]);
          }
        }
      );
    }
  }
  onSubmitFilter() {
    this.submitted = true;
    this.loading = true;
    this.paymentList = [];
    this.paymentListtotal = [];
    // stop here if form is invalid
    if (this.newFormfilter.invalid) {
      console.log('error');

    }
    else {
      this.page = 0;
      this.pageBasicText = 1;

      const formdata = new FormData();
      formdata.append('start', "0");
      formdata.append('length', "15");

      if (this.fl.start_date.value !== null) {
        formdata.append('start_date', this.fl.start_date.value);
      }
      if (this.fl.end_date.value !== null) {
        formdata.append('end_date', this.fl.end_date.value);
      }
      if (this.fl.user_id.value !== null) {
        formdata.append('user_id', this.fl.user_id.value);
      }
      if (this.fl.vendor_id.value !== null) {
        formdata.append('vendor_id', this.fl.vendor_id.value);
      }

      this.paymentService.getData(formdata).subscribe(
        data => {
          this.loading = false;
          if (data.status) {
            this.paymentList = data.data;
            this.paymentListtotal = data.total;
            this.collectionSize = data.recordsTotal;
          }
        },
        err => {
          if (err.error.error == 'Unauthenticated.') {
            this._authenticationService.logout();
            this._router.navigate(['/login']);
          }
        }
      );
    }
  }

  resetReport() {
    this.newFormfilter.reset();
    this.onSubmitFilter();
  }

  uploadFile(event: any, type: any) {
    if (type == 1) {
      this.file = event.target.files[0];
    }
  }

  toggleSidebar(name, type: any = 0): void {
    if (type == 0) {
      this.paymentId = 0;
    }
    this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
  }
  toggleSidebarfile(name, id: number = 0, index: any = null): void {
    this.paymentId = id;
    // this.type = index;
    this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
  }

  onSubmitfile() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.newForm.invalid) {
      console.log('error');
    }
    else {
      this.loadingFrm = true;
      const formdata = new FormData();
      formdata.append('file', this.file);
      formdata.append('id', this.paymentId);
      // formdata.append('type', this.type);
     
      this.paymentService.addfile(formdata).subscribe(
        data => {
          this.loadingFrm = false;
          if (data.status) {
            this.submitted = false;
            this.toggleSidebarfile('new-sidebar-doc');
            this.newForm.reset();
            this._toastrService.clear();
            this._toastrService.success(data.message, 'Success!', { toastClass: 'toast ngx-toastr', closeButton: true });
            this.progressMainTable = true;
            this.reloadList();
          }
          else {
            this.error = data.error;
          }
        },
        err => {
          if (err.error.error == 'Unauthenticated.') {
            this._authenticationService.logout();
            this._router.navigate(['/login']);
          }
        });
    }
  }
  paymentsList(my_start: any, my_lengths: any) {
    const formdata = new FormData();
    formdata.append('start', my_start);
    formdata.append('length', my_lengths);

    this.paymentService.getData(formdata).subscribe(
      (response) => {
        this.loading = false;
        this.progressMainTable = false;
        if (response.status) {
          this.paymentList = response.data;
          this.paymentListtotal = response.total;
          this.collectionSize = response.recordsTotal;
        }
      },
      (err) => {
        if (err.error.error == "Unauthenticated.") {
          this._authenticationService.logout();
          this._router.navigate(["/login"]);
        }
      }
    );
  }

  reloadList() {
    this.progressMainTable = true;
    this.paymentsList(this.page, this.lengths);
  }

  loadPage(event: any) {
    this.progressMainTable = true;
    this.page = this.lengths * (event - 1);
    this.paymentsList(this.page, this.lengths);
  }
  loadPageLenghs(event: any) {
    this.progressMainTable = true;
    this.lengths = event.target.value;
    this.paymentsList(this.start, this.lengths);
  }

  edit(index: any) {
    this.paymentId = this.paymentList[index].id;
    this.f.user_id.setValue(this.paymentList[index].user_id);
    this.f.vendor_id.setValue(this.paymentList[index].vendor_id);
    this.f.date.setValue(this.paymentList[index].edate);
    this.f.local_currency.setValue(this.paymentList[index].local_currency);
    this.f.foreign_currency.setValue(this.paymentList[index].foreign_currency);
    this.f.remark.setValue(this.paymentList[index].remark);

    this.toggleSidebar("new-sidebar", 1);
  }
}
