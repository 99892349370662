import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { ToastrService } from 'ngx-toastr';
@Injectable({
  providedIn: 'root'
})
export class ExpenseService {
  
  constructor(private _http: HttpClient, private _toastrService: ToastrService) { }

  getData(start, length,user_id): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}get-expense`, { start, length,user_id })
      .pipe(
        map(data => {
          return data;
        })
      );
  }
  
  add(expense_category_id:any,amount:any,date:any,remark:any, id: any,user_id:any): Observable<any> {
    if (id == 0) {
      return this._http
        .post(`${environment.apiUrl}save-expense`, { expense_category_id, amount, date, remark,user_id})
        .pipe(
          map(data => {
            return data;
          })
        );
    }
    else {
      return this._http
        .post(`${environment.apiUrl}update-expense`, { expense_category_id, amount, date, remark,id,user_id })
        .pipe(
          map(data => {
            return data;
          })
        );
    }
  }

  delete(id): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}delete-expense`, { id })
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  getcategoryDropDown(user_id): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}expensecategory-dropdown`, {user_id })
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  getuserDropDown(): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}user-dropdown`, { })
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  getAllData(formdata: any): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}expense-report`,formdata)
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  getmeta(id: string,ses_user_id:any,start_date:any,end_date:any): Observable<any> {
    return this._http.post(`${environment.apiUrl}expense-reportmeta`, {id,ses_user_id,start_date,end_date});
  }

  fileDownload(formdata: any): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}export-report`,formdata)
      .pipe(
        map(data => {
          return data;
        })
      );
  }
}
