export const locale = {
  lang: 'en',
  data: {
    MENU: {
      HOME: 'Home',
      MASTER: 'Master',
      PRODUCT: 'Product',
      VENDOR: 'Exporter',
      CUSTOMER: 'Customer',
      PURCHASE: 'Stock Transfer',
      MANAGESTOCK: 'Manage Stock',
      STOCK: 'Stock',
      PURCHASEPAYMENT: 'Purchase Payment',
      USER: 'User',
      SALES: 'Sales',
      SALESPAYMENT: 'Sales Payment',
      STOCKRECEIVE: 'Stock Receive',
      STOCKTRANSFER: 'Stock Receive',
      RECEIVE: 'Receive',
      EXPENSE: 'Manage Expense',
      EXPENSECATEGORY: 'Manage Expense',
      EXPENSEREPORT: 'Expense Report',
      OUTSTANDINGREPORT: 'Outstanding Report',
      REPORT: 'Report',
      SALESREPORT: 'Sales Report',
      PURCHASEPAYMENTREPORT: 'Purchase Payment Report',
      INTRANSIT: 'In transit',
      CASHENDREPORT: 'Cashend Report',
      CURRENCY: 'Currency',
      PAYMENT: 'Payment',
      SUBSTOCKTRANSFER: 'Sub Stock Transfer',
      PORT: 'In Port',
      SALESPRODUCT: 'Sales Product',
      ORDER: 'Order',
      SAMPLE: 'Sample'
    }
  }
}
