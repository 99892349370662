import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import Swal from "sweetalert2";
import { ToastrService } from "ngx-toastr";
import { AuthenticationService } from "app/auth/service";
import { Router, ActivatedRoute } from "@angular/router";
import { CoreSidebarService } from "@core/components/core-sidebar/core-sidebar.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { VendorService } from 'app/_services/vendor.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PurchasePaymentReportService } from 'app/_services/purchase-payment-report.service';

@Component({
  selector: 'app-vendor',
  templateUrl: './vendor.component.html',
  styleUrls: ['./vendor.component.scss']
})
export class VendorComponent implements OnInit {
  public vendorList: any = [];
  public start: any = 0;
  public page: any = 0;

  public sidebarToggleRef = false;
  public contentHeader: object;
  public pageBasicText = 1;
  public collectionSize = 0;
  public lengths = 30;
  public loading = false;
  public progressMainTable: boolean = false;
  public newFormAdd: FormGroup;
  public submitted = false;
  public isAddMode: boolean = true;
  public loadingFrm: boolean = false;
  public vendorId = 0;
  public error = "";
  public loadingReport: boolean = false;
  public vendorDetails: any = '';
  public vendorOutStanding: any = [];
  public vendorOpeningBalance: any;

  constructor(
    private _router: Router,
    private route: ActivatedRoute,
    private VendorService: VendorService,
    private purchasePaymentReportService: PurchasePaymentReportService,
    private _authenticationService: AuthenticationService,
    private _formBuilder: FormBuilder,
    private _toastrService: ToastrService,
    private http: HttpClient,
    private modalService: NgbModal,
    private _coreSidebarService: CoreSidebarService
  ) { }

  ngOnInit(): void {
    this.vendorsList(this.start, this.lengths);
    this.newFormAdd = this._formBuilder.group({
      name: ["", Validators.required],
      mobile: ["", Validators.required],
      email: [""],
      address: [""],
    });
  }

  get f() {
    return this.newFormAdd.controls;
  }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.newFormAdd.invalid) {
      this.newFormAdd.markAllAsTouched();
    } else {
      this.loadingFrm = true;
      this.error = "";
      const { name,mobile,email,address } = this.newFormAdd.controls;
      
      this.VendorService.add(
        name.value,
        mobile.value,
        email.value,
        address.value,
        this.vendorId
      ).subscribe(
        (data) => {
          this.loadingFrm = false;
          if (data.status) {
            this.vendorId = 0;
            this.submitted = false;
            this.newFormAdd.reset();
            this.toggleSidebar("new-sidebar");
            this._toastrService.success(data.message, "Success!", {
              toastClass: "toast ngx-toastr",
              closeButton: true,
            });
            this.reloadList();
          } else {
            this.error = data.message;
          }
        },
        (err) => {
          if (err.error.error == "Unauthenticated.") {
            this._authenticationService.logout();
            this._router.navigate(["/login"]);
          }
        }
      );
    }
  }

  toggleSidebar(name, type: any = 0): void {
    if (type == 0) {
      this.vendorId = 0;
    }
    this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
  }

  vendorsList(my_start: any, my_lengths: any) {
    this.VendorService.getData(my_start, my_lengths).subscribe(
      (response) => {
        this.loading = false;
        this.progressMainTable = false;
        if (response.status) {
          this.vendorList = response.data;
          this.collectionSize = response.recordsTotal;
        }
      },
      (err) => {
        if (err.error.error == "Unauthenticated.") {
          this._authenticationService.logout();
          this._router.navigate(["/login"]);
        }
      }
    );
  }

  reloadList() {
    this.progressMainTable = true;
    this.vendorsList(this.page, this.lengths);
  }

  loadPage(event: any) {
    this.progressMainTable = true;
    this.page = this.lengths * (event - 1);
    this.vendorsList(this.page, this.lengths);
  }
  loadPageLenghs(event: any) {
    this.progressMainTable = true;
    this.lengths = event.target.value;
    this.vendorsList(this.start, this.lengths);
  }

  edit(index: any) {
    this.vendorId = this.vendorList[index].id;
    this.f.name.setValue(this.vendorList[index].name);
    this.f.mobile.setValue(this.vendorList[index].mobile);
    this.f.email.setValue(this.vendorList[index].email);
    this.f.address.setValue(this.vendorList[index].address);

    this.toggleSidebar("new-sidebar", 1);
  }

  delete(id: any) {
    const me = this;
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#7367F0",
      cancelButtonColor: "#E42728",
      confirmButtonText: "Yes, delete it!",
      customClass: {
        confirmButton: "btn btn-primary",
        cancelButton: "btn btn-danger ml-1",
      },
    }).then(function (result) {
      if (result.value) {
        me.VendorService.delete(id).subscribe(
          (response) => {
            if (response.status) {
              me.progressMainTable = true;
              me.reloadList();
              Swal.fire({
                icon: "success",
                title: "Deleted!",
                text: "Vendor Deleted Successfully",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
            }
          },
          (err) => {
            if (err.error.error == "Unauthenticated.") {
              this._authenticationService.logout();
              this._router.navigate(["/login"]);
            }
          }
        );
      }
    });
  }

  details(id: any,modalSM) {
    if (id > 0) {
      this.loadingReport = true;
      this.vendorOpeningBalance = '';
      this.vendorOutStanding = [];

      this.modalService.open(modalSM, {
        centered: true,
        size: 'xl'
      });
      
      this.purchasePaymentReportService.loadReport(id).subscribe(
        data => {
          this.loadingReport = false;
          if (data.status) {
            let total = 0;
            if (data.opening_balance.balance_type == 'D') {
              total = -data.opening_balance.balance;
            }
            else {
              total = data.opening_balance.balance;
            }
            data.data.forEach((value: any, index: any) => {
              if (value.payment_type == "C") {
                total += parseFloat(value.amount);
              }
              else {
                total -= parseFloat(value.amount);
              }
              data.data[index].closing = total;
            });
            this.vendorOpeningBalance = data.opening_balance;
            this.vendorOutStanding = data.data;
          }
        },
        err => {
          if (err.error.error == 'Unauthenticated.') {
            this._authenticationService.logout();
            this._router.navigate(['/login']);
          }
          this._toastrService.clear();
          this._toastrService.error(err.errorMessage, 'Opps!', { toastClass: 'toast ngx-toastr', closeButton: true });
          this.vendorOpeningBalance = '';
          this.vendorOutStanding = [];
          this.loadingReport = false;
        }
      );
    }
  }

  downloadExcelData() {
    this.VendorService.fileDownload().subscribe(
      (data) => {
        window.location.href=data.url;
      },
      (error) => {
        this._toastrService.error('Something went wrong! Please Try Againg...', 'Opps!', { toastClass: 'toast ngx-toastr', closeButton: true });
      }
    );
  }
}
