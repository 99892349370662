<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <section id="home-page">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Add Stock Transfer </h4>
                        </div>
                        <div class="card-body pb-1">
                            <form name="form" [formGroup]="newForm" autocomplete="off">
                                <div class="row">
                                    <div class="col-lg-3 pt-1">
                                        <div class="form-group">
                                            <label>Select User<span class="text-danger">*</span></label>
                                            <ng-select formControlName="transfer_user_id" placeholder="Select User" aria-describedby="register-transfer_user_id" [ngClass]="{ 'is-invalid': submitted && f.transfer_user_id.errors }">
                                                <ng-option disabled selected value="0">Select User</ng-option>
                                                <ng-option *ngFor="let ven of usertDropDown" [value]="ven.id">{{ven.name}}</ng-option>
                                            </ng-select>
                                            <div *ngIf="submitted && f.transfer_user_id.errors" class="invalid-feedback">
                                                <div *ngIf="f.transfer_user_id.errors.required">User is required</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 pt-1">
                                        <div class="form-group">
                                            <label for="date" class="form-label">Date </label> <span class="required" aria-required="true"> * </span>
                                            <div class="input-group"  >
                                              <input class="form-control" placeholder="dd-mm-yyyy" formControlName="date" id="date" [(ngModel)]="basicDPdata"
                                                ngbDatepicker #basicDP="ngbDatepicker" [ngClass]="{ 'is-invalid': submitted && f.date.errors }" [maxDate]="{year: maxYear, month: maxMonth, day: maxDate}">
                                              <div class="input-group-append">
                                                <button class="btn btn-outline-secondary feather icon-calendar" (click)="basicDP.toggle()"
                                                  type="button" rippleEffect></button>
                                              </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-6 pt-1">
                                        <div class="form-group">
                                            <label for="register-remark" class="form-label">Remark</label>
                                            <input type="text" formControlName="remark" class="form-control"
                                            placeholder="Remark" aria-describedby="register-remark" 
                                            [ngClass]="{ 'is-invalid': submitted && f.remark.errors }" />
                                        </div>
                                    </div>
                                    
                                </div>
                            </form>
                            <div class="row" *ngFor="let pue_product of stockTransfer; let i = index;">
                                <div class="form-group col-lg-4">
                                    <label>Product</label>
                                    <ng-select name="product_id_{{pue_product.id}}" [(ngModel)]="pue_product.product_id">
                                        <ng-option disabled selected value="0">Select Product</ng-option>
                                        <ng-option *ngFor="let pro of productDropDown" [value]="pro.id">{{pro.product_name}}</ng-option>
                                    </ng-select>
                                </div>
                                <div class="form-group col-lg-3">
                                    <label>Qty<span class="text-danger">*</span></label>
                                    <input type="text" class="form-control" [(ngModel)]="pue_product.qty" name="qty_{{pue_product.id}}" (change)="addTotal($event)" placeholder="Qty" />
                                </div>
                                <div class="form-group col-lg-3">
                                    <label>Total<span class="text-danger">*</span></label>
                                    <input type="text" class="form-control"[(ngModel)]="pue_product.total" name="total_{{pue_product.id}}" placeholder="Total" />
                                </div>
                                <div class="form-group  col-lg-1">
                                    <br/>
                                    <button type="button" class="btn btn-danger" (click)="remove(i)">
                                        <i  data-feather="trash"></i>
                                    </button>
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group offset-lg-7 col-lg-3">
                                    <input type="text" class="form-control" name="final_total" placeholder="Final Total" [(ngModel)]="final_total" />
                                </div>
                            </div>
                            <button type="button" class="btn btn-outline-success mb-2" (click)="addMore()"><i  data-feather="plus"></i> Add More</button>
                            <div class="row">
                                <div class="col col-sm-6 col-xs-6 col-lg-2 pt-1">
                                    <button [disabled]="loading" type="button" routerLink="/stocktransfer"
                                        class="btn btn-secondary btn-block" rippleEffect>
                                        <span *ngIf="loading"
                                            class="spinner-border spinner-border-sm mr-1"></span>Cancel
                                    </button>
                                </div>
                                <div class="col col-sm-6 col-xs-6 offset-lg-8 col-lg-2 pt-1">
                                    <button [disabled]="isSubmitting" type="button" (click)="onSubmit()"
                                        class="btn btn-primary btn-block" rippleEffect>
                                        <span *ngIf="loading" class="spinner-border spinner-border-sm mr-1"></span>Save
                                    </button>
                                </div>
                                <div class="clearfix"></div>
                                <div class="col col-sm-12 col-xs-12 col-lg-8">
                                    <ngb-alert [type]="'danger'" [dismissible]="false" *ngIf="error">
                                        <div class="alert-body">
                                            <p>{{ error }}</p>
                                        </div>
                                    </ngb-alert>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>