import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { ToastrService } from "ngx-toastr";
import { AuthenticationService } from "app/auth/service";
import { Router, ActivatedRoute } from "@angular/router";
import { CoreSidebarService } from "@core/components/core-sidebar/core-sidebar.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { SalesReportService } from 'app/_services/sales-report.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SalesService } from 'app/_services/sales.service';

@Component({

  selector: 'app-sales-report',
  templateUrl: './sales-report.component.html',
  styleUrls: ['./sales-report.component.scss']
})
export class SalesReportComponent implements OnInit {

  public salesDataList: any = [];
  public salesDataListtotal: any = [];
  public start: any = 0;
  public page: any = 0;

  public sidebarToggleRef = false;
  public contentHeader: object;
  public pageBasicText = 1;
  public collectionSize = 0;
  public lengths = 15;
  public loading = false;
  public progressMainTable: boolean = false;
  public newFormAdd: FormGroup;
  public submitted = false;
  public isAddMode: boolean = true;
  public loadingFrm: boolean = false;
  public categoryId = 0;
  public error = "";
  public userDropDown: any = [];
  public currentUser: any;
  public isModelLoading: Boolean = true;
  public salesDetails: any;

  constructor(
    private _router: Router,
    private route: ActivatedRoute,
    private salesReportService: SalesReportService,
    private salesService: SalesService,
    private _authenticationService: AuthenticationService,
    private _formBuilder: FormBuilder,
    private _toastrService: ToastrService,
    private http: HttpClient,
    private modalService: NgbModal,
    private _coreSidebarService: CoreSidebarService
  ) { }

  ngOnInit(): void {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));

    this.loading = true;
    this.salesList(this.start, this.lengths);
    this.getuserDropDown();
    this.newFormAdd = this._formBuilder.group({
      start_date: [''],
      end_date: [''],
      user_id: [null],
    });
  }

  get f() {
    return this.newFormAdd.controls;
  }

  salesList(my_start: any, my_lengths: any) {
    const formdata = new FormData();
    formdata.append('start', my_start);
    formdata.append('length', my_lengths);
    // if (type != 1) {
    //   if (this.f.start_date.value !== null) {
    //     formdata.append('start_date', this.f.start_date.value);
    //   }
    //   if (this.f.end_date.value !== null) {
    //     formdata.append('end_date', this.f.end_date.value);
    //   }

    //   if (this.f.user_id.value != "") {
    //     formdata.append('user_id', this.f.user_id.value);
    //   }
    // }
    this.salesReportService.getAllData(formdata).subscribe(
      data => {
        this.loading = false;
        if (data.status) {
          this.salesDataList = data.data;
          this.salesDataListtotal = data.total;
          this.collectionSize = data.recordsTotal;
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      }
    );
  }

  salesListNew(my_start: any, my_lengths: any, type: any = 0) {
    const formdata = new FormData();
    if (type != 1) {
      formdata.append('start', my_start);
    }
    else {
      formdata.append('start', '0');
      this.page = 0;
      this.pageBasicText = 1;
    }
    formdata.append('length', my_lengths);
    if (type != 1) {
      if (this.f.start_date.value !== null) {
        formdata.append('start_date', this.f.start_date.value);
      }
      if (this.f.end_date.value !== null) {
        formdata.append('end_date', this.f.end_date.value);
      }

      if (this.f.user_id.value != "") {
        formdata.append('user_id', this.f.user_id.value);
      }
    }
    this.salesReportService.getAllData(formdata).subscribe(
      data => {
        this.loading = false;
        this.progressMainTable = false;
        if (data.status) {
          this.salesDataList = data.data;
          this.salesDataListtotal = data.total;
          this.collectionSize = data.recordsTotal;
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
      }
    );
  }
  reloadList(type: any = 0) {
    this.progressMainTable = true;
    this.salesListNew(this.page, this.lengths, type);
  }
  loadPage(event: any) {
    this.progressMainTable = true;
    this.page = this.lengths * (event - 1);
    this.salesListNew(this.page, this.lengths);
  }
  loadPageLenghs(event: any) {
    this.progressMainTable = true;
    this.lengths = event.target.value;
    this.salesListNew(this.start, this.lengths);
  }

  getuserDropDown() {
    this.salesReportService.getuserDropDown().subscribe(
      data => {
        if (data.status) {
          this.userDropDown = data.user;
        }
      });
  }

  onSubmit() {
    this.submitted = true;
    this.loading = true;
    // this.toggleSidebar('new-sidebar');
    this.salesDataList = [];
    // stop here if form is invalid
    if (this.newFormAdd.invalid) {

    }
    else {
      this.page = 0;
      this.pageBasicText = 1;

      const formdata = new FormData();
      formdata.append('start', "0");
      formdata.append('length', "15");

      if (this.f.start_date.value !== null) {
        formdata.append('start_date', this.f.start_date.value);
      }
      if (this.f.end_date.value !== null) {
        formdata.append('end_date', this.f.end_date.value);
      }
      if (this.f.user_id.value != "") {
        formdata.append('user_id', this.f.user_id.value);
      }

      this.salesReportService.getAllData(formdata).subscribe(
        data => {
          this.loading = false;
          if (data.status) {
            this.salesDataList = data.data;
            this.salesDataListtotal = data.total;
            this.collectionSize = data.recordsTotal;
          }
        },
        err => {
          if (err.error.error == 'Unauthenticated.') {
            this._authenticationService.logout();
            this._router.navigate(['/login']);
          }
        }
      );
    }
  }

  toggleSidebar(name): void {
    this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
  }

  getSum(index: any): number {
    let sum = 0;
    for (let i = 0; i < this.salesDataList.length; i++) {
      sum += parseFloat(this.salesDataList[i][index]);
    }
    return sum;
  }
  getSumDollar(index: any): number {
    let sum = 0;
    for (let i = 0; i < this.salesDataList.length; i++) {
      sum += parseFloat(this.salesDataList[i][index]);
    }
    return sum;
  }

  details(id: any, modalSM) {
    this.modalService.open(modalSM, {
      centered: true,
      size: 'xl'
    });
    console.log(id);

    this.salesService.getmeta(id).subscribe(
      data => {
        this.isModelLoading = false;
        if (data.status) {
          this.salesDetails = data.data;
        } else {
          this._toastrService.error(data.errorMessage, 'Error!');
        }
      },
      err => {
        this._toastrService.error(err.errorMessage, 'Opps!');
      }
    );
  }

  resetReport() {
    this.newFormAdd.reset();
    this.onSubmit();
  }

  downloadExcelData() {
    const formdata = new FormData();

    if (this.f.start_date.value !== null) {
      formdata.append('start_date', this.f.start_date.value);
    }
    if (this.f.end_date.value !== null) {
      formdata.append('end_date', this.f.end_date.value);
    }
    if (this.f.user_id.value != "") {
      formdata.append('user_id', this.f.user_id.value);
    }

    this.salesReportService.fileDownload(formdata).subscribe(
      (data) => {
        window.location.href = data.url;
      },
      (error) => {
        this._toastrService.error('Something went wrong! Please Try Againg...', 'Opps!', { toastClass: 'toast ngx-toastr', closeButton: true });
      }
    );
  }
}
