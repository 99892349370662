import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StockService {

  constructor(private _http: HttpClient) { }

  getData(start, length,user_id): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}get-purchasestock`, { start, length,user_id })
      .pipe(
        map(data => {
          return data;
        })
      );
  }
  getadminData(start, length): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}get-adminview`, { start, length })
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  getmeta(id: string): Observable<any> {
    return this._http.post(`${environment.apiUrl}get-adminviewmeta`, {id});
  }

  getproductHistory(id: string,user_id: string): Observable<any> {
    return this._http.post(`${environment.apiUrl}get-history`, {id,user_id});
  }

  fileDownload(user_id: string): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}export-purchasestock`, {user_id})
      .pipe(
        map(data => {
          return data;
        })
      );
  }
}
