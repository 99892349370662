import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private _http: HttpClient, private _toastrService: ToastrService) { }

  getData(start, length): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}get-user`, { start, length })
      .pipe(
        map(data => {
          return data;
        })
      );
  }
  add(name:any,mobile_number:any,email:any,password:any, id: any): Observable<any> {
    if (id == 0) {
      return this._http
        .post(`${environment.apiUrl}save-user`, { name, mobile_number, email, password})
        .pipe(
          map(data => {
            return data;
          })
        );
    }
    else {
      return this._http
        .post(`${environment.apiUrl}update-user`, { name, mobile_number, email, password,id })
        .pipe(
          map(data => {
            return data;
          })
        );
    }
  }

  delete(id): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}delete-user`, { id })
      .pipe(
        map(data => {
          return data;
        })
      );
  }
  // fileDownload(): Observable<Blob> {
  //   return this._http
  //     .get<Blob>(`${environment.apiUrl}export-users`, {
  //        responseType:
  //         'blob' as 'json'
  //     })
  //     .pipe(
  //       map(data => {
  //         return data;
  //       })
  //     );
  // } 

  fileDownload(): Observable<any> {
    return this._http
      .get(`${environment.apiUrl}export-users`)
      .pipe(
        map(data => {
          return data;
        })
      );
  }
}
