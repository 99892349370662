import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class PurchasepaymentService {

  constructor(private _http: HttpClient, private _toastrService: ToastrService) { }

  getData(start, length): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}get-purchasepayment`, { start, length })
      .pipe(
        map(data => {
          return data;
        })
      );
  }
  
  add(vendor_id:any,amount:any,date:any,remark:any, id: any): Observable<any> {
    if (id == 0) {
      return this._http
        .post(`${environment.apiUrl}save-purchasepayment`, { vendor_id, amount, date, remark})
        .pipe(
          map(data => {
            return data;
          })
        );
    }
    else {
      return this._http
        .post(`${environment.apiUrl}update-purchasepayment`, { vendor_id, amount, date, remark,id })
        .pipe(
          map(data => {
            return data;
          })
        );
    }
  }

  delete(id): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}delete-purchasepayment`, { id })
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  getvendorDropDown(): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}vendor-dropdown`, { })
      .pipe(
        map(data => {
          return data;
        })
      );
  }

}
