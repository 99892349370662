<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <section id="home-page">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title" *ngIf="isAddMode">Add Sales</h4>
                            <h4 class="card-title" *ngIf="!isAddMode">Edit Sales</h4>
                        </div>
                        <div class="card-body pb-1">
                            <form name="form" [formGroup]="newForm" autocomplete="off">
                                <div class="row">
                                    <div class="col-lg-3 pt-1">
                                        <div class="form-group">
                                            <label>Select Customer<span class="text-danger">*</span></label>
                                            <ng-select formControlName="customer_id" placeholder="Select Customer" aria-describedby="register-customer_id" [ngClass]="{ 'is-invalid': submitted && f.customer_id.errors }">
                                                <ng-option disabled selected value="0">Select Customer</ng-option>
                                                <ng-option *ngFor="let ven of customerDropDown" [value]="ven.id">{{ven.name}}</ng-option>
                                            </ng-select>
                                            <div *ngIf="submitted && f.customer_id.errors" class="invalid-feedback">
                                                <div *ngIf="f.customer_id.errors.required">Customer is required</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 pt-1">
                                        <div class="form-group">
                                            <label>Select Type<span class="text-danger">*</span></label>
                                            <ng-select formControlName="type_id" placeholder="Select Type" aria-describedby="register-type_id" [ngClass]="{ 'is-invalid': submitted && f.type_id.errors }">
                                                <ng-option disabled selected value="0">Select Type</ng-option>
                                                <ng-option [value]="1">Cash</ng-option>
                                                <ng-option [value]="2">Credit</ng-option>
                                            </ng-select>
                                            <div *ngIf="submitted && f.type_id.errors" class="invalid-feedback">
                                                <div *ngIf="f.type_id.errors.required">Type is required</div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 pt-1">
                                        <div class="form-group">
                                            <label for="date" class="form-label">Date </label> <span class="required" aria-required="true"> * </span>
                                            <div class="input-group"  >
                                            <!-- [(ngModel)]="basicDPdata" -->
                                              <input class="form-control" placeholder="dd-mm-yyyy" formControlName="date" id="date" 
                                                ngbDatepicker #basicDP="ngbDatepicker" [ngClass]="{ 'is-invalid': submitted && f.date.errors }" [maxDate]="{year: maxYear, month: maxMonth, day: maxDate}">
                                              <div class="input-group-append">
                                                <button class="btn btn-outline-secondary feather icon-calendar" (click)="basicDP.toggle()"
                                                  type="button" rippleEffect></button>
                                              </div>
                                              <div *ngIf="submitted && f.date.errors" class="invalid-feedback">
                                                <div *ngIf="f.date.errors.required">Date is required</div>
                                            </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 pt-1">
                                        <div class="form-group">
                                            <label for="register-invoice_no" class="form-label">Invoice No</label>
                                            <input type="text" formControlName="invoice_no" class="form-control" readonly
                                            placeholder="Invoice No" [value]="Invoiceno" aria-describedby="register-invoice_no" 
                                            [ngClass]="{ 'is-invalid': submitted && f.invoice_no.errors }" />
                                        </div>
                                    </div>
                                    <div class="col-lg-6 pt-1">
                                        <div class="form-group">
                                            <label for="register-remark" class="form-label">Remark</label>
                                            <input type="text" formControlName="remark" class="form-control"
                                            placeholder="Remark" aria-describedby="register-remark" 
                                            [ngClass]="{ 'is-invalid': submitted && f.remark.errors }" />
                                        </div>
                                    </div>
                                    
                                </div>
                            </form>
                            <div class="row" *ngFor="let pue_product of salesProducts; let i = index;">
                                <div class="form-group col-lg-4">
                                    <label>Product</label>
                                    <ng-select name="product_id_{{pue_product.id}}" [(ngModel)]="pue_product.product_id">
                                        <ng-option disabled selected value="0">Select Product</ng-option>
                                        <ng-option *ngFor="let pro of productDropDown" [value]="pro.id">{{pro.product_name}}</ng-option>
                                    </ng-select>
                                </div>
                                <div class="form-group col-lg-2">
                                    <label>Qty<span class="text-danger">*</span></label>
                                    <input type="text" class="form-control" [(ngModel)]="pue_product.qty" name="qty_{{pue_product.id}}" (change)="addTotal($event)" placeholder="Qty" />
                                </div>
                                <div class="form-group col-lg-2">
                                    <label>Price<span class="text-danger">*</span></label>
                                    <input type="text" class="form-control" [(ngModel)]="pue_product.price" name="price_{{pue_product.id}}" (change)="addTotal($event)" placeholder="Price" />
                                </div>
                                <div class="form-group col-lg-2">
                                    <label>Total<span class="text-danger">*</span></label>
                                    <input type="text" class="form-control"[(ngModel)]="pue_product.total" name="total_{{pue_product.id}}" placeholder="Total" />
                                </div>
                                <div class="form-group  col-lg-1">
                                    <br/>
                                    <button type="button" class="btn btn-danger" (click)="remove(i,pue_product.sale_tra_id,pue_product.qty)">
                                        <i  data-feather="trash"></i>
                                    </button>
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group offset-lg-8 col-lg-2">
                                    <input type="text" class="form-control" name="final_total" placeholder="Final Total" [(ngModel)]="final_total" />
                                </div>
                            </div>
                            <button type="button" class="btn btn-outline-success mb-2" (click)="addMore()"><i  data-feather="plus"></i> Add More</button>
                            <div class="row">
                                <div class="col col-sm-6 col-xs-6 col-lg-2 pt-1">
                                    <button [disabled]="loading" type="button" routerLink="/products"
                                        class="btn btn-secondary btn-block" rippleEffect>
                                        <span *ngIf="loading"
                                            class="spinner-border spinner-border-sm mr-1"></span>Cancel
                                    </button>
                                </div>
                                <div class="col col-sm-6 col-xs-6 offset-lg-8 col-lg-2 pt-1">
                                    <button [disabled]="isSubmitting" type="button" (click)="onSubmit()"
                                        class="btn btn-primary btn-block" *ngIf="isAddMode" rippleEffect>
                                        <span *ngIf="loadingFrm" class="spinner-border spinner-border-sm mr-1"></span>Save
                                    </button>

                                    <!-- <button type="submit" class="btn btn-primary ml-1 " rippleEffect [disabled]="loadingFrm"> <span *ngIf="loadingFrm" class="spinner-border spinner-border-sm mr-1"></span>Submit</button> -->

                                    <button [disabled]="isSubmitting" type="button" (click)="onSubmit()"
                                        class="btn btn-primary btn-block" *ngIf="!isAddMode" rippleEffect>
                                        <span *ngIf="loading"
                                            class="spinner-border spinner-border-sm mr-1"></span>Update
                                    </button>
                                </div>
                                <div class="clearfix"></div>
                                <div class="col col-sm-12 col-xs-12 col-lg-8">
                                    <ngb-alert [type]="'danger'" [dismissible]="false" *ngIf="error">
                                        <div class="alert-body">
                                            <p>{{ error }}</p>
                                        </div>
                                    </ngb-alert>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>