import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import Swal from "sweetalert2";
import { ToastrService } from "ngx-toastr";
import { AuthenticationService } from "app/auth/service";
import { Router, ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OrderService } from 'app/_services/order.service';

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss']
})
export class OrderComponent implements OnInit {
  public orderList: any = [];
  public start: any = 0;
  public page: any = 0;
  public pageBasicText = 1;
  public collectionSize = 0;
  public lengths = 15;
  public loading = false;
  public progressMainTable: boolean = false;
  public isModelLoading: Boolean =true;
  public orderDetails:any;
  public newFormFilter: FormGroup;
  public submitted = false;
  public purchaseId: any = 0;
  public type: any = 0;
  public error = "";
  public loadingFrm: boolean = false;
  public file: any = '';
  public vendorDropDown: any = [];

  constructor(
    private _router: Router,
    private route: ActivatedRoute,
    private _authenticationService: AuthenticationService,
    private _formBuilder: FormBuilder,
    private _toastrService: ToastrService,
    private http: HttpClient,
    private modalService: NgbModal,
    private orderService: OrderService,
  ) { }

  ngOnInit(): void {
    this.loading = true;

    this.getvendorDropDown();
    this.ordersList(this.start, this.lengths);

    this.newFormFilter = this._formBuilder.group({
      start_date: [''],
      end_date: [''],
      order_to: [null],
      consignee_to: [null],
    });
  }

  get f() {
    return this.newFormFilter.controls;
  }

  getvendorDropDown() {
    this.orderService.getvendorDropDown().subscribe(
      data => {
        if (data.status) {
          this.vendorDropDown = data.vendor;
        }
      });
  }
  onSubmitFilter(){
    this.submitted = true;
    this.loading = true;
    // this.toggleSidebar('filter-sidebar');
    this.orderList = [];
    // stop here if form is invalid
    if (this.newFormFilter.invalid) {
      
    }
    else {
      this.page = 0;      
      this.pageBasicText = 1;
      
      const formdata = new FormData();
      formdata.append('start', "0");
      formdata.append('length', "15");
      
      if (this.f.order_to.value !== null) {
        formdata.append('order_to', this.f.order_to.value);
      }
      if (this.f.consignee_to.value !== null) {
        formdata.append('consignee_to', this.f.consignee_to.value);
      }
      if (this.f.start_date.value !== null) {
        formdata.append('start_date', this.f.start_date.value);
      }
      if (this.f.end_date.value !== null) {
        formdata.append('end_date', this.f.end_date.value);
      }
      
      this.orderService.getData(formdata).subscribe(
        data => {
          this.loading = false;
          if (data.status) {
            this.orderList = data.data;
            this.collectionSize = data.recordsTotal;
          }
        },
        err => {
          if (err.error.error == 'Unauthenticated.') {
            this._authenticationService.logout();
            this._router.navigate(['/login']);
          }
        }
      );
    }
  }

  resetReport() {
    this.newFormFilter.reset();
    this.onSubmitFilter();
  }
  
  ordersList(my_start: any, my_lengths: any) {
    const formdata = new FormData();
    formdata.append('start', my_start);
    formdata.append('length', my_lengths);

    this.orderService.getData(formdata).subscribe(
      (response) => {
        this.loading = false;
        this.progressMainTable = false;
        if (response.status) {
          this.orderList = response.data;
          this.collectionSize = response.recordsTotal;
        }
      },
      (err) => {
        if (err.error.error == "Unauthenticated.") {
          this._authenticationService.logout();
          this._router.navigate(["/login"]);
        }
      }
    );
  }

  ordersListtNew(my_start: any, my_lengths: any,type: any = 0) {
    const formdata = new FormData();
    if (type != 1) {
      formdata.append('start', my_start);
    }
    else {
      formdata.append('start', '0');
      this.page = 0;
      this.pageBasicText = 1;
    }
    formdata.append('length', my_lengths);
    if (type != 1) {
      if (this.f.order_to.value !== null) {
        formdata.append('order_to', this.f.order_to.value);
      }
      if (this.f.consignee_to.value !== null) {
        formdata.append('consignee_to', this.f.consignee_to.value);
      }
      if (this.f.start_date.value !== null) {
        formdata.append('start_date', this.f.start_date.value);
      }
      if (this.f.end_date.value !== null) {
        formdata.append('end_date', this.f.end_date.value);
      }
    }

    this.orderService.getData(formdata).subscribe(
      (response) => {
        this.loading = false;
        this.progressMainTable = false;
        if (response.status) {
          this.orderList = response.data;
          this.collectionSize = response.recordsTotal;
        }
      },
      (err) => {
        if (err.error.error == "Unauthenticated.") {
          this._authenticationService.logout();
          this._router.navigate(["/login"]);
        }
      }
    );
  }
  remove(id: any = 0) {
    const me = this;
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to delete this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#7367F0",
      cancelButtonColor: "#E42728",
      confirmButtonText: "Yes, delete it!",
      customClass: {
        confirmButton: "btn btn-primary",
        cancelButton: "btn btn-danger ml-1",
      },
    }).then(function (result) {
      if (result.value) {
        me.orderService.delete(id).subscribe(
          (response) => {
            if (response.status) {
              Swal.fire({
                icon: "success",
                title: "Deleted!",
                text: "Record Deleted Successfully",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
              window.location.reload();
            }
          },
          (err) => {
            if (err.error.error == "Unauthenticated.") {
              this._authenticationService.logout();
              this._router.navigate(["/login"]);
            }
          }
        );
      }
    });
  }

  reloadList(type: any = 0) {
    this.progressMainTable = true;
    this.ordersListtNew(this.page, this.lengths, type);
  }

  loadPage(event: any) {
    this.progressMainTable = true;
    this.page = this.lengths * (event - 1);
    this.ordersListtNew(this.page, this.lengths);
  }
  loadPageLenghs(event: any) {
    this.progressMainTable = true;
    this.lengths = event.target.value;
    this.ordersListtNew(this.start, this.lengths);
  }

  details(id: any,modalSM) {
    this.modalService.open(modalSM, {
      centered: true,
      size: 'xl'
    });
    this.orderService.getmeta(id).subscribe(
      data => {
          this.isModelLoading = false;
          if (data.status) {
              this.orderDetails = data.data;
          }else {
              this._toastrService.error(data.errorMessage, 'Error!');
          }
      },
      err => {
          this._toastrService.error(err.errorMessage, 'Opps!');
      }
    );
  } 
}
