import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'environments/environment';
@Injectable({
  providedIn: 'root'
})
export class SubStockTransferService {

  constructor(private _http: HttpClient) { }

  getData(formdata: any): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}get-sub-stock-transfer`, formdata)
      .pipe(
        map(data => {
          return data;
        })
      );
  }
  getmeta(id: string): Observable<any> {
    return this._http.post(`${environment.apiUrl}get-sub-stock-transfer-meta`, {id});
  }

  add(user_id:any,send_user_id:any,receive_user_id:any,date:any,remark:any, id: any,final_total:any,stockTransfer:any): Observable<any> {
    if (id == 0) {
      return this._http
        .post(`${environment.apiUrl}save-sub-stock-transfer`, {user_id,send_user_id,receive_user_id,date,remark,final_total,stockTransfer})
        .pipe(
          map(data => {
            return data;
          })
        );
    }
  }
}
