import { Component, OnInit,ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { ToastrService } from "ngx-toastr";
import { AuthenticationService } from "app/auth/service";
import { Router, ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { OutstandingReportService } from 'app/_services/outstanding-report.service';

@Component({
  selector: 'app-outstanding-report',
  templateUrl: './outstanding-report.component.html',
  styleUrls: ['./outstanding-report.component.scss']
})
export class OutstandingReportComponent implements OnInit {
  @ViewChild('auto') auto: any;
  public start: any = 0;
  public page: any = 0;
  public lengths = 15;
  public loading = false;
  public progressMainTable: boolean = false;
  public collectionSize = 0;
  public pageBasicText = 1;

  public newFormAdd: FormGroup;
  public submitted = false;
  public isLoading: boolean = false;
  public error = "";
  public allCustomer: any;
  public loadingReport: boolean = false;
  public customerId: any = 0;
  public customerDetails: any = '';
  public customerOutStanding: any = [];
  public customerOpeningBalance: any;
  public historyHeading: string = 'Recently selected';
  public keyword = 'name';
  public currentUser: any;
  public tempUser: any;
  public user_id:any = 0;
  public customerDropDown: any = [];

  constructor(
    private _router: Router,
    private route: ActivatedRoute,
    private outstandingReportService: OutstandingReportService,
    private _authenticationService: AuthenticationService,
    private _formBuilder: FormBuilder,
    private _toastrService: ToastrService,
    private http: HttpClient,
  ) { }

  ngOnInit(): void {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.tempUser = JSON.parse(localStorage.getItem('tempUser'));
    if(this.currentUser.user.id != this.tempUser){
      this.user_id = this.tempUser;
    }else{
      this.user_id = this.currentUser.user.id;
    }
    this.getcustomerDropDown(this.user_id);
    this.outstandingReportList(this.start, this.lengths,this.user_id);

    this.newFormAdd = this._formBuilder.group({
      start_date: [''],
      end_date: [''],
      customer_id: ["", Validators.required],
    });

  }

  get f() {
    return this.newFormAdd.controls;
  }

  getcustomerDropDown(my_user_id:any) {
    this.outstandingReportService.getcustomerDropDown(my_user_id).subscribe(
      data => {
        if (data.status) {
          this.customerDropDown = data.customer;
        }
      });
  }

  onChangeSearch(value: string) {
    this.isLoading = true
    this.outstandingReportService.customerSearch(value).subscribe(
      data => {
        if (data.status) {
          this.isLoading = false
          this.allCustomer = data.customer;
        }
        else {
          this.isLoading = false;
          this.allCustomer = [];
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
        this._toastrService.clear();
        this._toastrService.error(err.errorMessage, 'Opps!', { toastClass: 'toast ngx-toastr', closeButton: true });
        this.allCustomer = [];
      }
    );
  }

  selectEvent(item: any) {
    this.customerId = item.id;
    this.customerDetails = item;
    this.customerOpeningBalance = '';
    this.customerOutStanding = [];
    this.loadReport();
  }

  onFocused(e: any) {
    // do something when input is focused
  }

  clear(): void {
    this.auto.clear();
  }

  loadReport() {
    if (this.customerId > 0) {
      this.loadingReport = true;
      this.customerOpeningBalance = '';
      this.customerOutStanding = [];

      this.outstandingReportService.loadOutStandingReport(this.customerId).subscribe(
        data => {
          this.loadingReport = false;
          if (data.status) {
            let total = 0;
            if (data.opening_balance.balance_type == 'D') {
              total = -data.opening_balance.balance;
            }
            else {
              total = data.opening_balance.balance;
            }
            data.data.forEach((value: any, index: any) => {
              if (value.payment_type == "C") {
                total += parseFloat(value.amount);
              }
              else {
                total -= parseFloat(value.amount);
              }
              data.data[index].closing = total;
            });

            this.customerOpeningBalance = data.opening_balance;
            this.customerOutStanding = data.data;

          }
        },
        err => {
          if (err.error.error == 'Unauthenticated.') {
            this._authenticationService.logout();
            this._router.navigate(['/login']);
          }
          this._toastrService.clear();
          this._toastrService.error(err.errorMessage, 'Opps!', { toastClass: 'toast ngx-toastr', closeButton: true });
          this.customerOpeningBalance = '';
          this.customerOutStanding = [];
          this.loadingReport = false;
        }
      );

    }

  }

  onSubmit() {
    this.submitted = true;
    this.loading = true;
    // this.toggleSidebar('new-sidebar');
    this.customerOutStanding = [];
    // stop here if form is invalid
    if (this.newFormAdd.invalid) {
      this.newFormAdd.markAllAsTouched();
    }else{
      this.page = 0;      
      this.pageBasicText = 1;
      
      const formdata = new FormData();
      formdata.append('start', "0");
      formdata.append('length', "15");

      if (this.f.start_date.value !== null) {
        formdata.append('start_date', this.f.start_date.value);
      }
      if (this.f.end_date.value !== null) {
        formdata.append('end_date', this.f.end_date.value);
      }
      if (this.f.customer_id.value != "") {
        formdata.append('customer_id', this.f.customer_id.value);
      }

      this.outstandingReportService.loadOutStandingReportnew(formdata).subscribe(
        data => {
          this.loading = false;
          if (data.status) {
            let total = 0;
            if (data.opening_balance.balance_type == 'D') {
              total = -data.opening_balance.balance;
            }
            else {
              total = data.opening_balance.balance;
            }
            data.data.forEach((value: any, index: any) => {
              if (value.payment_type == "C") {
                total += parseFloat(value.amount);
              }
              else {
                total -= parseFloat(value.amount);
              }
              data.data[index].closing = total;
            });

            this.customerOpeningBalance = data.opening_balance;
            this.customerOutStanding = data.data;
          }
        },
        err => {
          if (err.error.error == 'Unauthenticated.') {
            this._authenticationService.logout();
            this._router.navigate(['/login']);
          }
          this._toastrService.clear();
          this._toastrService.error(err.errorMessage, 'Opps!', { toastClass: 'toast ngx-toastr', closeButton: true });
          this.customerOpeningBalance = '';
          this.customerOutStanding = [];
          this.loading = false;
        }
      );
    }
  }
  resetReport() {
    this.newFormAdd.reset();
    this.customerOutStanding = [];
  }
  outstandingReportList(my_start: any, my_lengths: any,my_user_id:any) {

  }

  outstandingReportListNew(my_start: any, my_lengths: any,type: any = 0) {

  }
  reloadList(type: any = 0) {
    this.progressMainTable = true;
    this.outstandingReportListNew(this.page, this.lengths, type);
  }

  loadPage(event: any) {
    this.progressMainTable = true;
    this.page = this.lengths * (event - 1);
    this.outstandingReportListNew(this.page, this.lengths);
  }
  loadPageLenghs(event: any) {
    this.progressMainTable = true;
    this.lengths = event.target.value;
    this.outstandingReportListNew(this.start, this.lengths);
  }

  downloadExcelData() {
    const formdata = new FormData();
    if (this.f.start_date.value !== null) {
      formdata.append('start_date', this.f.start_date.value);
    }
    if (this.f.end_date.value !== null) {
      formdata.append('end_date', this.f.end_date.value);
    }
    if (this.f.customer_id.value != "") {
      formdata.append('customer_id', this.f.customer_id.value);
    }

    this.outstandingReportService.fileDownload(formdata).subscribe(
      (data) => {
        window.location.href = data.url;
      },
      (error) => {
        this._toastrService.error('Something went wrong! Please Try Againg...', 'Opps!', { toastClass: 'toast ngx-toastr', closeButton: true });
      }
    );
  }
}