import { Component, OnInit, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import Swal from "sweetalert2";
import { ToastrService } from "ngx-toastr";
import { AuthenticationService } from "app/auth/service";
import { Router, ActivatedRoute } from "@angular/router";
import { CoreSidebarService } from "@core/components/core-sidebar/core-sidebar.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbDateAdapter, NgbDateParserFormatter, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import { SalesService } from 'app/_services/sales.service';

@Injectable()
 export class CustomDateParserFormatter extends NgbDateParserFormatter {
   readonly DELIMITER = '-';
 
   parse(value: string): NgbDateStruct | null {
     if (value) {
       const date = value.split(this.DELIMITER);
       return {
         day: parseInt(date[0], 10),
         month: parseInt(date[1], 10),
         year: parseInt(date[2], 10),
       };
     }
     return null;
   }
 
   format(date: NgbDateStruct | null): string {
     return date ? date.day + this.DELIMITER + date.month + this.DELIMITER + date.year : '';
   }
 }

@Component({
  selector: 'app-sales-add',
  templateUrl: './sales-add.component.html',
  styleUrls: ['./sales-add.component.scss'],

  providers: [
		{ provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
	],
})
export class SalesAddComponent implements OnInit {

  public newForm: FormGroup;
  public submitted = false;
  public isAddMode: boolean = true;
  public loadingFrm: boolean = false;
  public isSubmitting: boolean = true;
  public salesId: 0;
  public final_total: any = 0.00;
  public error = "";
  public customerDropDown: any = [];
  public productDropDown: any = [];
  public Invoiceno: any = [];
  public FormSubmit: Boolean = false;
  public addFormSubmit: Boolean = false;
  public loading = false;
  public sale_details: any = [];

  public today = new Date();
  public getDate = String(this.today. getDate()). padStart(2, '0');
  public getMonth = String(this.today. getMonth() + 1). padStart(2, '0'); //January is 0!
  public getYear = this.today. getFullYear();

  public maxDate = Number(this.getDate);
  public maxMonth = Number(this.getMonth);
  public maxYear = Number(this.getYear);
  public basicDPdata: NgbDateStruct = {day: this.maxDate, month: this.maxMonth, year: this.maxYear};
  public currentUser: any;
  public tempUser: any;
  public user_id:any = 0;
  constructor(
    private _router: Router,
    private route: ActivatedRoute,
    private salesService: SalesService,
    private _authenticationService: AuthenticationService,
    private _formBuilder: FormBuilder,
    private _toastrService: ToastrService,
    private http: HttpClient,
    private _coreSidebarService: CoreSidebarService
  ) { }

  public salesProducts: any[] = [{
    id: 1,
    product_id: '0',
    price: '0',
    qty: '0',
    total: '0',
  }];

  ngOnInit(): void {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.tempUser = JSON.parse(localStorage.getItem('tempUser'));
    if(this.currentUser.user.id != this.tempUser){
      this.user_id = this.tempUser;
    }else{
      this.user_id = this.currentUser.user.id;
    }
    this.getcustomerDropDown(this.user_id);
    this.getproductDropDown(this.user_id);
    this.getInvoiceno(this.user_id);

    this.newForm = this._formBuilder.group({
      customer_id: ["", Validators.required],
      type_id: ["", Validators.required],
      date: ["", Validators.required],
      remark: [""],
      invoice_no: [""]
    });
    
    this.salesId = this.route.snapshot.params['id'];
    this.isAddMode = !this.salesId;
    this.loadingFrm = false;
    
    if (!this.isAddMode) {
      this.edit(this.salesId);
    }else {
      this.salesId = 0;
    }
  }

  get f() {
    return this.newForm.controls;
  }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.newForm.invalid) {
      this.newForm.markAllAsTouched();
    } else {
      this.loadingFrm = true;
      this.isSubmitting = true;
      this.error = "";
      let date = this.newForm.controls.date.value['year']+'-'+ this.newForm.controls.date.value['month']+'-'+this.newForm.controls.date.value['day'];
      const { invoice_no,customer_id,type_id,remark } = this.newForm.controls;
      
      this.salesService.add(
        this.user_id,
        this.Invoiceno,
        customer_id.value,
        type_id.value,
        date,
        remark.value,
        this.salesId,
        this.final_total,
        this.salesProducts
      ).subscribe(
        (data) => {
          this.loadingFrm = false;
          if (data.status) {
            this.salesId = 0;
            this.submitted = false;
            this.newForm.reset();
            this._toastrService.success(data.message, "Success!", {
              toastClass: "toast ngx-toastr",
              closeButton: true,
            });
            this._router.navigate(['/sales']);
          } else {
            this.error = data.message;
          }
        },
        (err) => {
          if (err.error.error == "Unauthenticated.") {
            this._authenticationService.logout();
            this._router.navigate(["/login"]);
          }
        }
      );
    }
  }

  edit(id: any) {
    this.loadingFrm = true;
    this.salesService.edit(id).subscribe(
      data => {
        if (data.status) {
          this.loadingFrm = false;
          const [year, month, day] = data.sale.date.split('-');
          const obj = { year: parseInt(year), month: parseInt(month), day: 
            parseInt(day.split(' ')[0].trim()) };
            
          this.newForm.setValue({
            date:  {day: parseInt(day), month: parseInt(month), year: parseInt(year)},
            remark: data.sale.remark,
            customer_id: data.sale.customer_id,
            type_id: data.sale.type_id,
            invoice_no: data.sale.invoice_no,
          });
          this.final_total = data.sale.final_total;

          this.salesId = data.sale.id,
          this.salesProducts = data.sale_details;
          this.isSubmitting = false;
        } else {
          this._toastrService.error(data.errorMessage, 'Error!');
        }
      },
      err => {
        this._toastrService.error(err.errorMessage, 'Opps!');
      }
    );
  }

  getInvoiceno(my_user_id:any) {
    this.salesService.getInvoiceno(my_user_id).subscribe(
      data => {
        if (data.status) {
          this.Invoiceno = data.invoice_no;
        }
      });
  }
  
  getproductDropDown(my_user_id:any) {
    this.salesService.getproductDropDown(my_user_id).subscribe(
      data => {
        if (data.status) {
          this.productDropDown = data.product;
        }
      });
  }

  getcustomerDropDown(my_user_id:any) {
    this.salesService.getcustomerDropDown(my_user_id).subscribe(
      data => {
        if (data.status) {
          this.customerDropDown = data.customer;
        }
      });
  }

  addMore() {
    this.salesProducts.push({
      id: this.salesProducts.length + 1,
      product_id: '0',
      price: '0',
      qty: '0',
      total: '0',
    });
  }

  remove(i: number, productId: any = 0,qty:any) {
    const me = this;
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to delete this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#7367F0",
      cancelButtonColor: "#E42728",
      confirmButtonText: "Yes, delete it!",
      customClass: {
        confirmButton: "btn btn-primary",
        cancelButton: "btn btn-danger ml-1",
      },
    }).then(function (result) {
      if (result.value) {
        me.salesService.delete(productId,qty,me.user_id).subscribe(
          (response) => {
            if (response.status) {
              Swal.fire({
                icon: "success",
                title: "Deleted!",
                text: "Record Deleted Successfully",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
              // remove code
              me.salesProducts.splice(i, 1);
              let temp_total = 0;
              for (let i = 0; i < me.salesProducts.length; i++) {
                me.salesProducts[i].total = (me.salesProducts[i].price * me.salesProducts[i].qty).toFixed(2);
                temp_total += parseFloat(me.salesProducts[i].total);
              }
              me.final_total = temp_total.toFixed(2);
              // remove code
            }
          },
          (err) => {
            if (err.error.error == "Unauthenticated.") {
              this._authenticationService.logout();
              this._router.navigate(["/login"]);
            }
          }
        );
      }
    });
  }
  
  addTotal(event:any){
    let temp_total = 0;
    for (let i = 0; i < this.salesProducts.length; i++) {
      this.salesService.stockcheck(this.salesProducts[i].product_id, this.salesProducts[i].qty,this.user_id).subscribe(
        (response) => {
          this.loading = false;
          if (response.status){
            this.salesProducts[i].total = (this.salesProducts[i].price * this.salesProducts[i].qty).toFixed(2);
            temp_total += parseFloat(this.salesProducts[i].total);
            this.final_total = temp_total.toFixed(2);
            this.isSubmitting = false;
          }else {
            this._toastrService.error(response.message, "Error!", {
              toastClass: "toast ngx-toastr",
              closeButton: true,
            });
            this.isSubmitting = true;
          }
        },
      );
    }
  }
}
