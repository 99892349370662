import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import Swal from "sweetalert2";
import { ToastrService } from "ngx-toastr";
import { AuthenticationService } from "app/auth/service";
import { Router, ActivatedRoute } from "@angular/router";
import { CoreSidebarService } from "@core/components/core-sidebar/core-sidebar.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { UserService } from 'app/_services/user.service';
@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {

  public userList: any = [];
  public start: any = 0;
  public page: any = 0;

  public sidebarToggleRef = false;
  public contentHeader: object;
  public pageBasicText = 1;
  public collectionSize = 0;
  public lengths = 30;
  public loading = false;
  public progressMainTable: boolean = false;
  public newFormAdd: FormGroup;
  public submitted = false;
  public isAddMode: boolean = true;
  public loadingFrm: boolean = false;
  public userId = 0;
  public error = "";
  public dowaloadLoder: boolean = false;
  public currentUser: any;

  constructor(
    private _router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private _authenticationService: AuthenticationService,
    private _formBuilder: FormBuilder,
    private _toastrService: ToastrService,
    private http: HttpClient,
    private _coreSidebarService: CoreSidebarService
  ) { }

  ngOnInit(): void {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));

    this.usersList(this.start, this.lengths);
    if(this.currentUser.user.id == 1){
      this.newFormAdd = this._formBuilder.group({
        name: ["", Validators.required],
        mobile_number: ["", Validators.required],
        email: [""],
        password: ["", Validators.required],
      });
    }else{
      this.newFormAdd = this._formBuilder.group({
        name: ["", Validators.required],
        mobile_number: ["", Validators.required],
        email: [""],
        password: [""],
      });
    }
  }

  get f() {
    return this.newFormAdd.controls;
  }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.newFormAdd.invalid) {
      this.newFormAdd.markAllAsTouched();
    } else {
      this.loadingFrm = true;
      this.error = "";
      const { name, mobile_number, email, password } = this.newFormAdd.controls;
      this.userService.add(
        name.value,
        mobile_number.value,
        email.value,
        password.value,
        this.userId
      ).subscribe(
        (data) => {
          this.loadingFrm = false;
          if (data.status) {
            this.userId = 0;
            this.submitted = false;
            this.newFormAdd.reset();
            this.toggleSidebar("new-sidebar");
            this._toastrService.success(data.message, "Success!", {
              toastClass: "toast ngx-toastr",
              closeButton: true,
            });
            this.reloadList();
          } else {
            this.error = data.message;
          }
        },
        (err) => {
          if (err.error.error == "Unauthenticated.") {
            this._authenticationService.logout();
            this._router.navigate(["/login"]);
          }
        }
      );
    }
  }

  toggleSidebar(name, type: any = 0): void {
    if (type == 0) {
      this.userId = 0;
    }
    this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
  }

  usersList(my_start: any, my_lengths: any) {
    this.userService.getData(my_start, my_lengths).subscribe(
      (response) => {
        this.loading = false;
        this.progressMainTable = false;
        if (response.status) {
          this.userList = response.data;
          this.collectionSize = response.recordsTotal;
        }
      },
      (err) => {
        if (err.error.error == "Unauthenticated.") {
          this._authenticationService.logout();
          this._router.navigate(["/login"]);
        }
      }
    );
  }

  reloadList() {
    this.progressMainTable = true;
    this.usersList(this.page, this.lengths);
  }

  loadPage(event: any) {
    this.progressMainTable = true;
    this.page = this.lengths * (event - 1);
    this.usersList(this.page, this.lengths);
  }
  loadPageLenghs(event: any) {
    this.progressMainTable = true;
    this.lengths = event.target.value;
    this.usersList(this.start, this.lengths);
  }

  edit(index: any) {
    this.userId = this.userList[index].id;
    this.f.name.setValue(this.userList[index].name);
    this.f.mobile_number.setValue(this.userList[index].mobile_number);
    this.f.email.setValue(this.userList[index].email);

    this.toggleSidebar("new-sidebar", 1);
  }

  delete(id: any) {
    const me = this;
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#7367F0",
      cancelButtonColor: "#E42728",
      confirmButtonText: "Yes, delete it!",
      customClass: {
        confirmButton: "btn btn-primary",
        cancelButton: "btn btn-danger ml-1",
      },
    }).then(function (result) {
      if (result.value) {
        me.userService.delete(id).subscribe(
          (response) => {
            if (response.status) {
              me.progressMainTable = true;
              me.reloadList();
              Swal.fire({
                icon: "success",
                title: "Deleted!",
                text: "User Deleted Successfully",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
            }
          },
          (err) => {
            if (err.error.error == "Unauthenticated.") {
              this._authenticationService.logout();
              this._router.navigate(["/login"]);
            }
          }
        );
      }
    });
  }

  downloadExcelData() {
    this.userService.fileDownload().subscribe(
      (data) => {
        window.location.href=data.url;
      },
      (error) => {
        this._toastrService.error('Something went wrong! Please Try Againg...', 'Opps!', { toastClass: 'toast ngx-toastr', closeButton: true });
      }
    );
  }
}
