<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <section id="home-page">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Stock List</h4>
                        </div>
                        <div class="card-body p-0 pb-1">
                            <div class="row m-0">
                                <div class="col-md-6 col-6">
                                    <div class="d-flex justify-content-between align-items-center ml-1">
                                        <label class="d-flex align-items-center">Show
                                        <select class="form-control mx-25" [(ngModel)]="lengths" (change)="loadPageLenghs($event)" >
                                            <option value="15">15</option>
                                            <option value="30">30</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                        </select>
                                      </label>
                                    </div>
                                </div>
                                <div class="col-md-6 col-6 d-flex justify-content-start justify-content-md-end">
                                    <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pb-md-0 w-100">
                                        <button class="btn btn-info ml-1" rippleEffect
                                            (click)="downloadExcelData()">
                                            <i data-feather="plus"
                                                class="d-sm-none d-inline-block mr-0 mr-sm-1"></i><span
                                                class="d-none d-sm-inline-block">Download</span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="col p-0 table-responsive" *ngIf="currentUser.user.id != 1">
                                <div class="overlay" *ngIf="progressMainTable">
                                    <div class="spinner-grow" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </div>
                                <table class="table table-striped table-hover valign-middle text-dark">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Name</th>
                                            <th>Product</th>
                                            <th>Qty</th>
                                            <th>History</th>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="stockList?.length != 0">
                                        <tr *ngFor="let ware of stockList; let ndx = index">
                                            <td>{{ndx+page+1}}</td>
                                            <td>{{ ware.name }}</td>
                                            <td>{{ ware.product_name }}</td>
                                            <td>{{ ware.qty }}</td>
                                            <td *ngIf="user_id == ware.user_id">
                                                <button type="button" (click)="productHistory(ware.product_id,modalSM1)" data-toggle="tooltip" data-popup="tooltip-custom" placement="top" container="body" ngbTooltip="View" class="btn btn-icon btn-sm btn-warning mr-1" rippleEffect>
                                                    <i data-feather="eye"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="stockList?.length == 0">
                                        <tr *ngIf="!loading">
                                            <td colspan="3" class="no-data-available">No Data!</td>
                                        </tr>
                                        <tr>
                                            <td colspan="3" class="no-data-available">
                                                <div class="col" *ngIf="loading">
                                                    <div class="text-center">
                                                        <div class="spinner-border" role="status">
                                                            <span class="sr-only">Loading...</span>
                                                        </div>
                                                        <div>
                                                            Please Wait...
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <ngb-pagination class="d-flex justify-content-end mt-2 mr-2" *ngIf="collectionSize > 0" [collectionSize]="collectionSize" [pageSize]="lengths" [(page)]="pageBasicText" (pageChange)="loadPage($event)" aria-label="Custom pagination">
                                    <ng-template ngbPaginationPrevious><span [data-feather]="'chevron-left'" [class]="'font-weight-bolder'"></span>Prev
                                    </ng-template>
                                    <ng-template ngbPaginationNext>Next<span [data-feather]="'chevron-right'" [class]="'font-weight-bolder'"></span>
                                    </ng-template>
                                </ngb-pagination>
                            </div>
                            <div class="col p-0 table-responsive" *ngIf="currentUser.user.id == 1">
                                <div class="overlay" *ngIf="progressMainTable">
                                    <div class="spinner-grow" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </div>
                                <table class="table table-striped table-hover valign-middle text-dark">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Name</th>
                                            <!-- <th>Product</th> -->
                                            <th>Qty</th>
                                            <th>View</th>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="adminstockList?.length != 0">
                                        <tr *ngFor="let ware of adminstockList; let ndx = index">
                                            <td>{{ndx+page+1}}</td>
                                            <td>{{ ware.name }}</td>
                                            <!-- <td>{{ ware.product_name }}</td> -->
                                            <td>{{ ware.qty }}</td>
                                            <td>
                                                <button type="button" (click)="details(ware,modalSM)" data-toggle="tooltip" data-popup="tooltip-custom" placement="top" container="body" ngbTooltip="View" class="btn btn-icon btn-sm btn-warning mr-1" rippleEffect>
                                                    <i data-feather="eye"></i>
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="adminstockList?.length == 0">
                                        <tr *ngIf="!loading">
                                            <td colspan="3" class="no-data-available">No Data!</td>
                                        </tr>
                                        <tr>
                                            <td colspan="3" class="no-data-available">
                                                <div class="col" *ngIf="loading">
                                                    <div class="text-center">
                                                        <div class="spinner-border" role="status">
                                                            <span class="sr-only">Loading...</span>
                                                        </div>
                                                        <div>
                                                            Please Wait...
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <ngb-pagination class="d-flex justify-content-end mt-2 mr-2" *ngIf="admincollectionSize > 0" [collectionSize]="admincollectionSize" [pageSize]="lengths" [(page)]="pageBasicText" (pageChange)="loadPage($event)" aria-label="Custom pagination">
                                    <ng-template ngbPaginationPrevious><span [data-feather]="'chevron-left'" [class]="'font-weight-bolder'"></span>Prev
                                    </ng-template>
                                    <ng-template ngbPaginationNext>Next<span [data-feather]="'chevron-right'" [class]="'font-weight-bolder'"></span>
                                    </ng-template>
                                </ngb-pagination>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- Modal -->
        <ng-template #modalSM let-modal>
            <div class="overlay" *ngIf="isModelLoading">
                <div class="spinner-border text-primary" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
            <div class="modal-header">
                <h5 class="modal-title w-100" id="myModalLabel160">
                    <span class="float-left"> <span class="badge badge-glow badge-primary">Stock Details - <b>{{singleName.name}}</b></span></span>
                </h5>
                <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body" tabindex="0" ngbAutofocus>
                <div class="row">
                    <div class="table-responsive m-t-40">
                        <table class="table table-hover">
                            <thead>
                                <tr>
                                    <th class="text-center">#</th>
                                    <th class="text-center">Product Name</th>
                                    <th class="text-center">Quantity</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let ind of adminDetails; let i = index">
                                    <td class="text-center">{{ i + 1 }}</td>
                                    <td class="text-center">{{ ind.product_name}}</td>
                                    <td class="text-center">{{ ind.qty}}</td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="adminDetails?.length == 0">
                                <tr>
                                    <td colspan="4" class="no-data-available">No data!</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </ng-template>
        <!-- / Modal -->
        <!-- Modal -->
        <ng-template #modalSM1 let-modal>
            <div class="overlay" *ngIf="isModelLoading">
                <div class="spinner-border text-primary" role="status">
                    <span class="sr-only">Loading...</span>
                </div>
            </div>
            <div class="modal-header">
                <h5 class="modal-title w-100" id="myModalLabel160">
                    <span class="float-left"> <span class="badge badge-glow badge-primary">History</span></span>
                </h5>
                <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body" tabindex="0" ngbAutofocus>
                <div class="row">
                    <div class="table-responsive m-t-40">
                        <table class="table table-hover">
                            <thead>
                                <tr>
                                    <th class="text-center">#</th>
                                    <th class="text-center">Date</th>
                                    <th class="text-center">Type</th>
                                    <th class="text-center">Quantity</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let ind of proHistory; let i = index">
                                    <td class="text-center">{{ i + 1 }}</td>
                                    <td class="text-center">{{ ind.date}}</td>
                                    <td class="text-center">
                                        <span *ngIf="ind.type == 'I'">IN</span>
                                        <span *ngIf="ind.type == 'O'">OUT</span>
                                    </td>
                                    <td class="text-center">{{ ind.qty}}</td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="proHistory?.length == 0">
                                <tr>
                                    <td colspan="4" class="no-data-available">No data!</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </ng-template>
        <!-- / Modal -->
    </div>
</div>