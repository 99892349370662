import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StockreceiveService {

  constructor(private _http: HttpClient) { }

  getData(formdata: any): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}get-stock-receive`, formdata)
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  getmeta(id: string): Observable<any> {
    return this._http.post(`${environment.apiUrl}get-metapurchase`, { id });
  }

  orderchangeStatus(id: string, status: string): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}change-status`, { status, id })
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  adddate(port_date: any, id: any): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}update-portdate`, { port_date, id })
      .pipe(
        map(data => {
          return data;
        })
      );
  }
}
