import { CoreMenu } from '@core/types'
export const menu: CoreMenu[] = [
  {
    id: 'home',
    title: 'Home',
    translate: 'MENU.HOME',
    type: 'item',
    icon: 'home',
    url: 'home'
  },
  {
    id: 'master',
    title: 'Master',
    translate: 'MENU.MASTER',
    type: 'collapsible',
    role: ['Admin'],
    icon: 'box',
    badge: {
      title: '3',
      translate: 'MENU.MASTER',
      classes: 'badge-light-warning badge-pill'
    },
    children: [
      {
        id: 'user',
        title: 'User',
        translate: 'MENU.USER',
        type: 'item',
        icon: 'user',
        url: 'user'
      },
      {
        id: 'vendor',
        title: 'Exporter',
        translate: 'MENU.VENDOR',
        type: 'item',
        icon: 'users',
        url: 'vendor'
      },
      {
        id: 'product',
        title: 'Product',
        translate: 'MENU.PRODUCT',
        type: 'item',
        icon: 'tag',
        url: 'product'
      },
    ]
  },
  {
    id: 'user',
    title: 'User',
    translate: 'MENU.USER',
    type: 'item',
    icon: 'user',
    url: 'user',
    role: ['User'],
  },
  {
    id: 'customer',
    title: 'Customer',
    translate: 'MENU.CUSTOMER',
    type: 'item',
    icon: 'users',
    role: ['User'],
    url: 'customer'
  },
  {
    id: 'currency',
    title: 'Currency',
    translate: 'MENU.CURRENCY',
    type: 'item',
    icon: 'dollar-sign',
    role: ['User'],
    url: 'currency'
  },
  {
    id: 'managestock',
    title: 'Manage Stock',
    translate: 'MENU.MANAGESTOCK',
    type: 'item',
    icon: 'database',
    role: ['Admin'],
    url: 'managestock'
  },
  {
    id: 'master',
    title: 'Stock Transfer',
    translate: 'MENU.PURCHASE',
    type: 'collapsible',
    role: ['Admin'],
    icon: 'fast-forward',
    badge: {
      title: '3',
      translate: 'MENU.MASTER',
      classes: 'badge-light-warning badge-pill'
    },
    children: [
      {
        id: 'purchase',
        title: 'In Transit',
        translate: 'MENU.PURCHASE',
        type: 'item',
        icon: 'fast-forward',
        url: 'purchase'
      },
      {
        id: 'inport',
        title: 'In Port',
        translate: 'MENU.PORT',
        type: 'item',
        icon: 'fast-forward',
        url: 'inport'
      },
      {
        id: 'purchasereceive',
        title: 'Receive',
        translate: 'MENU.RECEIVE',
        type: 'item',
        icon: 'fast-forward',
        url: 'purchasereceive'
      },
    ]
  },
  // {
  //   id: 'purchase',
  //   title: 'Stock Transfer',
  //   translate: 'MENU.PURCHASE',
  //   type: 'item',
  //   icon: 'fast-forward',
  //   role: ['Admin'],
  //   url: 'purchase'
  // },
  {
    id: 'stockreceive',
    title: 'Stock Receive',
    translate: 'MENU.STOCKRECEIVE',
    type: 'collapsible',
    role: ['User'],
    icon: 'box',
    badge: {
      title: '2',
      translate: 'MENU.STOCKRECEIVE',
      classes: 'badge-light-warning badge-pill'
    },
    children: [
      {
        id: 'intransit',
        title: 'In transit',
        translate: 'MENU.INTRANSIT',
        type: 'item',
        icon: 'skip-forward',
        role: ['User'],
        url: 'intransit'
      },
      {
        id: 'stockreceive',
        title: 'Stock Receive',
        translate: 'MENU.STOCKRECEIVE',
        type: 'item',
        icon: 'fast-forward',
        role: ['User'],
        url: 'stockreceive'
      }
    ]
  },
  {
    id: 'stocktransfer',
    title: 'Stock Transfer',
    translate: 'MENU.STOCKTRANSFER',
    type: 'item',
    icon: 'database',
    role: ['User'],
    url: 'stocktransfer'
  },
  {
    id: 'substocktransfer',
    title: 'Sub Stock Transfer',
    translate: 'MENU.SUBSTOCKTRANSFER',
    type: 'item',
    icon: 'database',
    role: ['User'],
    url: 'substocktransfer'
  },
  {
    id: 'stock',
    title: 'Stock',
    translate: 'MENU.STOCK',
    type: 'item',
    icon: 'database',
    role: ['Admin','User'],
    url: 'stock'
  },
  {
    id: 'payment',
    title: 'Payment',
    translate: 'MENU.PAYMENT',
    type: 'item',
    icon: 'dollar-sign',
    role: ['Admin'],
    url: 'payment',
    // allowedUserIds:['23']
  },
  {
    id: 'order',
    title: 'Order',
    translate: 'MENU.ORDER',
    type: 'item',
    icon: 'shopping-cart',
    role: ['Admin'],
    url: 'order',
  },
  // {
  //   id: 'purchasepayment',
  //   title: 'Purchase Payment',
  //   translate: 'MENU.PURCHASEPAYMENT',
  //   type: 'item',
  //   icon: 'dollar-sign',
  //   role: ['Admin'],
  //   url: 'purchasepayment'
  // },
  {
    id: 'sales',
    title: 'Sales',
    translate: 'MENU.SALES',
    type: 'collapsible',
    role: ['User'],
    icon: 'box',
    badge: {
      title: '2',
      translate: 'MENU.SALES',
      classes: 'badge-light-warning badge-pill'
    },
    children: [
      {
        id: 'sales',
        title: 'Sales',
        translate: 'MENU.SALES',
        type: 'item',
        icon: 'shopping-cart',
        role: ['User'],
        url: 'sales'
      },
      {
        id: 'salespayment',
        title: 'Sales Payment',
        translate: 'MENU.SALESPAYMENT',
        type: 'item',
        icon: 'dollar-sign',
        role: ['User'],
        url: 'salespayment'
      }
    ]
  },
  {
    id: 'expense_master',
    title: 'Expense',
    translate: 'MENU.EXPENSE',
    type: 'collapsible',
    role: ['User'],
    icon: 'dollar-sign',
    badge: {
      title: '3',
      translate: 'MENU.EXPENSE',
      classes: 'badge-light-warning badge-pill'
    },
    children: [
      {
        id: 'expense_category',
        title: 'Expense Category',
        translate: 'MENU.EXPENSECATEGORY',
        type: 'item',
        role: ['User'],
        icon: 'tag',
        url: 'expensecategory'
      },
      {
        id: 'expense',
        title: 'Expense',
        translate: 'MENU.EXPENSE',
        type: 'item',
        role: ['User'],
        icon: 'shopping-bag',
        url: 'expense'
      },
      {
        id: 'expensereport',
        title: 'Expense Report',
        translate: 'MENU.EXPENSEREPORT',
        type: 'item',
        role: ['User'],
        icon: 'file',
        url: 'expensereport'
      },
    ]
  },
  {
    id: 'report',
    title: 'Report',
    translate: 'MENU.REPORT',
    type: 'collapsible',
    role: ['Admin'],
    icon: 'file-minus',
    badge: {
      title: '6',
      translate: 'MENU.REPORT',
      classes: 'badge-light-warning badge-pill'
    },
    children: [
      {
        id: 'expensereportadmin',
        title: 'Expense Report',
        translate: 'MENU.EXPENSEREPORT',
        type: 'item',
        role: ['Admin'],
        icon: 'file',
        url: 'expensereportadmin'
      },
      {
        id: 'purchasepaymentreport',
        title: 'Purchase Payment Report',
        translate: 'MENU.PURCHASEPAYMENTREPORT',
        type: 'item',
        role: ['Admin'],
        icon: 'file',
        url: 'purchasepaymentreport'
      },
      {
        id: 'salesreport',
        title: 'Sales Report',
        translate: 'MENU.SALESREPORT',
        type: 'item',
        role: ['Admin'],
        icon: 'file',
        url: 'salesreport'
      },
      {
        id: 'cashendadmin',
        title: 'Cashend Report',
        translate: 'MENU.CASHENDREPORT',
        type: 'item',
        role: ['Admin'],
        icon: 'file',
        url: 'cashendadmin'
      },
      {
        id: 'adminoutstanding',
        title: 'Outstanding Report',
        translate: 'MENU.OUTSTANDINGREPORT',
        type: 'item',
        role: ['Admin'],
        icon: 'file',
        url: 'adminoutstanding'
      },
      {
        id: 'salesproduct',
        title: 'Sales Product',
        translate: 'MENU.SALESPRODUCT',
        type: 'item',
        role: ['Admin'],
        icon: 'file',
        url: 'salesproduct'
      }
    ]
  },
  {
    id: 'report',
    title: 'Report',
    translate: 'MENU.REPORT',
    type: 'collapsible',
    role: ['User'],
    icon: 'file-minus',
    badge: {
      title: '2',
      translate: 'MENU.REPORT',
      classes: 'badge-light-warning badge-pill'
    },
    children: [
      {
        id: 'outstandingreport',
        title: 'Outstanding Report',
        translate: 'MENU.OUTSTANDINGREPORT',
        type: 'item',
        role: ['User'],
        icon: 'file',
        url: 'outstandingreport'
      },
      {
        id: 'cashendreport',
        title: 'Cashend Report',
        translate: 'MENU.CASHENDREPORT',
        type: 'item',
        role: ['User'],
        icon: 'file',
        url: 'cashendreport'
      },
      {
        id: 'salesproduct',
        title: 'Sales Product',
        translate: 'MENU.SALESPRODUCT',
        type: 'item',
        role: ['User'],
        icon: 'file',
        url: 'salesproduct'
      }
    ]
  }
  // {
  //   id: 'sample',
  //   title: 'Sample',
  //   translate: 'MENU.SAMPLE',
  //   type: 'item',
  //   icon: 'file',
  //   url: 'sample'
  // }
]
