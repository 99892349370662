import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class CurrencyService {

  constructor(private _http: HttpClient, private _toastrService: ToastrService) { }

  getData(start, length,user_id): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}currency-list`, { start, length ,user_id})
      .pipe(
        map(data => {
          return data;
        })
      );
  }
  
  add(currency_dollar:any, id: any): Observable<any> {
    if (id == 0) {
      return this._http
        .post(`${environment.apiUrl}save-customer`, { currency_dollar})
        .pipe(
          map(data => {
            return data;
          })
        );
    }
    else {
      return this._http
        .post(`${environment.apiUrl}update-user-currency`, { currency_dollar,id })
        .pipe(
          map(data => {
            return data;
          })
        );
    }
  }
}
