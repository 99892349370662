import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  constructor(private _http: HttpClient, private _toastrService: ToastrService) { }

  getData(start, length,user_id): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}get-customer`, { start, length,user_id })
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  add(name:any,mobile:any,email:any,address:any, id: any,user_id:any): Observable<any> {
    if (id == 0) {
      return this._http
        .post(`${environment.apiUrl}save-customer`, { name, mobile, email, address,user_id})
        .pipe(
          map(data => {
            return data;
          })
        );
    }
    else {
      return this._http
        .post(`${environment.apiUrl}update-customer`, { name, mobile, email, address,id })
        .pipe(
          map(data => {
            return data;
          })
        );
    }
  }

  delete(id): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}delete-customer`, { id })
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  fileDownload(user_id: string): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}export-customer`, {user_id})
      .pipe(
        map(data => {
          return data;
        })
      );
  }
}
