import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class OrderAddService {

  constructor(private _http: HttpClient) { }

  edit(id: string): Observable<any> {
    return this._http.post(`${environment.apiUrl}edit-order`, {id});
  }

  delete(id: string): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}delete-metaorder`, { id })
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  add(order_to:any,consignee_to:any,notify_party:any,date:any,note:any,remark:any, order_no:any,id: any,final_total:any,orderProducts:any): Observable<any> {
    if (id == 0) {
      return this._http
        .post(`${environment.apiUrl}save-order`, { order_to,consignee_to,notify_party,date,note,final_total,remark,order_no,orderProducts})
        .pipe(
          map(data => {
            return data;
          })
        );
    }
    else {
      return this._http
        .post(`${environment.apiUrl}update-order`, { order_to,consignee_to,notify_party,date,note,final_total,remark,order_no,orderProducts, id })
        .pipe(
          map(data => {
            return data;
          })
        );
    }
  }

  getOrderno(): Observable<any> {
    return this._http
      .get(`${environment.apiUrl}get-orderno`)
      .pipe(
        map(data => {
          return data;
        })
      );
  }
}
