import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class SalesProductService {

  constructor(private _http: HttpClient, private _toastrService: ToastrService) { }

  getproductDropDown(user_id): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}product-dropdown-report`, {user_id})
      .pipe(
        map(data => {
          return data;
        })
      );
  }
  getAllData(formdata: any): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}sales-report-product`,formdata)
      .pipe(
        map(data => {
          return data;
        })
      );
  }
  fileDownload(formdata: any): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}export-report-product`,formdata)
      .pipe(
        map(data => {
          return data;
        })
      );
  }
}
