import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import Swal from "sweetalert2";
import { ToastrService } from "ngx-toastr";
import { AuthenticationService } from "app/auth/service";
import { Router, ActivatedRoute } from "@angular/router";
import { CoreSidebarService } from "@core/components/core-sidebar/core-sidebar.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { SalespaymentService } from 'app/_services/salespayment.service';
@Component({
  selector: 'app-salespayment',
  templateUrl: './salespayment.component.html',
  styleUrls: ['./salespayment.component.scss']
})
export class SalespaymentComponent implements OnInit {

  public salpaymentList: any = [];
  public salpaymentListtotal: any = [];
  public start: any = 0;
  public page: any = 0;

  public sidebarToggleRef = false;
  public contentHeader: object;
  public pageBasicText = 1;
  public collectionSize = 0;
  public lengths = 15;
  public loading = false;
  public progressMainTable: boolean = false;
  public newFormAdd: FormGroup;
  public newForm: FormGroup;
  public submitted = false;
  public isAddMode: boolean = true;
  public loadingFrm: boolean = false;
  public salpaymentId = 0;
  public error = "";
  public customerDropDown: any = [];
  public currentUser: any;
  public tempUser: any;
  public user_id: any = 0;
  constructor(
    private _router: Router,
    private route: ActivatedRoute,
    private salespaymentService: SalespaymentService,
    private _authenticationService: AuthenticationService,
    private _formBuilder: FormBuilder,
    private _toastrService: ToastrService,
    private http: HttpClient,
    private _coreSidebarService: CoreSidebarService
  ) { }

  ngOnInit(): void {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.tempUser = JSON.parse(localStorage.getItem('tempUser'));
    if (this.currentUser.user.id != this.tempUser) {
      this.user_id = this.tempUser;
    } else {
      this.user_id = this.currentUser.user.id;
    }
    this.getcustomerDropDown(this.user_id);

    this.salpaymentsList(this.start, this.lengths, this.user_id);

    this.newFormAdd = this._formBuilder.group({
      customer_id: ["", Validators.required],
      amount: ["", Validators.required],
      date: ["", Validators.required],
      remark: [""],
    });

    this.newForm = this._formBuilder.group({
      customer_id: [null],
      start_date: [''],
      end_date: ['']
    });
  }
  getcustomerDropDown(my_user_id: any) {
    this.salespaymentService.getcustomerDropDown(my_user_id).subscribe(
      data => {
        if (data.status) {
          this.customerDropDown = data.customer;
        }
      });
  }

  get fr() {
    return this.newForm.controls;
  }

  get f() {
    return this.newFormAdd.controls;
  }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.newFormAdd.invalid) {
      this.newFormAdd.markAllAsTouched();
    } else {
      this.loadingFrm = true;
      this.error = "";
      const { customer_id, amount, date, remark } = this.newFormAdd.controls;

      this.salespaymentService.add(
        customer_id.value,
        amount.value,
        date.value,
        remark.value,
        this.salpaymentId,
        this.user_id
      ).subscribe(
        (data) => {
          this.loadingFrm = false;
          if (data.status) {
            this.salpaymentId = 0;
            this.submitted = false;
            this.newFormAdd.reset();
            this.toggleSidebar("new-sidebar");
            this._toastrService.success(data.message, "Success!", {
              toastClass: "toast ngx-toastr",
              closeButton: true,
            });
            this.reloadList();
          } else {
            this.error = data.message;
          }
        },
        (err) => {
          if (err.error.error == "Unauthenticated.") {
            this._authenticationService.logout();
            this._router.navigate(["/login"]);
          }
        }
      );
    }
  }

  toggleSidebar(name, type: any = 0): void {
    if (type == 0) {
      this.salpaymentId = 0;
    }
    this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
  }

  salpaymentsList(my_start: any, my_lengths: any, my_user_id: any) {
    const formdata = new FormData();
    formdata.append('start', my_start);
    formdata.append('length', my_lengths);
    formdata.append('user_id', my_user_id);

    this.salespaymentService.getData(formdata).subscribe(
      (response) => {
        this.loading = false;
        this.progressMainTable = false;
        if (response.status) {
          this.salpaymentList = response.data;
          this.salpaymentListtotal = response.total;
          this.collectionSize = response.recordsTotal;
        }
      },
      (err) => {
        if (err.error.error == "Unauthenticated.") {
          this._authenticationService.logout();
          this._router.navigate(["/login"]);
        }
      }
    );
  }

  salpaymentsListNew(my_start: any, my_lengths: any, type: any = 0) {
    const formdata = new FormData();
    formdata.append('user_id', this.user_id);

    if (type != 1) {
      formdata.append('start', my_start);
    }
    else {
      formdata.append('start', '0');
      this.page = 0;
      this.pageBasicText = 1;
    }
    formdata.append('length', my_lengths);
    if (type != 1) {
      if (this.fr.start_date.value !== null) {
        formdata.append('start_date', this.fr.start_date.value);
      }
      if (this.fr.end_date.value !== null) {
        formdata.append('end_date', this.fr.end_date.value);
      }
      if (this.fr.customer_id.value !== null) {
        formdata.append('customer_id', this.fr.customer_id.value);
      }
    }

    this.salespaymentService.getData(formdata).subscribe(
      (response) => {
        this.loading = false;
        this.progressMainTable = false;
        if (response.status) {
          this.salpaymentList = response.data;
          this.salpaymentListtotal = response.total;
          this.collectionSize = response.recordsTotal;
        }
      },
      (err) => {
        if (err.error.error == "Unauthenticated.") {
          this._authenticationService.logout();
          this._router.navigate(["/login"]);
        }
      }
    );
  }

  reloadList(type: any = 0) {
    this.progressMainTable = true;
    this.salpaymentsListNew(this.page, this.lengths, type);
  }

  loadPage(event: any) {
    this.progressMainTable = true;
    this.page = this.lengths * (event - 1);
    this.salpaymentsListNew(this.page, this.lengths);
  }
  loadPageLenghs(event: any) {
    this.progressMainTable = true;
    this.lengths = event.target.value;
    this.salpaymentsListNew(this.start, this.lengths);
  }

  edit(index: any) {
    this.salpaymentId = this.salpaymentList[index].id;
    this.f.customer_id.setValue(this.salpaymentList[index].customer_id);
    this.f.amount.setValue(this.salpaymentList[index].amount);
    this.f.date.setValue(this.salpaymentList[index].edate);
    this.f.remark.setValue(this.salpaymentList[index].remark);
    this.toggleSidebar("new-sidebar", 1);
  }

  delete(id: any) {
    const me = this;
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#7367F0",
      cancelButtonColor: "#E42728",
      confirmButtonText: "Yes, delete it!",
      customClass: {
        confirmButton: "btn btn-primary",
        cancelButton: "btn btn-danger ml-1",
      },
    }).then(function (result) {
      if (result.value) {
        me.salespaymentService.delete(id).subscribe(
          (response) => {
            if (response.status) {
              me.progressMainTable = true;
              me.reloadList();
              Swal.fire({
                icon: "success",
                title: "Deleted!",
                text: "Sales Payment Deleted Successfully",
                customClass: {
                  confirmButton: "btn btn-success",
                },
              });
            }
          },
          (err) => {
            if (err.error.error == "Unauthenticated.") {
              this._authenticationService.logout();
              this._router.navigate(["/login"]);
            }
          }
        );
      }
    });
  }

  onSubmitFilter() {
    this.submitted = true;
    this.loading = true;
    // this.toggleSidebar('new-sidebar-filter');
    this.salpaymentList = [];
    this.salpaymentListtotal = [];
    // stop here if form is invalid
    if (this.newForm.invalid) {

    }
    else {
      this.page = 0;
      this.pageBasicText = 1;

      const formdata = new FormData();
      formdata.append('start', "0");
      formdata.append('length', "15");
      formdata.append('user_id', this.user_id);

      if (this.fr.start_date.value !== null) {
        formdata.append('start_date', this.fr.start_date.value);
      }
      if (this.fr.end_date.value !== null) {
        formdata.append('end_date', this.fr.end_date.value);
      }
      if (this.fr.customer_id.value !== null) {
        formdata.append('customer_id', this.fr.customer_id.value);
      }

      this.salespaymentService.getData(formdata).subscribe(
        data => {
          this.loading = false;
          if (data.status) {
            this.salpaymentList = data.data;
            this.salpaymentListtotal = data.total;
            this.collectionSize = data.recordsTotal;
          }
        },
        err => {
          if (err.error.error == 'Unauthenticated.') {
            this._authenticationService.logout();
            this._router.navigate(['/login']);
          }
        }
      );
    }
  }

  resetReport() {
    this.newForm.reset();
    this.onSubmitFilter();
  }

  downloadExcelData() {
    const formdata = new FormData();

    if (this.fr.start_date.value !== null) {
      formdata.append('start_date', this.fr.start_date.value);
    }
    if (this.fr.end_date.value !== null) {
      formdata.append('end_date', this.fr.end_date.value);
    }
    if (this.fr.customer_id.value !== null) {
      formdata.append('customer_id', this.fr.customer_id.value);
    }
    this.salespaymentService.fileDownload(formdata).subscribe(
      (data) => {
        window.location.href = data.url;
      },
      (error) => {
        this._toastrService.error('Something went wrong! Please Try Againg...', 'Opps!', { toastClass: 'toast ngx-toastr', closeButton: true });
      }
    );
  }
}
