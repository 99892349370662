import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { JwtInterceptorProviders } from './auth/helpers/jwt.interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';

import 'hammerjs';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { ToastrModule } from 'ngx-toastr'; // For auth after login toast

import { CoreModule } from '@core/core.module';
import { CoreCommonModule } from '@core/common.module';
import { CoreSidebarModule, CoreThemeCustomizerModule } from '@core/components';

import { coreConfig } from 'app/app-config';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';

import { AppComponent } from 'app/app.component';
import { LayoutModule } from 'app/layout/layout.module';
import { SampleModule } from 'app/main/sample/sample.module';
import { NgSelectModule } from '@ng-select/ng-select';

import { AppRoutingModule } from './app-routing.module';
import { ProductComponent } from './component/product/product.component';
import { VendorComponent } from './component/vendor/vendor.component';
import { CustomerComponent } from './component/customer/customer.component';
import { PurchaseComponent } from './component/purchase/purchase.component';
import { PurchaseAddComponent } from './component/purchase-add/purchase-add.component';
import { StockComponent } from './component/stock/stock.component';
import { PurchasepaymentComponent } from './component/purchasepayment/purchasepayment.component';
import { UserComponent } from './component/user/user.component';
import { SalesComponent } from './component/sales/sales.component';
import { SalesAddComponent } from './component/sales-add/sales-add.component';
import { SalespaymentComponent } from './component/salespayment/salespayment.component';
import { StockreceiveComponent } from './component/stockreceive/stockreceive.component';
import { ExpensecategoryComponent } from './component/expensecategory/expensecategory.component';
import { ExpenseComponent } from './component/expense/expense.component';
import { ExpenseReportComponent } from './component/expense-report/expense-report.component';
import { OutstandingReportComponent } from './component/outstanding-report/outstanding-report.component';
import { PurchasePaymentReportComponent } from './component/purchase-payment-report/purchase-payment-report.component';
import { SalesReportComponent } from './component/sales-report/sales-report.component';
import { IntransitComponent } from './component/intransit/intransit.component';
import { CashendComponent } from './component/cashend/cashend.component';
import { CurrencyComponent } from './component/currency/currency.component';
import { ManageStockComponent } from './component/manage-stock/manage-stock.component';
import { AdminoutstandingComponent } from './component/adminoutstanding/adminoutstanding.component';
import { CashendadminComponent } from './component/cashendadmin/cashendadmin.component';
import { PurchaseReceiveComponent } from './component/purchase-receive/purchase-receive.component';
import { StockTransferComponent } from './component/stock-transfer/stock-transfer.component';
import { StockTransferAddComponent } from './component/stock-transfer-add/stock-transfer-add.component';
import { PaymentComponent } from './component/payment/payment.component';
import { ExpenseReportAdminComponent } from './component/expense-report-admin/expense-report-admin.component';
import { SubStockTransferComponent } from './component/sub-stock-transfer/sub-stock-transfer.component';
import { SubStockTransferAddComponent } from './component/sub-stock-transfer-add/sub-stock-transfer-add.component';
import { InportComponent } from './component/inport/inport.component';
import { SalesProductComponent } from './component/sales-product/sales-product.component';
import { OrderComponent } from './component/order/order.component';
import { OrderAddComponent } from './component/order-add/order-add.component';
import { CompanyComponent } from './component/company/company.component';
import { NgApexchartsModule } from 'ng-apexcharts';


@NgModule({
  declarations: [AppComponent, ProductComponent, VendorComponent, CustomerComponent, PurchaseComponent, PurchaseAddComponent, StockComponent, PurchasepaymentComponent, UserComponent, SalesComponent, SalesAddComponent, SalespaymentComponent, StockreceiveComponent, ExpensecategoryComponent, ExpenseComponent, ExpenseReportComponent, OutstandingReportComponent, PurchasePaymentReportComponent, SalesReportComponent, IntransitComponent, CashendComponent, CurrencyComponent, ManageStockComponent, AdminoutstandingComponent, CashendadminComponent, PurchaseReceiveComponent, StockTransferComponent, StockTransferAddComponent, PaymentComponent, ExpenseReportAdminComponent, SubStockTransferComponent, SubStockTransferAddComponent, InportComponent, SalesProductComponent, OrderComponent, OrderAddComponent, CompanyComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    TranslateModule.forRoot(),

    //NgBootstrap
    NgbModule,
    ToastrModule.forRoot(),
    NgSelectModule,

    // Core modules
    CoreModule.forRoot(coreConfig),
    CoreCommonModule,
    CoreSidebarModule,
    CoreThemeCustomizerModule,

    // App modules
    LayoutModule,
    SampleModule,
    AutocompleteLibModule
  ],
  
  providers: [JwtInterceptorProviders],
  bootstrap: [AppComponent]
})
export class AppModule {}
