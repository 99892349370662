import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import Swal from "sweetalert2";
import { ToastrService } from "ngx-toastr";
import { AuthenticationService } from "app/auth/service";
import { Router, ActivatedRoute } from "@angular/router";
import { CoreSidebarService } from "@core/components/core-sidebar/core-sidebar.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { SalesReportService } from 'app/_services/sales-report.service';
import { CashendadminService } from 'app/_services/cashendadmin.service';

@Component({
  selector: 'app-cashendadmin',
  templateUrl: './cashendadmin.component.html',
  styleUrls: ['./cashendadmin.component.scss']
})
export class CashendadminComponent implements OnInit {

  public cashList: any = [];
  public cashadminList: any = [];
  public start: any = 0;
  public page: any = 0;

  public sidebarToggleRef = false;
  public contentHeader: object;
  public pageBasicText = 1;
  public collectionSize = 0;
  public lengths = 30;
  public loading = false;
  public progressMainTable: boolean = false;
  public newFormAdd: FormGroup;
  public submitted = false;
  public loadingFrm: boolean = false;
  public categoryId = 0;
  public error = "";
  public currentUser: any;
  public userDropDown: any = [];

  constructor(
    private _router: Router,
    private route: ActivatedRoute,
    private cashendadminService: CashendadminService,
    private salesReportService: SalesReportService,
    private _authenticationService: AuthenticationService,
    private _formBuilder: FormBuilder,
    private _toastrService: ToastrService,
    private http: HttpClient,
    private _coreSidebarService: CoreSidebarService
  ) { }

  ngOnInit(): void {
    this.cashsadminList(1);
    this.getuserDropDown();
    this.newFormAdd = this._formBuilder.group({
      start_date: [''],
      end_date: [''],
      user_id: [null],
    });
  }


  get f() {
    return this.newFormAdd.controls;
  }

  getuserDropDown() {
    this.salesReportService.getuserDropDown().subscribe(
      data => {
        if (data.status) {
          this.userDropDown = data.user;
        }
      });
  }
  cashsadminList(type: any = 1) {
    const formdata = new FormData();
    if (type != 1) {
      if (this.f.start_date.value !== null) {
        formdata.append('start_date', this.f.start_date.value);
      }
      if (this.f.end_date.value !== null) {
        formdata.append('end_date', this.f.end_date.value);
      }

      if (this.f.user_id.value != "") {
        formdata.append('user_id', this.f.user_id.value);
      }
    }
    this.cashendadminService.getadminData(formdata).subscribe(
      (response) => {
        this.loading = false;
        this.progressMainTable = false;
        if (response.status) {
          this.cashadminList = response.data;
          this.collectionSize = response.recordsTotal;
        }
      },
      (err) => {
        if (err.error.error == "Unauthenticated.") {
          this._authenticationService.logout();
          this._router.navigate(["/login"]);
        }
      }
    );
  }

  onSubmit() {
    this.submitted = true;
    this.loading = true;
    // this.toggleSidebar('new-sidebar');
    this.cashadminList = [];
    // stop here if form is invalid
    if (this.newFormAdd.invalid) {

    }
    else {
      const formdata = new FormData();
      if (this.f.start_date.value !== null) {
        formdata.append('start_date', this.f.start_date.value);
      }
      if (this.f.end_date.value !== null) {
        formdata.append('end_date', this.f.end_date.value);
      }
      if (this.f.user_id.value != "") {
        formdata.append('user_id', this.f.user_id.value);
      }

      this.cashendadminService.getadminData(formdata).subscribe(
        data => {
          this.loading = false;
          if (data.status) {
            this.cashadminList = data.data;
          }
        },
        err => {
          if (err.error.error == 'Unauthenticated.') {
            this._authenticationService.logout();
            this._router.navigate(['/login']);
          }
        }
      );
    }
  }
  toggleSidebar(name): void {
    this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
  }
  resetReport()
  {
    this.newFormAdd.reset();
    this.onSubmit();
  }
  getSum(index: any): number {
    let sum = 0;
    for (let i = 0; i < this.cashadminList.length; i++) {
      sum += parseFloat(this.cashadminList[i][index]);
    }
    return sum;
  }

  downloadExcelData() {
    const formdata = new FormData();
    if (this.f.start_date.value !== null) {
      formdata.append('start_date', this.f.start_date.value);
    }
    if (this.f.end_date.value !== null) {
      formdata.append('end_date', this.f.end_date.value);
    }
    if (this.f.user_id.value !== null) {
      formdata.append('user_id', this.f.user_id.value);
    }

    this.cashendadminService.fileDownload(formdata).subscribe(
      (data) => {
        window.location.href = data.url;
      },
      (error) => {
        this._toastrService.error('Something went wrong! Please Try Againg...', 'Opps!', { toastClass: 'toast ngx-toastr', closeButton: true });
      }
    );
  }
}
