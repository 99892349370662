import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import Swal from "sweetalert2";
import { ToastrService } from "ngx-toastr";
import { AuthenticationService } from "app/auth/service";
import { Router, ActivatedRoute } from "@angular/router";
import { CoreSidebarService } from "@core/components/core-sidebar/core-sidebar.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { InportService } from 'app/_services/inport.service';
import { PurchaseService } from 'app/_services/purchase.service';

@Component({
  selector: 'app-inport',
  templateUrl: './inport.component.html',
  styleUrls: ['./inport.component.scss']
})
export class InportComponent implements OnInit {
  public inportList: any = [];
  public start: any = 0;
  public page: any = 0;

  public pageBasicText = 1;
  public collectionSize = 0;
  public lengths = 15;
  public loading = false;
  public progressMainTable: boolean = false;
  public isModelLoading: Boolean =true;
  public purchaseDetails:any;
  StatusLable: any;

  public newFormAdd: FormGroup;
  public newFormFilter: FormGroup;
  public submitted = false;
  public purchaseId: any = 0;
  public type: any = 0;
  public error = "";
  public loadingFrm: boolean = false;
  public file: any = '';
  public vendorDropDown: any = [];
  public userDropDown: any = [];
  
  constructor(
    private _router: Router,
    private route: ActivatedRoute,
    private inportService: InportService,
    private PurchaseService: PurchaseService,
    private _authenticationService: AuthenticationService,
    private _formBuilder: FormBuilder,
    private _toastrService: ToastrService,
    private http: HttpClient,
    private modalService: NgbModal,
    private _coreSidebarService: CoreSidebarService
  ) { }

  ngOnInit(): void {
    this.getvendorDropDown();
    this.getuserDropDown();

    this.loading = true;
    this.inportsList(this.start, this.lengths);
  
    this.newFormAdd = this._formBuilder.group({
      file: ["", Validators.required]
    });

    this.newFormFilter = this._formBuilder.group({
      start_date: [''],
      end_date: [''],
      vehicle_details: [''],
      vendor_id: [null],
      user_id: [null],
    });
  }
  getvendorDropDown() {
    this.PurchaseService.getvendorDropDown().subscribe(
      data => {
        if (data.status) {
          this.vendorDropDown = data.vendor;
        }
      });
  }
  getuserDropDown() {
    this.PurchaseService.getuserDropDown().subscribe(
      data => {
        if (data.status) {
          this.userDropDown = data.user;
        }
      });
  }

  get f() {
    return this.newFormAdd.controls;
  }

  get fr() {
    return this.newFormFilter.controls;
  }

  inportsList(my_start: any, my_lengths: any) {
    const formdata = new FormData();
    formdata.append('start', my_start);
    formdata.append('length', my_lengths);

    this.inportService.getData(formdata).subscribe(
      (response) => {
        this.loading = false;
        this.progressMainTable = false;
        if (response.status) {
          this.inportList = response.data;
          this.collectionSize = response.recordsTotal;
        }
      },
      (err) => {
        if (err.error.error == "Unauthenticated.") {
          this._authenticationService.logout();
          this._router.navigate(["/login"]);
        }
      }
    );
  }

  inportsListNew(my_start: any, my_lengths: any,type: any = 0) {
    const formdata = new FormData();
    if (type != 1) {
      formdata.append('start', my_start);
    }
    else {
      formdata.append('start', '0');
      this.page = 0;
      this.pageBasicText = 1;
    }
    formdata.append('length', my_lengths);
    if (type != 1) {
      if (this.fr.user_id.value !== null) {
        formdata.append('user_id', this.fr.user_id.value);
      }
      if (this.fr.vendor_id.value !== null) {
        formdata.append('vendor_id', this.fr.vendor_id.value);
      }
      if (this.fr.start_date.value !== null) {
        formdata.append('start_date', this.fr.start_date.value);
      }
      if (this.fr.end_date.value !== null) {
        formdata.append('end_date', this.fr.end_date.value);
      }
      if (this.fr.vehicle_details.value !== null) {
        formdata.append('vehicle_details', this.fr.vehicle_details.value);
      }
    }

    this.inportService.getData(formdata).subscribe(
      (response) => {
        this.loading = false;
        this.progressMainTable = false;
        if (response.status) {
          this.inportList = response.data;
          this.collectionSize = response.recordsTotal;
        }
      },
      (err) => {
        if (err.error.error == "Unauthenticated.") {
          this._authenticationService.logout();
          this._router.navigate(["/login"]);
        }
      }
    );
  }

  reloadList(type: any = 0) {
    this.progressMainTable = true;
    this.inportsListNew(this.page, this.lengths, type);
  }

  loadPage(event: any) {
    this.progressMainTable = true;
    this.page = this.lengths * (event - 1);
    this.inportsListNew(this.page, this.lengths);
  }
  loadPageLenghs(event: any) {
    this.progressMainTable = true;
    this.lengths = event.target.value;
    this.inportsListNew(this.start, this.lengths);
  }

  onSubmitFilter(){
    this.submitted = true;
    this.loading = true;
    // this.toggleSidebar('filter-sidebar');
    this.inportList = [];
    // stop here if form is invalid
    if (this.newFormFilter.invalid) {
      
    }
    else {
      this.page = 0;      
      this.pageBasicText = 1;
      
      const formdata = new FormData();
      formdata.append('start', "0");
      formdata.append('length', "15");
      
      if (this.fr.vendor_id.value !== null) {
        formdata.append('vendor_id', this.fr.vendor_id.value);
      }
      if (this.fr.user_id.value !== null) {
        formdata.append('user_id', this.fr.user_id.value);
      }
      if (this.fr.start_date.value !== null) {
        formdata.append('start_date', this.fr.start_date.value);
      }
      if (this.fr.end_date.value !== null) {
        formdata.append('end_date', this.fr.end_date.value);
      }
      if (this.fr.vehicle_details.value !== null) {
        formdata.append('vehicle_details', this.fr.vehicle_details.value);
      }
      
      this.inportService.getData(formdata).subscribe(
        data => {
          this.loading = false;
          if (data.status) {
            this.inportList = data.data;
            this.collectionSize = data.recordsTotal;
          }
        },
        err => {
          if (err.error.error == 'Unauthenticated.') {
            this._authenticationService.logout();
            this._router.navigate(['/login']);
          }
        }
      );
    }
  }

  resetReport() {
    this.newFormFilter.reset();
    this.onSubmitFilter();
  }

  toggleSidebar(name, id: number = 0, index: any = null): void {
    this.purchaseId = id;
    this.type = index;
    this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
  }

  uploadFile(event: any, type: any) {
    if (type == 1) {
      this.file = event.target.files[0];
    }
  }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.newFormAdd.invalid) {
      console.log('error');
    }
    else {
      this.loadingFrm = true;
      const formdata = new FormData();
      formdata.append('file', this.file);
      formdata.append('id', this.purchaseId);
      formdata.append('type', this.type);
     
      this.PurchaseService.addfile(formdata).subscribe(
        data => {
          this.loadingFrm = false;
          if (data.status) {
            this.submitted = false;
            this.toggleSidebar('new-sidebar');
            this.newFormAdd.reset();
            this._toastrService.clear();
            this._toastrService.success(data.message, 'Success!', { toastClass: 'toast ngx-toastr', closeButton: true });
            this.progressMainTable = true;
            this.inportsList(this.page, this.lengths);
          }
          else {
            this.error = data.error;
          }
        },
        err => {
          if (err.error.error == 'Unauthenticated.') {
            this._authenticationService.logout();
            this._router.navigate(['/login']);
          }
        });
    }
  }
}
