import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { ToastrService } from 'ngx-toastr';
@Injectable({
  providedIn: 'root'
})
export class AdminoutstandingService {

  constructor(private _http: HttpClient, private _toastrService: ToastrService) { }

  getData(formdata: any): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}admin-outstanding-report`,formdata)
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  getmeta(formdata: any): Observable<any> {
    return this._http.post(`${environment.apiUrl}admin-outstanding-report-details`, formdata);
  }

  fileDownload(formdata: any): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}export-outstanding-report-admin`,formdata)
      .pipe(
        map(data => {
          return data;
        })
      );
  }
  fileDownloadCustomer(formdata: any): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}export-outstanding-report-admincustomer`,formdata)
      .pipe(
        map(data => {
          return data;
        })
      );
  }
}
