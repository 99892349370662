import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class PurchasePaymentReportService {

  constructor(private _http: HttpClient, private _toastrService: ToastrService) { }

  vendorSearch(searchText: string): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}vendor-search`, { searchText })
      .pipe(
        map(data => {
          return data;
        })
      );
  }
  loadReport(id): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}purchasepayment-report`, { id })
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  fileDownload(): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}export-payment`, {})
      .pipe(
        map(data => {
          return data;
        })
      );
  }
}
