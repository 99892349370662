<div class="content-wrapper container-xxl p-0">
    <div class="content-body">
        <section id="home-page">
            <div class="row">
                <div class="col-12">
                    <div class="card">
                        <div class="card-header">
                            <h4 class="card-title">Payment List</h4>
                        </div>
                        <form class="add-new modal-content pt-0" [formGroup]="newFormfilter" (ngSubmit)="onSubmitFilter()">
                            <div class="modal-body flex-grow-1 ">
                                <div class="row">
                                    <div class="col-lg-3">
                                        <div class="form-group">
                                            <label>Select User<span class="text-danger">*</span></label>
                                            <ng-select formControlName="user_id" aria-describedby="register-user_id">
                                                <ng-option disabled selected value="0">Select User</ng-option>
                                                <ng-option *ngFor="let cust of userDropDown"
                                                    [value]="cust.id">{{cust.name}}</ng-option>
                                            </ng-select>
                                        </div>
                                    </div>
                                    <div class="col-lg-3">
                                        <div class="form-group">
                                            <label>Select Exporter<span class="text-danger">*</span></label>
                                            <ng-select formControlName="vendor_id" aria-describedby="register-vendor_id">
                                                <ng-option disabled selected value="0">Select Exporter</ng-option>
                                                <ng-option *ngFor="let cust of vendorDropDown"
                                                    [value]="cust.id">{{cust.name}}</ng-option>
                                            </ng-select>
                                        </div>
                                    </div>
                                    <div class="col-lg-3">
                                        <div class="form-group">
                                            <label class="form-label">From Date</label>
                                            <input type="date" formControlName="start_date" id="start_date"
                                                placeholder="dd-mm-yyyy" class="form-control">
                                        </div>
                                    </div>
                                    <div class="col-lg-3">
                                        <div class="form-group">
                                            <label class="form-label">To Date</label>
                                            <input type="date" formControlName="end_date" id="end_date"
                                                placeholder="dd-mm-yyyy" class="form-control">
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-4">
                                        <div class="form-group">
                                            <button type="submit" class="btn btn-primary mr-1 " rippleEffect
                                                [disabled]="loading ">
                                                <span *ngIf="loading "
                                                    class="spinner-border spinner-border-sm mr-1 "></span>Go</button>
                                            <button type="reset" class="btn btn-primary mr-1 " (click)="resetReport()"
                                                rippleEffect [disabled]="loading ">
                                                <span *ngIf="loading "
                                                    class="spinner-border spinner-border-sm mr-1 "></span>
                                                <i data-feather="refresh-cw"
                                                    class="d-sm-none d-inline-block mr-0 mr-sm-1"></i>
                                                <span class="d-none d-sm-inline-block">Reset</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <div class="card-body p-0 pb-1">
                            <div class="row m-0">
                                <div class="col-md-6 col-6">
                                    <div class="d-flex justify-content-between align-items-center ml-1">
                                        <label class="d-flex align-items-center">Show
                                        <select class="form-control mx-25" [(ngModel)]="lengths" (change)="loadPageLenghs($event)" >
                                            <option value="15">15</option>
                                            <option value="30">30</option>
                                            <option value="50">50</option>
                                            <option value="100">100</option>
                                        </select>
                                      </label>
                                    </div>
                                </div>
                                <div class="col-md-6 col-6 d-flex justify-content-start justify-content-md-end">
                                    <div class="d-flex align-items-center justify-content-end pr-1 pb-1 pb-md-0 w-100">
                                        <button class="btn btn-primary ml-1" rippleEffect (click)="toggleSidebar('new-sidebar')">
                                        <i data-feather="plus" class="d-sm-none d-inline-block mr-0 mr-sm-1"></i
                                        ><span class="d-none d-sm-inline-block">Add New</span>
                                        </button>
                                        
                                    </div>
                                </div>
                            </div>
                            <div class="col p-0 table-responsive">
                                <div class="overlay" *ngIf="progressMainTable">
                                    <div class="spinner-grow" role="status">
                                        <span class="sr-only">Loading...</span>
                                    </div>
                                </div>
                                <table class="table table-striped table-hover valign-middle text-dark">
                                    <thead>
                                        <tr>
                                            <th>#</th>
                                            <th>Name</th>
                                            <th>Exporter</th>
                                            <th style="width: 110px;">Date</th>
                                            <th>Local Currency</th>
                                            <th>Foreign Currency</th>
                                            <th>Remark</th>
                                            <th>Action</th>
                                            <th>Document</th>
                                        </tr>
                                    </thead>

                                    <tbody *ngIf="paymentList?.length != 0">
                                        <tr *ngFor="let ware of paymentList; let ndx = index">
                                            <td>{{ndx+page+1}}</td>
                                            <td>{{ ware.name }}</td>
                                            <td>{{ ware.vendor_name }}</td>
                                            <td>{{ ware.date }}</td>
                                            <td>{{ ware.local_currency | number:'1.2-2'}}</td>
                                            <td>{{ ware.foreign_currency | number:'1.2-2'}}</td>
                                            <td>{{ ware.remark }}</td>
                                            <td>
                                                <a (click)="edit(ndx)" class="btn btn-icon btn-sm btn-info mr-1 mb-1" data-toggle="tooltip" data-popup="tooltip-custom" placement="top" container="body" ngbTooltip="Edit">
                                                    <i data-feather="edit-2"></i>
                                                </a>
                                            </td>
                                            <td>
                                                <button class="btn btn-primary ml-1" rippleEffect
                                                    (click)="toggleSidebarfile('new-sidebar-doc',ware.id,1)">
                                                    <i data-feather="plus"
                                                        class="d-sm-none d-inline-block mr-0 mr-sm-1"></i><span
                                                        class="d-none d-sm-inline-block">Add</span>
                                                </button>
                                                <a *ngIf="ware.payment_file != '' " [href]="ware.payment_file" target="_blank"
                                                    rel="noopener noreferrer"><button
                                                        class="btn btn-warning ml-1">view</button></a>
                                            </td>
                                        </tr>
                                        <tr class="total_bg" *ngFor="let ware of paymentListtotal;">
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                            <th>Total</th>
                                            <th>{{ ware.tot_total | number:'1.2-2' }}</th>
                                            <th>{{ ware.tot_dollar | number:'1.2-2' }}</th>
                                            <th></th>
                                            <th></th>
                                            <th></th>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="paymentList?.length == 0">
                                        <tr *ngIf="!loading">
                                            <td colspan="9" class="no-data-available">No Data!</td>
                                        </tr>
                                        <tr>
                                            <td colspan="9" class="no-data-available">
                                                <div class="col" *ngIf="loading">
                                                    <div class="text-center">
                                                        <div class="spinner-border" role="status">
                                                            <span class="sr-only">Loading...</span>
                                                        </div>
                                                        <div>
                                                            Please Wait...
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <ngb-pagination class="d-flex justify-content-end mt-2 mr-2" *ngIf="collectionSize > 0" [collectionSize]="collectionSize" [pageSize]="lengths" [(page)]="pageBasicText" (pageChange)="loadPage($event)" aria-label="Custom pagination">
                                    <ng-template ngbPaginationPrevious><span [data-feather]="'chevron-left'" [class]="'font-weight-bolder'"></span>Prev
                                    </ng-template>
                                    <ng-template ngbPaginationNext>Next<span [data-feather]="'chevron-right'" [class]="'font-weight-bolder'"></span>
                                    </ng-template>
                                </ngb-pagination>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>

<!-- New User Sidebar -->
<core-sidebar class="modal modal-slide-in sidebar-todo-modal fade" name="new-sidebar" overlayClass="modal-backdrop">
    <div class="slideout-content">
        <!-- Modal to add new starts-->
        <div class="modalsd modal-slide-in sdfade new-modal" id="modals-slide-in">
            <div class="modal-dialog">
                <form class="add-new modal-content pt-0" [formGroup]="newFormAdd" (ngSubmit)="onSubmit()">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="toggleSidebar('new-sidebar')">
                      ×
                    </button>
                    <div class="modal-header mb-1">
                        <h5 class="modal-title" id="exampleModalLabel">Add Payment</h5>
                    </div>
                    <div class="modal-body flex-grow-1 ">
                        <div class="row m-0">
                            <div class="col">
                                <div class="form-group">
                                    <label for="register-date" class="form-label">Date<span class="text-danger">*</span> </label>
                                    <input type="date" formControlName="date" class="form-control" placeholder="Enter Date" aria-describedby="register-date" [ngClass]="{ 'is-invalid': submitted && f.date.errors }" />
                                    <div *ngIf="submitted && f.date.errors" class="invalid-feedback">
                                        <div *ngIf="f.date.errors.required">Date is required</div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="register-user_id" class="form-label">User<span class="text-danger">*</span> </label>
                                    <ng-select formControlName="user_id" aria-describedby="register-user_id" [ngClass]="{ 'is-invalid': submitted && f.user_id.errors }">
                                        <ng-option disabled selected value="0">Select User</ng-option>
                                        <ng-option *ngFor="let ven of userDropDown" [value]="ven.id">{{ven.name}}</ng-option>
                                    </ng-select>
                                    <div *ngIf="submitted && f.user_id.errors" class="invalid-feedback">
                                        <div *ngIf="f.user_id.errors.required">User is required</div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="register-vendor_id" class="form-label">Exporter</label>
                                    <ng-select formControlName="vendor_id" aria-describedby="register-vendor_id" [ngClass]="{ 'is-invalid': submitted && f.vendor_id.errors }">
                                        <ng-option disabled selected value="0">Select Exporter</ng-option>
                                        <ng-option *ngFor="let ven of vendorDropDown" [value]="ven.id">{{ven.name}}</ng-option>
                                    </ng-select>
                                    <div *ngIf="submitted && f.vendor_id.errors" class="invalid-feedback">
                                        <div *ngIf="f.vendor_id.errors.required">User is required</div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="register-local_currency" class="form-label">Local currency<span class="text-danger">*</span> </label>
                                    <input type="number" formControlName="local_currency" class="form-control" placeholder="Enter Local currency" aria-describedby="register-local_currency" [ngClass]="{ 'is-invalid': submitted && f.local_currency.errors }" />
                                    <div *ngIf="submitted && f.local_currency.errors" class="invalid-feedback">
                                        <div *ngIf="f.local_currency.errors.required">Local currency is required</div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="register-foreign_currency" class="form-label">Foreign currency<span class="text-danger">*</span> </label>
                                    <input type="number" formControlName="foreign_currency" class="form-control" placeholder="Enter Foreign currency" aria-describedby="register-foreign_currency" [ngClass]="{ 'is-invalid': submitted && f.foreign_currency.errors }" />
                                    <div *ngIf="submitted && f.foreign_currency.errors" class="invalid-feedback">
                                        <div *ngIf="f.foreign_currency.errors.required">Foreign currency is required</div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <label for="register-remark" class="form-label">Remark<span class="text-danger"></span> </label>
                                    <input type="text" formControlName="remark" class="form-control" placeholder="Enter Remark" aria-describedby="register-remark" [ngClass]="{ 'is-invalid': submitted && f.remark.errors }" />
                                    <div *ngIf="submitted && f.remark.errors" class="invalid-feedback">
                                        <div *ngIf="f.remark.errors.required">Remark is required</div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="col-lg-12 p-0">
                                        <ngb-alert [type]="'danger'" [dismissible]="false" *ngIf="error">
                                            <div class="alert-body">
                                                <p>{{ error }}</p>
                                            </div>
                                        </ngb-alert>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="col-lg-12 p-0">
                                        <button type="reset" class="btn btn-secondary" (click)="toggleSidebar('new-sidebar')" [disabled]="loadingFrm" rippleEffect>
                                            <span *ngIf="loadingFrm" class="spinner-border spinner-border-sm mr-1"></span>
                                            <i data-feather="x" class="d-sm-none d-inline-block mr-0 mr-sm-1"></i>
                                            <span class="d-none d-sm-inline-block">Cancel </span>                                    
                                        </button>
                                        <button type="submit" class="btn btn-primary ml-1 " rippleEffect [disabled]="loadingFrm"> <span *ngIf="loadingFrm" class="spinner-border spinner-border-sm mr-1"></span>Submit</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <!-- Modal to add new Ends-->
    </div>
</core-sidebar>
<!--/ New User Sidebar -->

<!-- New User Sidebar document -->
<core-sidebar class="modal modal-slide-in sidebar-todo-modal fade" name="new-sidebar-doc" overlayClass="modal-backdrop">
    <div class="slideout-content">
        <!-- Modal to add new starts-->
        <div class="modalsd modal-slide-in sdfade new-modal" id="modals-slide-in">
            <div class="modal-dialog">
                <form class="add-new modal-content pt-0" [formGroup]="newForm" (ngSubmit)="onSubmitfile()">
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close"
                        (click)="toggleSidebarfile('new-sidebar-doc')">
                        ×
                    </button>
                    <div class="modal-header mb-1">
                        <h5 class="modal-title" id="exampleModalLabel">Add File</h5>
                    </div>
                    <div class="modal-body flex-grow-1 ">
                        <div class="row m-0">
                            <div class="col">
                                <div class="form-group">
                                    <label for="register-file" class="form-label">File<span class="text-danger">*</span>
                                    </label>
                                    <input type="file" formControlName="file" class="form-control"
                                        placeholder="Select File" (change)="uploadFile($event,1)"
                                        aria-describedby="register-file"
                                        [ngClass]="{ 'is-invalid': submitted && fr.file.errors }" />
                                    <div *ngIf="submitted && fr.file.errors" class="invalid-feedback">
                                        <div *ngIf="fr.file.errors.required">File is required</div>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="col-lg-12 p-0">
                                        <ngb-alert [type]="'danger'" [dismissible]="false" *ngIf="error">
                                            <div class="alert-body">
                                                <p>{{ error }}</p>
                                            </div>
                                        </ngb-alert>
                                    </div>
                                </div>
                                <div class="form-group">
                                    <div class="col-lg-12 p-0">
                                        <button type="reset" class="btn btn-secondary"
                                            (click)="toggleSidebarfile('new-sidebar-doc')" [disabled]="loadingFrm" rippleEffect>
                                            <span *ngIf="loadingFrm"
                                                class="spinner-border spinner-border-sm mr-1"></span>
                                            <i data-feather="x" class="d-sm-none d-inline-block mr-0 mr-sm-1"></i>
                                            <span class="d-none d-sm-inline-block">Cancel </span>
                                        </button>
                                        <button type="submit" class="btn btn-primary ml-1 " rippleEffect
                                            [disabled]="loadingFrm"> <span *ngIf="loadingFrm"
                                                class="spinner-border spinner-border-sm mr-1"></span>Submit</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <!-- Modal to add new Ends-->
    </div>
</core-sidebar>
<!--/ New User Sidebar document-->