import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import Swal from "sweetalert2";
import { ToastrService } from "ngx-toastr";
import { AuthenticationService } from "app/auth/service";
import { Router, ActivatedRoute } from "@angular/router";
import { CoreSidebarService } from "@core/components/core-sidebar/core-sidebar.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { StockService } from 'app/_services/stock.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-stock',
  templateUrl: './stock.component.html',
  styleUrls: ['./stock.component.scss']
})
export class StockComponent implements OnInit {

  public stockList: any = [];
  public adminstockList: any = [];
  public start: any = 0;
  public page: any = 0;

  public pageBasicText = 1;
  public collectionSize = 0;
  public admincollectionSize = 0;
  public lengths = 15;
  public loading = false;
  public progressMainTable: boolean = false;
  public currentUser: any;
  public isModelLoading: Boolean =true;
  public adminDetails:any;
  public proHistory:any;
  public singleName:any;

  public tempUser: any;
  public user_id:any = 0;
  constructor(
    private _router: Router,
    private route: ActivatedRoute,
    private stockService: StockService,
    private _authenticationService: AuthenticationService,
    private _formBuilder: FormBuilder,
    private _toastrService: ToastrService,
    private http: HttpClient,
    private modalService: NgbModal,
    private _coreSidebarService: CoreSidebarService
  ) { }

  ngOnInit(): void {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.tempUser = JSON.parse(localStorage.getItem('tempUser'));
    
    if(this.currentUser.user.id != this.tempUser){
      this.user_id = this.tempUser;
    }else{
      this.user_id = this.currentUser.user.id;
    }
    this.stocksList(this.start, this.lengths,this.user_id);
    this.adminstocksList(this.start, this.lengths);
  }

  stocksList(my_start: any, my_lengths: any,my_user_id:any) {
    // this.currentUser = JSON.parse(localStorage.getItem('currentUser'));

    this.stockService.getData(my_start, my_lengths,my_user_id).subscribe(
      (response) => {
        this.loading = false;
        this.progressMainTable = false;
        if (response.status) {
          this.stockList = response.data;
          this.collectionSize = response.recordsTotal;
        }
      },
      (err) => {
        if (err.error.error == "Unauthenticated.") {
          this._authenticationService.logout();
          this._router.navigate(["/login"]);
        }
      }
    );
  }

  adminstocksList(my_start: any, my_lengths: any) {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));

    this.stockService.getadminData(my_start, my_lengths).subscribe(
      (response) => {
        this.loading = false;
        this.progressMainTable = false;
        if (response.status) {
          this.adminstockList = response.data;
          this.admincollectionSize = response.recordsTotal;
        }
      },
      (err) => {
        if (err.error.error == "Unauthenticated.") {
          this._authenticationService.logout();
          this._router.navigate(["/login"]);
        }
      }
    );
  }
  
  reloadList() {
    this.progressMainTable = true;
    this.stocksList(this.page, this.lengths,this.user_id);
    this.adminstocksList(this.page, this.lengths);
  }

  loadPage(event: any) {
    this.progressMainTable = true;
    this.page = this.lengths * (event - 1);
    this.stocksList(this.page, this.lengths,this.user_id);
    this.adminstocksList(this.page, this.lengths);
  }
  loadPageLenghs(event: any) {
    this.progressMainTable = true;
    this.lengths = event.target.value;
    this.stocksList(this.start, this.lengths,this.user_id);
    this.adminstocksList(this.start, this.lengths);
  }

  details(stockname: any,modalSM) {
    this.modalService.open(modalSM, {
      centered: true,
      size: 'xl'
    });
    this.singleName = stockname;
    this.stockService.getmeta(stockname.user_id).subscribe(
      data => {
          this.isModelLoading = false;
          if (data.status) {
              this.adminDetails = data.data;
          }else {
              this._toastrService.error(data.errorMessage, 'Error!');
          }
      },
      err => {
          this._toastrService.error(err.errorMessage, 'Opps!');
      }
    );
  }

  productHistory(id: any,modalSM1) {
    this.modalService.open(modalSM1, {
      centered: true,
      size: 'xl'
    });
    this.stockService.getproductHistory(id,this.user_id).subscribe(
      data => {
          this.isModelLoading = false;
          if (data.status) {
              this.proHistory = data.data;
          }else {
              this._toastrService.error(data.errorMessage, 'Error!');
          }
      },
      err => {
          this._toastrService.error(err.errorMessage, 'Opps!');
      }
    );
  }

  downloadExcelData() {
    this.stockService.fileDownload(this.user_id).subscribe(
      (data) => {
        window.location.href=data.url;
      },
      (error) => {
        this._toastrService.error('Something went wrong! Please Try Againg...', 'Opps!', { toastClass: 'toast ngx-toastr', closeButton: true });
      }
    );
  }
}
