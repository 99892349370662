import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class SalespaymentService {

  constructor(private _http: HttpClient, private _toastrService: ToastrService) { }

  getData(formdata: any): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}get-salespayment`, formdata)
      .pipe(
        map(data => {
          return data;
        })
      );
  }
  
  add(customer_id:any,amount:any,date:any,remark:any, id: any,user_id:any): Observable<any> {
    if (id == 0) {
      return this._http
        .post(`${environment.apiUrl}save-salespayment`, { customer_id, amount, date, remark,user_id})
        .pipe(
          map(data => {
            return data;
          })
        );
    }
    else {
      return this._http
        .post(`${environment.apiUrl}update-salespayment`, { customer_id, amount, date, remark,id,user_id })
        .pipe(
          map(data => {
            return data;
          })
        );
    }
  }

  delete(id): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}delete-salespayment`, { id })
      .pipe(
        map(data => {
          return data;
        })
      );
  }
  getcustomerDropDown(user_id): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}customer-dropdown`, { user_id})
      .pipe(
        map(data => {
          return data;
        })
      );
  }
  fileDownload(formdata: any): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}export-salespayment`,formdata)
      .pipe(
        map(data => {
          return data;
        })
      );
  }
}
