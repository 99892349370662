import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import Swal from "sweetalert2";
import { ToastrService } from "ngx-toastr";
import { AuthenticationService } from "app/auth/service";
import { Router, ActivatedRoute } from "@angular/router";
import { CoreSidebarService } from "@core/components/core-sidebar/core-sidebar.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { CurrencyService } from 'app/_services/currency.service';

@Component({
  selector: 'app-currency',
  templateUrl: './currency.component.html',
  styleUrls: ['./currency.component.scss']
})
export class CurrencyComponent implements OnInit {
  public userList: any = [];
  public start: any = 0;
  public page: any = 0;

  public sidebarToggleRef = false;
  public contentHeader: object;
  public pageBasicText = 1;
  public collectionSize = 0;
  public lengths = 15;
  public loading = false;
  public progressMainTable: boolean = false;
  public newFormAdd: FormGroup;
  public submitted = false;
  public isAddMode: boolean = true;
  public loadingFrm: boolean = false;
  public userId = 0;
  public error = "";
  public currentUser: any;
  public user_id:any = 0;

  constructor(
    private _router: Router,
    private route: ActivatedRoute,
    private currencyService: CurrencyService,
    private _authenticationService: AuthenticationService,
    private _formBuilder: FormBuilder,
    private _toastrService: ToastrService,
    private http: HttpClient,
    private _coreSidebarService: CoreSidebarService
  ) { }

  ngOnInit(): void {
    this.currentUser = JSON.parse(localStorage.getItem('currentUser'));
    this.user_id = this.currentUser.user.id;

    this.currencysList(this.start, this.lengths,this.user_id);
    this.newFormAdd = this._formBuilder.group({
      currency_dollar: ["", Validators.required],
    });
  }
  get f() {
    return this.newFormAdd.controls;
  }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.newFormAdd.invalid) {
      this.newFormAdd.markAllAsTouched();
    } else {
      this.loadingFrm = true;
      this.error = "";
      const { currency_dollar} = this.newFormAdd.controls;
      this.currencyService.add(currency_dollar.value,this.userId
      ).subscribe(
        (data) => {
          this.loadingFrm = false;
          if (data.status) {
            this.userId = 0;
            this.submitted = false;
            this.newFormAdd.reset();
            this.toggleSidebar("new-sidebar");
            this._toastrService.success(data.message, "Success!", {
              toastClass: "toast ngx-toastr",
              closeButton: true,
            });
            this.reloadList();
          } else {
            this.error = data.message;
          }
        },
        (err) => {
          if (err.error.error == "Unauthenticated.") {
            this._authenticationService.logout();
            this._router.navigate(["/login"]);
          }
        }
      );
    }
  }

  toggleSidebar(name, type: any = 0): void {
    if (type == 0) {
      this.userId = 0;
    }
    this._coreSidebarService.getSidebarRegistry(name).toggleOpen();
  }

  currencysList(my_start: any, my_lengths: any,my_user_id:any) {
    this.currencyService.getData(my_start, my_lengths,my_user_id).subscribe(
      (response) => {
        this.loading = false;
        this.progressMainTable = false;
        if (response.status) {
          this.userList = response.data;
          this.collectionSize = response.recordsTotal;
        }
      },
      (err) => {
        if (err.error.error == "Unauthenticated.") {
          this._authenticationService.logout();
          this._router.navigate(["/login"]);
        }
      }
    );
  }

  reloadList() {
    this.progressMainTable = true;
    this.currencysList(this.page, this.lengths,this.user_id);
  }

  loadPage(event: any) {
    this.progressMainTable = true;
    this.page = this.lengths * (event - 1);
    this.currencysList(this.page, this.lengths,this.user_id);
  }
  loadPageLenghs(event: any) {
    this.progressMainTable = true;
    this.lengths = event.target.value;
    this.currencysList(this.start, this.lengths,this.user_id);
  }

  edit(index: any) {
    this.userId = this.userList[index].id;
    this.f.currency_dollar.setValue(this.userList[index].currency_dollar);

    this.toggleSidebar("new-sidebar", 1);
  }
}
