import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class OutstandingReportService {

  constructor(private _http: HttpClient, private _toastrService: ToastrService) { }

  customerSearch(searchText: string): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}customer-search`, { searchText })
      .pipe(
        map(data => {
          return data;
        })
      );
  }
  loadOutStandingReport(id): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}outstanding-report`, { id })
      .pipe(
        map(data => {
          return data;
        })
      );
  }
  loadOutStandingReportnew(formdata: any): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}outstanding-report-new`,formdata)
      .pipe(
        map(data => {
          return data;
        })
      );
  }

  getcustomerDropDown(user_id): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}customer-dropdown`, { user_id})
      .pipe(
        map(data => {
          return data;
        })
      );
  }
  fileDownload(formdata: any): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}export-outstanding-report`,formdata)
      .pipe(
        map(data => {
          return data;
        })
      );
  }
}
