<div class="content-wrapper container-xxl p-0">
  <div class="content-body">
    <!-- content-header component -->
    <!-- <app-content-header [contentHeader]="contentHeader"></app-content-header> -->

    <!-- Basic Alerts start -->
    <section id="home-page" *ngIf="user_id == 100000">
      <div class="row">
        <div class="col-12">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">All Branch</h4>
              <form class="add-new modal-content pt-0" [formGroup]="newFormAdd" (ngSubmit)="onSubmit()">
                <div class="row">
                  <div class="col-lg-1">
                  </div>
                  <div class="col-lg-4">
                    <div class="form-group">
                      <label class="form-label">From Date</label>
                      <input type="date" formControlName="start_date" id="start_date" placeholder="dd-mm-yyyy"
                        class="form-control">
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="form-group">
                      <label class="form-label">To Date</label>
                      <input type="date" formControlName="end_date" id="end_date" placeholder="dd-mm-yyyy"
                        class="form-control">
                    </div>
                  </div>
                  <div class="col-lg-3">
                    <div class="form-group">
                      <button style="margin-top: 23px;" type="submit" class="btn btn-primary mr-1 " rippleEffect [disabled]="loading ">
                        <span *ngIf="loading " class="spinner-border spinner-border-sm mr-1 "></span>Go
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div class="card-body pb-1">
              <div id="chart">
                <apx-chart [series]="allchartOptions.series" [chart]="allchartOptions.chart"
                  [labels]="allchartOptions.labels" [colors]="allchartOptions.colors"
                  [responsive]="allchartOptions.responsive"></apx-chart>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">Senegal</h4>
              <form class="add-new modal-content pt-0" [formGroup]="newFormAdd" (ngSubmit)="onSubmitSenegal()">
                <div class="row">
                  <div class="col-lg-1">
                  </div>
                  <div class="col-lg-4">
                    <div class="form-group">
                      <label class="form-label">From Date</label>
                      <input type="date" formControlName="start_date" id="start_date" placeholder="dd-mm-yyyy"
                        class="form-control">
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="form-group">
                      <label class="form-label">To Date</label>
                      <input type="date" formControlName="end_date" id="end_date" placeholder="dd-mm-yyyy"
                        class="form-control">
                    </div>
                  </div>
                  <div class="col-lg-3">
                    <div class="form-group">
                      <button style="margin-top: 23px;" type="submit" class="btn btn-primary mr-1 " rippleEffect [disabled]="loading ">
                        <span *ngIf="loading " class="spinner-border spinner-border-sm mr-1 "></span>Go
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div class="card-body pb-1">
              <div id="chart">
                <apx-chart [series]="senegalchartOptions.series" [colors]="allchartOptions.colors"
                  [chart]="senegalchartOptions.chart" [labels]="senegalchartOptions.labels"
                  [responsive]="senegalchartOptions.responsive"></apx-chart>
              </div>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">Mali</h4>
              <form class="add-new modal-content pt-0" [formGroup]="newFormAdd" (ngSubmit)="onSubmitMali()">
                <div class="row">
                  <div class="col-lg-1">
                  </div>
                  <div class="col-lg-4">
                    <div class="form-group">
                      <label class="form-label">From Date</label>
                      <input type="date" formControlName="start_date" id="start_date" placeholder="dd-mm-yyyy"
                        class="form-control">
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="form-group">
                      <label class="form-label">To Date</label>
                      <input type="date" formControlName="end_date" id="end_date" placeholder="dd-mm-yyyy"
                        class="form-control">
                    </div>
                  </div>
                  <div class="col-lg-3">
                    <div class="form-group">
                      <button style="margin-top: 23px;" type="submit" class="btn btn-primary mr-1 " rippleEffect [disabled]="loading ">
                        <span *ngIf="loading " class="spinner-border spinner-border-sm mr-1 "></span>Go
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div class="card-body pb-1">
              <div id="chart">
                <apx-chart [series]="malichartOptions.series" [colors]="allchartOptions.colors"
                  [chart]="malichartOptions.chart" [labels]="malichartOptions.labels"
                  [responsive]="malichartOptions.responsive"></apx-chart>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">Conakry</h4>
              <form class="add-new modal-content pt-0" [formGroup]="newFormAdd" (ngSubmit)="onSubmitConakry()">
                <div class="row">
                  <div class="col-lg-1">
                  </div>
                  <div class="col-lg-4">
                    <div class="form-group">
                      <label class="form-label">From Date</label>
                      <input type="date" formControlName="start_date" id="start_date" placeholder="dd-mm-yyyy"
                        class="form-control">
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="form-group">
                      <label class="form-label">To Date</label>
                      <input type="date" formControlName="end_date" id="end_date" placeholder="dd-mm-yyyy"
                        class="form-control">
                    </div>
                  </div>
                  <div class="col-lg-3">
                    <div class="form-group">
                      <button style="margin-top: 23px;" type="submit" class="btn btn-primary mr-1 " rippleEffect [disabled]="loading ">
                        <span *ngIf="loading " class="spinner-border spinner-border-sm mr-1 "></span>Go
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div class="card-body pb-1">
              <div id="chart">
                <apx-chart [series]="conakrychartOptions.series" [chart]="conakrychartOptions.chart"
                  [labels]="conakrychartOptions.labels" [colors]="allchartOptions.colors"
                  [responsive]="conakrychartOptions.responsive"></apx-chart>
              </div>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">Gambia</h4>
              <form class="add-new modal-content pt-0" [formGroup]="newFormAdd" (ngSubmit)="onSubmitGambia()">
                <div class="row">
                  <div class="col-lg-1">
                  </div>
                  <div class="col-lg-4">
                    <div class="form-group">
                      <label class="form-label">From Date</label>
                      <input type="date" formControlName="start_date" id="start_date" placeholder="dd-mm-yyyy"
                        class="form-control">
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="form-group">
                      <label class="form-label">To Date</label>
                      <input type="date" formControlName="end_date" id="end_date" placeholder="dd-mm-yyyy"
                        class="form-control">
                    </div>
                  </div>
                  <div class="col-lg-3">
                    <div class="form-group">
                      <button style="margin-top: 23px;" type="submit" class="btn btn-primary mr-1 " rippleEffect [disabled]="loading ">
                        <span *ngIf="loading " class="spinner-border spinner-border-sm mr-1 "></span>Go
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div class="card-body pb-1">
              <div id="chart">
                <apx-chart [series]="gambiachartOptions.series" [chart]="gambiachartOptions.chart"
                  [labels]="gambiachartOptions.labels" [colors]="allchartOptions.colors"
                  [responsive]="gambiachartOptions.responsive"></apx-chart>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-6">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">Lome Togo</h4>
              <form class="add-new modal-content pt-0" [formGroup]="newFormAdd" (ngSubmit)="onSubmitLome()">
                <div class="row">
                  <div class="col-lg-1">
                  </div>
                  <div class="col-lg-4">
                    <div class="form-group">
                      <label class="form-label">From Date</label>
                      <input type="date" formControlName="start_date" id="start_date" placeholder="dd-mm-yyyy"
                        class="form-control">
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="form-group">
                      <label class="form-label">To Date</label>
                      <input type="date" formControlName="end_date" id="end_date" placeholder="dd-mm-yyyy"
                        class="form-control">
                    </div>
                  </div>
                  <div class="col-lg-3">
                    <div class="form-group">
                      <button style="margin-top: 23px;" type="submit" class="btn btn-primary mr-1 " rippleEffect [disabled]="loading ">
                        <span *ngIf="loading " class="spinner-border spinner-border-sm mr-1 "></span>Go
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div class="card-body pb-1">
              <div id="chart">
                <apx-chart [series]="lometogochartOptions.series" [chart]="lometogochartOptions.chart"
                  [colors]="allchartOptions.colors" [labels]="lometogochartOptions.labels"
                  [responsive]="lometogochartOptions.responsive"></apx-chart>
              </div>
            </div>
          </div>
        </div>
        <div class="col-6">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">Burkina</h4>
              <form class="add-new modal-content pt-0" [formGroup]="newFormAdd" (ngSubmit)="onSubmitBurkina()">
                <div class="row">
                  <div class="col-lg-1">
                  </div>
                  <div class="col-lg-4">
                    <div class="form-group">
                      <label class="form-label">From Date</label>
                      <input type="date" formControlName="start_date" id="start_date" placeholder="dd-mm-yyyy"
                        class="form-control">
                    </div>
                  </div>
                  <div class="col-lg-4">
                    <div class="form-group">
                      <label class="form-label">To Date</label>
                      <input type="date" formControlName="end_date" id="end_date" placeholder="dd-mm-yyyy"
                        class="form-control">
                    </div>
                  </div>
                  <div class="col-lg-3">
                    <div class="form-group">
                      <button style="margin-top: 23px;" type="submit" class="btn btn-primary mr-1 " rippleEffect [disabled]="loading ">
                        <span *ngIf="loading " class="spinner-border spinner-border-sm mr-1 "></span>Go
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
            <div class="card-body pb-1">
              <div id="chart">
                <apx-chart [series]="burkinachartOptions.series" [chart]="burkinachartOptions.chart"
                  [colors]="allchartOptions.colors" [labels]="burkinachartOptions.labels"
                  [responsive]="burkinachartOptions.responsive"></apx-chart>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <!-- Basic Alerts end -->
  </div>
</div>