import { Component, OnInit ,Injectable} from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import Swal from "sweetalert2";
import { ToastrService } from "ngx-toastr";
import { AuthenticationService } from "app/auth/service";
import { Router, ActivatedRoute } from "@angular/router";
import { CoreSidebarService } from "@core/components/core-sidebar/core-sidebar.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { PurchaseService } from 'app/_services/purchase.service';
import { NgbDateAdapter, NgbDateParserFormatter, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';

@Injectable()
 export class CustomDateParserFormatter extends NgbDateParserFormatter {
   readonly DELIMITER = '-';
 
   parse(value: string): NgbDateStruct | null {
     if (value) {
       const date = value.split(this.DELIMITER);
       return {
         day: parseInt(date[0], 10),
         month: parseInt(date[1], 10),
         year: parseInt(date[2], 10),
       };
     }
     return null;
   }
 
   format(date: NgbDateStruct | null): string {
     return date ? date.day + this.DELIMITER + date.month + this.DELIMITER + date.year : '';
   }
 }

@Component({
  selector: 'app-purchase-add',
  templateUrl: './purchase-add.component.html',
  styleUrls: ['./purchase-add.component.scss'],
  providers: [
		{ provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter },
	],
})
export class PurchaseAddComponent implements OnInit {

  public newForm: FormGroup;
  public submitted = false;
  public isAddMode: boolean = true;
  public loadingFrm: boolean = false;
  public purchaseId = 0;
  public final_total: any = 0.00;
  public error = "";
  public userDropDown: any = [];
  public vendorDropDown: any = [];
  public productDropDown: any = [];
  public FormSubmit: Boolean = false;
  public addFormSubmit: Boolean = false;
  public loading = false;

  public today = new Date();
  public getDate = String(this.today. getDate()). padStart(2, '0');
  public getMonth = String(this.today. getMonth() + 1). padStart(2, '0'); //January is 0!
  public getYear = this.today. getFullYear();

  public maxDate = Number(this.getDate);
  public maxMonth = Number(this.getMonth);
  public maxYear = Number(this.getYear);
  public basicDPdata: NgbDateStruct = {day: this.maxDate, month: this.maxMonth, year: this.maxYear};

  constructor(
    private _router: Router,
    private route: ActivatedRoute,
    private PurchaseService: PurchaseService,
    private _authenticationService: AuthenticationService,
    private _formBuilder: FormBuilder,
    private _toastrService: ToastrService,
    private http: HttpClient,
    private _coreSidebarService: CoreSidebarService
  ) { }

  public purchaseProducts: any[] = [{
    id: 1,
    product_id: '0',
    price: '0',
    qty: '0',
    total: '0',
  }];

  ngOnInit(): void {
    this.getuserDropDown();
    this.getvendorDropDown();
    this.getproductDropDown();

    this.newForm = this._formBuilder.group({
      user_id: ["", Validators.required],
      vendor_id: ["", Validators.required],
      date: ["", Validators.required],
      vehicle_details: ["", Validators.required],
      consignee_name: [""],
      remark: [""],
      eta: ["", Validators.required],
    });

    this.purchaseId = this.route.snapshot.params['id'];
    this.isAddMode = !this.purchaseId;
    if (!this.isAddMode) {
      this.loading = true;
      this.edit(this.purchaseId);
    }else {
      this.purchaseId = 0;
    }
  }

  get f() {
    return this.newForm.controls;
  }
  
  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.newForm.invalid) {
      this.newForm.markAllAsTouched();
    } else {
      this.loadingFrm = true;
      this.error = "";
      let date = this.newForm.controls.date.value['year']+'-'+ this.newForm.controls.date.value['month']+'-'+this.newForm.controls.date.value['day'];
      const { user_id,vendor_id,vehicle_details,remark,consignee_name,eta } = this.newForm.controls;
      
      this.PurchaseService.add(
        user_id.value,
        vendor_id.value,
        date,
        vehicle_details.value,
        remark.value,
        consignee_name.value,
        eta.value,
        this.purchaseId,
        this.final_total,
        this.purchaseProducts
      ).subscribe(
        (data) => {
          this.loadingFrm = false;
          if (data.status) {
            this.purchaseId = 0;
            this.submitted = false;
            this.newForm.reset();
            this._toastrService.success(data.message, "Success!", {
              toastClass: "toast ngx-toastr",
              closeButton: true,
            });
            this._router.navigate(['/purchase']);
          } else {
            this.error = data.message;
          }
        },
        (err) => {
          if (err.error.error == "Unauthenticated.") {
            this._authenticationService.logout();
            this._router.navigate(["/login"]);
          }
        }
      );
    }
  }

  edit(id: any) {
    this.loadingFrm = true;
    this.PurchaseService.edit(id).subscribe(
      data => {
        if (data.status) {
          this.loadingFrm = false;
          this.loading = false;
          const [year, month, day] = data.purchase.date.split('-');
          const obj = { year: parseInt(year), month: parseInt(month), day: 
            parseInt(day.split(' ')[0].trim()) };
            
          this.newForm.setValue({
            date:  {day: parseInt(day), month: parseInt(month), year: parseInt(year)},
            remark: data.purchase.remark,
            eta: data.purchase.eta,
            user_id: data.purchase.user_id,
            vendor_id: data.purchase.vendor_id,
            vehicle_details: data.purchase.vehicle_details,
            consignee_name: data.purchase.consignee_name,
          });
          this.final_total = data.purchase.final_total;

          this.purchaseId = data.purchase.id,
          this.purchaseProducts = data.purchase_details;
        } else {
          this._toastrService.error(data.errorMessage, 'Error!');
        }
      },
      err => {
        this._toastrService.error(err.errorMessage, 'Opps!');
      }
    );
  }

  getproductDropDown() {
    this.PurchaseService.getproductDropDown().subscribe(
      data => {
        if (data.status) {
          this.productDropDown = data.product;
        }
      });
  }

  getuserDropDown() {
    this.PurchaseService.getuserDropDown().subscribe(
      data => {
        if (data.status) {
          this.userDropDown = data.user;
        }
      });
  }

  getvendorDropDown() {
    this.PurchaseService.getvendorDropDown().subscribe(
      data => {
        if (data.status) {
          this.vendorDropDown = data.vendor;
        }
      });
  }

  addMore() {
    this.purchaseProducts.push({
      id: this.purchaseProducts.length + 1,
      product_id: '0',
      price: '0',
      qty: '0',
      total: '0',
    });
  }

  remove(i: number, productId: any = 0) {
    
    this.purchaseProducts.splice(i, 1);

    let temp_total = 0;
    for (let i = 0; i < this.purchaseProducts.length; i++) {
      this.purchaseProducts[i].total = (this.purchaseProducts[i].price * this.purchaseProducts[i].qty).toFixed(2);
      temp_total += parseFloat(this.purchaseProducts[i].total);
    }
    this.final_total = temp_total.toFixed(2);
  }
  
  addTotal(event:any){
    let temp_total = 0;
    for (let i = 0; i < this.purchaseProducts.length; i++) {
      this.purchaseProducts[i].total = (this.purchaseProducts[i].price * this.purchaseProducts[i].qty).toFixed(2);
      temp_total += parseFloat(this.purchaseProducts[i].total);
    }
    this.final_total = temp_total.toFixed(2);
  }
}
