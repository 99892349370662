import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'environments/environment';
@Injectable({
  providedIn: 'root'
})
export class StockTransferService {

  constructor(private _http: HttpClient) { }

  getData(formdata: any): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}get-stock-transfer`, formdata)
      .pipe(
        map(data => {
          return data;
        })
      );
  }
  getuserDropDown(user_id): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}user-dropdown-stock`, { user_id})
      .pipe(
        map(data => {
          return data;
        })
      );
  }
  getuserreceiveDropDown(): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}user-dropdown`, { })
      .pipe(
        map(data => {
          return data;
        })
      );
  }
  getproductDropDown(user_id): Observable<any> {
    return this._http
      .post(`${environment.apiUrl}product-dropdown`, {user_id})
      .pipe(
        map(data => {
          return data;
        })
      );
  }
  
  add(user_id:any,transfer_user_id:any,date:any,remark:any, id: any,final_total:any,stockTransfer:any): Observable<any> {
    if (id == 0) {
      return this._http
        .post(`${environment.apiUrl}save-stock-transfer`, {user_id,transfer_user_id,date,remark,final_total,stockTransfer})
        .pipe(
          map(data => {
            return data;
          })
        );
    }
    // else {
    //   return this._http
    //     .post(`${environment.apiUrl}update-sales`, {invoice_no,customer_id,type_id,date,remark,final_total,salesProducts, id })
    //     .pipe(
    //       map(data => {
    //         return data;
    //       })
    //     );
    // }
  }

  stockcheck(product_id: string,qty: string,user_id: string): Observable<any> {
    return this._http.post(`${environment.apiUrl}stock-transfer-check`, {product_id,qty,user_id});
  }
  getmeta(id: string): Observable<any> {
    return this._http.post(`${environment.apiUrl}get-stock-transfer-meta`, {id});
  }
}
