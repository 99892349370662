import { Component, OnInit, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { ToastrService } from "ngx-toastr";
import { AuthenticationService } from "app/auth/service";
import { Router, ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { PurchasePaymentReportService } from 'app/_services/purchase-payment-report.service';
import { PaymentService } from 'app/_services/payment.service';

@Component({
  selector: 'app-purchase-payment-report',
  templateUrl: './purchase-payment-report.component.html',
  styleUrls: ['./purchase-payment-report.component.scss']
})
export class PurchasePaymentReportComponent implements OnInit {
  @ViewChild('auto') auto: any;
  public paymentList: any = [];
  public start: any = 0;
  public page: any = 0;
  public progressMainTable: boolean = false;
  public collectionSize = 0;
  public lengths = 15;
  public pageBasicText = 1;

  public loading = false;
  public newFormAdd: FormGroup;
  public submitted = false;
  public isLoading: boolean = false;
  public error = "";
  public allVendor: any;
  public loadingReport: boolean = false;
  public vendorId: any = 0;
  public vendorDetails: any = '';
  public vendorOutStanding: any = [];
  public vendorOpeningBalance: any;
  public historyHeading: string = 'Recently selected';
  public keyword = 'name';

  constructor(
    private _router: Router,
    private route: ActivatedRoute,
    private paymentService: PaymentService,
    private purchasePaymentReportService: PurchasePaymentReportService,
    private _authenticationService: AuthenticationService,
    private _formBuilder: FormBuilder,
    private _toastrService: ToastrService,
    private http: HttpClient,
  ) { }

  ngOnInit(): void {
    this.paymentsList(this.start, this.lengths);
  }

  paymentsList(my_start: any, my_lengths: any) {
    const formdata = new FormData();
    formdata.append('start', my_start);
    formdata.append('length', my_lengths);

    this.paymentService.getData(formdata).subscribe(
      (response) => {
        this.loading = false;
        this.progressMainTable = false;
        if (response.status) {
          this.paymentList = response.data;
          this.collectionSize = response.recordsTotal;
        }
      },
      (err) => {
        if (err.error.error == "Unauthenticated.") {
          this._authenticationService.logout();
          this._router.navigate(["/login"]);
        }
      }
    );
  }
  loadPage(event: any) {
    this.progressMainTable = true;
    this.page = this.lengths * (event - 1);
    this.paymentsList(this.page, this.lengths);
  }
  loadPageLenghs(event: any) {
    this.progressMainTable = true;
    this.lengths = event.target.value;
    this.paymentsList(this.start, this.lengths);
  }

  onChangeSearch(value: string) {
    this.isLoading = true
    this.purchasePaymentReportService.vendorSearch(value).subscribe(
      data => {
        if (data.status) {
          this.isLoading = false
          this.allVendor = data.vendor;
        }
        else {
          this.isLoading = false;
          this.allVendor = [];
        }
      },
      err => {
        if (err.error.error == 'Unauthenticated.') {
          this._authenticationService.logout();
          this._router.navigate(['/login']);
        }
        this._toastrService.clear();
        this._toastrService.error(err.errorMessage, 'Opps!', { toastClass: 'toast ngx-toastr', closeButton: true });
        this.allVendor = [];
      }
    );
  }

  selectEvent(item: any) {
    this.vendorId = item.id;
    this.vendorDetails = item;
    this.vendorOpeningBalance = '';
    this.vendorOutStanding = [];
    this.loadReport();
  }

  onFocused(e: any) {
    // do something when input is focused
  }

  clear(): void {
    this.auto.clear();
  }

  loadReport() {
    if (this.vendorId > 0) {
      this.loadingReport = true;
      this.vendorOpeningBalance = '';
      this.vendorOutStanding = [];

      this.purchasePaymentReportService.loadReport(this.vendorId).subscribe(
        data => {
          this.loadingReport = false;
          if (data.status) {
            let total = 0;
            if (data.opening_balance.balance_type == 'D') {
              total = -data.opening_balance.balance;
            }
            else {
              total = data.opening_balance.balance;
            }
            data.data.forEach((value: any, index: any) => {
              if (value.payment_type == "C") {
                total += parseFloat(value.amount);
              }
              else {
                total -= parseFloat(value.amount);
              }
              data.data[index].closing = total;
            });
            this.vendorOpeningBalance = data.opening_balance;
            this.vendorOutStanding = data.data;
          }
        },
        err => {
          if (err.error.error == 'Unauthenticated.') {
            this._authenticationService.logout();
            this._router.navigate(['/login']);
          }
          this._toastrService.clear();
          this._toastrService.error(err.errorMessage, 'Opps!', { toastClass: 'toast ngx-toastr', closeButton: true });
          this.vendorOpeningBalance = '';
          this.vendorOutStanding = [];
          this.loadingReport = false;
        }
      );
    }
  }

  downloadExcelData() {
    this.purchasePaymentReportService.fileDownload().subscribe(
      (data) => {
        window.location.href=data.url;
      },
      (error) => {
        this._toastrService.error('Something went wrong! Please Try Againg...', 'Opps!', { toastClass: 'toast ngx-toastr', closeButton: true });
      }
    );
  }
}
